import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {icons} from "../../../services/images";
import {useDispatch, useSelector} from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {getReservationDetails} from "../../../redux/selectors/bookingSelector";
import {setReservationDetailsAction} from "../../../redux/booking";
import {getReservationHandler} from "../../../handlers/bookingHandlers";
import {ReservationDetails} from "../../../services/interfaces";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";
import ReservationDetailsSection from "./ReservationDetailsSection";


interface Props {
    reservationId: number | null,
    onClose: () => void;
    updateTableCallback: () => void;
}

const ReservationDetailsModal = ({onClose, reservationId, updateTableCallback}: Props) => {
    const [error, setError] = useState<boolean>(false);
    const dispatch = useDispatch();
    const reservation: ReservationDetails | null = useSelector(getReservationDetails);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    useEffect(() => {
        if (reservationId !== null)
            getReservationHandler(reservationId);
    }, [reservationId]);

    const closeModal = () => {
        onClose();
        dispatch(setReservationDetailsAction(null));
        setError(false);
    };


    const saveCallback = () => {
        updateTableCallback();
        closeModal();
    };


    if (!reservation || !merchantPoint) {
        return (
            <Modal
                isOpen={!!reservationId}
                onRequestClose={closeModal}
                overlayClassName="modal-wrapper"
                ariaHideApp={false}
                className="modal-content booking-details-modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        Szczegóły rezerwacji
                    </h5>
                    <div className="modal-close" onClick={closeModal}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <LoadingScreen/>
                </div>
            </Modal>
        )
    }

    return (
        <Modal
            isOpen={!!reservationId}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content booking-details-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title">
                    Szczegóły rezerwacji <span className="blue-primary">#{reservation.id}</span>
                </h5>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <ReservationDetailsSection reservation={reservation} merchantPoint={merchantPoint}
                                           saveCallback={saveCallback} error={error} setError={setError}/>
            </div>
        </Modal>
    )
};

export default ReservationDetailsModal;