import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Modal from 'react-modal';
import {isEmpty, isEqual} from "lodash";
import {EmployeeVacation} from "../../../services/interfaces";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import {getEmployeeVacationsHandler, updateVacationHandler} from "../../../handlers/bookingHandlers";
import {useSelector} from "react-redux";
import {getEmployeeVacations} from "../../../redux/selectors/bookingSelector";
import {DatePicker, DatePickerProps} from "antd";
import dayjs from "dayjs";
import ErrorComponent from "../../common/ErrorComponent";
import LoadingScreen from "../../common/LoadingScreen";
import {toLocalISOString} from "../../../services/dates";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateCallback?: () => void
}


const EmployeeVacationModal = ({isOpen, setIsOpen, updateCallback}: Props) => {
    const [selectedDays, setSelectedDays] = React.useState<Date[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [employeeVacation, setEmployeeVacation] = useState<EmployeeVacation | null>(null);
    const employeeVacations = useSelector(getEmployeeVacations);


    useEffect(() => {
        if (!isEmpty(employeeVacations)) {
            const firstVacation = employeeVacations[0];
            setEmployeeVacation(firstVacation);
            setSelectedDays(firstVacation.vacation_days.map(d => new Date(d)))
        }
    }, [employeeVacations]);


    const disabledDays = useMemo(() => {
        if (employeeVacation)
            return employeeVacation.disabled_days;
        return [];
    }, [employeeVacation]);

    useEffect(() => {
        isOpen && getEmployeeVacationsHandler()
    }, [isOpen]);

    const toggleModal = useCallback(() => {
        setIsOpen(!isOpen);
        setSelectedDays([]);
        setEmployeeVacation(null);
        setError(null);
    }, [isOpen, setIsOpen]);

    const saveCallback = useCallback(() => {
        setIsLoading(false);
        toggleModal();
        updateCallback && updateCallback();
    }, [toggleModal, setIsLoading, updateCallback]);

    const errorCallback = useCallback((error: string) => {
        setError(error);
        setIsLoading(false);
    }, [setIsLoading, setError]);

    const onSave = useCallback(() => {
        if (employeeVacation && selectedDays)
            updateVacationHandler(employeeVacation.employee.id, selectedDays.map(d => toLocalISOString(d)), saveCallback, errorCallback)
    }, [employeeVacation, selectedDays, saveCallback, errorCallback]);


    const isChanged = useMemo(() => {
        if (employeeVacation) {
            return !isEqual(employeeVacation.vacation_days.map(d => new Date(d)), selectedDays);
        }
        return false
    }, [employeeVacation, selectedDays]);



    const onChange: DatePickerProps<dayjs.Dayjs[]>['onChange'] = (date, dateString) => {
        if (date)
            setSelectedDays(date.map(d => d.toDate()));
        else setSelectedDays([]);
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content employee-schedule-modal"
        >
            <div className="modal-header">
                <h5>Ustal urlop</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {!employeeVacation || isEmpty(employeeVacations) ?
                <LoadingScreen/> :
                <div className="modal-body">
                    <div className="tab-manager">
                        {employeeVacations.map(vacation =>
                            <div
                                onClick={() => {
                                    if (employeeVacation.employee.id !== vacation.employee.id) {
                                        setEmployeeVacation(vacation);
                                        setSelectedDays(vacation.vacation_days.map(d => new Date(d)));
                                    }}
                                }
                                className={`tab-pill ${employeeVacation.employee.id === vacation.employee.id ? 'selected' : ''}`}
                                key={`Employee-Vacation-${vacation.employee.id}`}
                            >
                                <span>{vacation.employee.name}</span>
                            </div>
                        )}
                    </div>
                    <div className="select-wrapper">
                        <p className="label">Wybierz dni<span className="required-mark"> *</span></p>
                        <DatePicker
                            multiple
                            onChange={onChange}
                            className="date-picker-range-input booking multiple"
                            suffixIcon={<img src={icons.calendarIcon} alt=""/>}
                            maxTagCount="responsive"
                            value={selectedDays.map(d => dayjs(d))}
                            size="large"
                            format="DD.MM.YYYY"
                            minDate={dayjs()}
                            placeholder="Wybierz dni niedyspozycyjności..."
                            disabledDate={(current) => disabledDays.includes(current.format('YYYY-MM-DD'))}
                        />
                    </div>
                    {error && <ErrorComponent error={error}/>}
                </div>
            }
            <div className="modal-footer">
                <Button label="Potwierdź" onClick={onSave} fullWidth disabled={!isChanged} loading={isLoading}/>
            </div>
        </Modal>
    );
};

export default EmployeeVacationModal;

