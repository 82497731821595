import {
    AffectedReservations,
    Category, CategoryType, Employee, EmployeeSchedule, EmployeeVacation, ImportExcelError,
    OrderDetails,
    OrderFilterOptions,
    OrderTableData,
    OrderTableResponse,
    ProductCategory,
    ReservationDetails,
    ReservationFilterOptions,
    ReservationTableData,
    ReservationTableResponse,
    ServiceCategory,
} from "../services/interfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface BookingState {
    orderFilterOptions: OrderFilterOptions | null,
    reservationFilterOptions: ReservationFilterOptions | null,
    orderTable: OrderTableData,
    reservationTable: ReservationTableData
    editMode: boolean,
    reservationDetails: ReservationDetails | null
    orderDetails: OrderDetails | null
    categories: {
        product: ProductCategory[]
        service: ServiceCategory[]
    }
    categoryOptions: Category[]
    importErrors: null | ImportExcelError[]
    employeeSchedules: EmployeeSchedule[]
    employeeVacations: EmployeeVacation[]
    employees: Employee[],
    affectedReservations: AffectedReservations | null
}

const DEFAULT_STATE: BookingState = {
    orderFilterOptions: null,
    reservationFilterOptions: null,
    orderTable: {
        orders: [],
        ordersCount: 0,
        maxPage: 0,
        pageSize: 0
    },
    reservationTable: {
        reservations: [],
        reservationsCount: 0,
        maxPage: 0,
        pageSize: 0
    },
    editMode: false,
    reservationDetails: null,
    orderDetails: null,
    categories: {
        product: [],
        service: []
    },
    categoryOptions: [],
    importErrors: null,
    employeeSchedules: [],
    employeeVacations: [],
    employees: [],
    affectedReservations: null
};

interface SetCategoriesPayload {
    categoryType: CategoryType;
    categories:  ProductCategory[] | ServiceCategory[];
}

const bookingSlice = createSlice({
    initialState: DEFAULT_STATE,
    name: 'booking',
    reducers: {
        setOrderTableData: (state, action: PayloadAction<OrderTableResponse>) => {
            return {
                ...state,
                orderTable: {
                    orders: action.payload.orders,
                    ordersCount: action.payload.orders_count,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size
                },
            }
        },
        setReservationTableData: (state, action: PayloadAction<ReservationTableResponse>) => {
            return {
                ...state,
                reservationTable: {
                    reservations: action.payload.reservations,
                    reservationsCount: action.payload.reservations_count,
                    maxPage: action.payload.max_page,
                    pageSize: action.payload.page_size
                },
            }
        },
        setOrderFilterOptions: (state, action: PayloadAction<OrderFilterOptions>) => {
            return {
                ...state,
                orderFilterOptions: action.payload
            }
        },
        setReservationFilterOptions: (state, action: PayloadAction<ReservationFilterOptions>) => {
            return {
                ...state,
                reservationFilterOptions: action.payload
            }
        },
        setEditMode: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                editMode: action.payload
            }
        },
        setReservationDetails: (state, action: PayloadAction<ReservationDetails | null>) => {
            return {
                ...state,
                reservationDetails: action.payload
            }
        },
        setOrderDetails: (state, action: PayloadAction<OrderDetails | null>) => {
            return {
                ...state,
                orderDetails: action.payload
            }
        },
        setCategories: (state, action: PayloadAction<SetCategoriesPayload>) => {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.payload.categoryType]: action.payload.categories
                }
            }
        },
        setCategoryOptions: (state, action: PayloadAction<Category[]>) => {
            return {
                ...state,
                categoryOptions: action.payload
            }
        },
        setImportErrors: (state, action: PayloadAction<ImportExcelError[] | null>) => {
            return {
                ...state,
                importErrors: action.payload
            }
        },
        setEmployeeSchedules: (state, action: PayloadAction<EmployeeSchedule[]>) => {
            return {
                ...state,
                employeeSchedules: action.payload
            }
        },
        setEmployeeVacations: (state, action: PayloadAction<EmployeeVacation[]>) => {
            return {
                ...state,
                employeeVacations: action.payload
            }
        },
        setEmployees: (state, action: PayloadAction<Employee[]>) => {
            return {
                ...state,
                employees: action.payload
            }
        },
        setAffectedReservations: (state, action: PayloadAction<AffectedReservations | null>) => {
            return {
                ...state,
                affectedReservations: action.payload
            }
        }
    }
});

export default bookingSlice.reducer

export const setOrderTableDataAction = bookingSlice.actions.setOrderTableData;
export const setReservationTableDataAction = bookingSlice.actions.setReservationTableData;
export const setOrderFilterOptionsAction = bookingSlice.actions.setOrderFilterOptions;
export const setReservationFilterOptionsAction = bookingSlice.actions.setReservationFilterOptions;
export const setBookingEditModeAction = bookingSlice.actions.setEditMode;
export const setReservationDetailsAction = bookingSlice.actions.setReservationDetails;
export const setOrderDetailsAction = bookingSlice.actions.setOrderDetails;
export const setCategoriesAction = bookingSlice.actions.setCategories;
export const setCategoryOptionsAction = bookingSlice.actions.setCategoryOptions;
export const setCategoryImportErrorsAction = bookingSlice.actions.setImportErrors;
export const setEmployeeSchedulesAction = bookingSlice.actions.setEmployeeSchedules;
export const setEmployeeVacationsAction = bookingSlice.actions.setEmployeeVacations;
export const setEmployeesAction = bookingSlice.actions.setEmployees;
export const setAffectedReservationsAction = bookingSlice.actions.setAffectedReservations;