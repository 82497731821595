import Modal from "react-modal";
import {icons} from "../../services/images";
import {Circle, GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {GOOGLE_MAP_KEY} from "../../config/config-prod";
import {Slider} from "antd";
import Button from "../common/Button";
import React, {useEffect, useState} from "react";
import {Address, Coordinates, OfferLocation} from "../../services/interfaces";
import Input from "../common/Input";
import {validateZipCode} from "../../services/validators";
import {some} from "lodash";

const containerStyle = {
    width: '100%',
    height: '400px'
};

export const DEFAULT_OFFER_LOCATION: OfferLocation = {
    coordinates: {lat: 52.229, lng: 21.011},
    offer_range: 0,
    place_name: "",
};

interface MapModalProps {
    isOpen: boolean,
    onClose: () => void;
    onSaveAddress?: (location: Coordinates, address: Address) => void,
    onSaveRange?: (location: OfferLocation) => void,
    savedLocation?: OfferLocation | null,
    userLocation: Coordinates | null
}

const MapModal = ({
                      isOpen,
                      onSaveAddress,
                      onSaveRange,
                      onClose,
                      userLocation,
                      savedLocation,
                  }: MapModalProps) => {

    const [markerPosition, setMarkerPosition] = useState<Coordinates>(DEFAULT_OFFER_LOCATION.coordinates);
    const [center, setCenter] = useState<Coordinates>(DEFAULT_OFFER_LOCATION.coordinates);
    const [tempLocation, setTempLocation] = useState<OfferLocation | null>(null);
    const [range, setRange] = useState(DEFAULT_OFFER_LOCATION.offer_range);
    const [placeName, setPlaceName] = useState(DEFAULT_OFFER_LOCATION.place_name);
    const [isDefaultPoint, setIsDefaultPoint] = useState(true);
    const [address, setAddress] = useState<Address>({street: '', zip_code: '', city: ''});
    const [radius, setRadius] = useState(0);

    useEffect(() => {
        if (isOpen) {
            if (savedLocation?.offer_range) {
                setMarkerPosition(savedLocation.coordinates);
                setCenter(savedLocation.coordinates);
                setRange(savedLocation.offer_range);
                setRadius(savedLocation.offer_range * 1000);
                setPlaceName(savedLocation.place_name);
                if (userLocation) {
                    setIsDefaultPoint(JSON.stringify(savedLocation.coordinates) === JSON.stringify(userLocation))
                } else {
                    setIsDefaultPoint(JSON.stringify(savedLocation.coordinates) === JSON.stringify(DEFAULT_OFFER_LOCATION.coordinates))
                }
            } else {
                if (userLocation) {
                    setMarkerPosition(userLocation)
                    setCenter(userLocation)
                } else {
                    setMarkerPosition(DEFAULT_OFFER_LOCATION.coordinates)
                    setCenter(DEFAULT_OFFER_LOCATION.coordinates)
                }
                setRange(DEFAULT_OFFER_LOCATION.offer_range);
                setPlaceName(DEFAULT_OFFER_LOCATION.place_name);
                setRadius(DEFAULT_OFFER_LOCATION.offer_range * 1000);
                setIsDefaultPoint(true)
            }
            setTempLocation(null);
        }
    }, [isOpen, savedLocation, userLocation]);


    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const newPosition: Coordinates = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };
            setMarkerPosition(newPosition);

            setTempLocation(prev => ({
                coordinates: newPosition,
                offer_range: prev?.offer_range || range,
                place_name: "Wybrany punkt"
            }));
            setIsDefaultPoint(false);

            if (userLocation) {
                if (newPosition === userLocation) {
                    setPlaceName("Domyślny punkt");
                } else {
                    setPlaceName("Wybrany punkt");
                }
            } else {
                if (newPosition === DEFAULT_OFFER_LOCATION.coordinates) {
                    setPlaceName("Domyślny punkt");
                } else {
                    setPlaceName("Wybrany punkt");
                }
            }
        }
    };

    const handleRangeChange = (newRange: number) => {
        setRadius(newRange * 1000);
        setRange(newRange);
        setTempLocation(prev => prev ? {...prev, offer_range: newRange} : null);
        if (isDefaultPoint && !placeName) setPlaceName("Domyślny punkt");
    };

    const handleSave = () => {
        const locationToSave = tempLocation || {
            coordinates: markerPosition,
            offer_range: range,
            place_name: placeName || (isDefaultPoint ? "Domyślny punkt" : "Wybrany punkt")
        };
        if (onSaveRange) {
            onSaveRange(locationToSave);
        }
        onClose();
    }

    const toggleModal = () => {
        onClose();
        setAddress({street: '', zip_code: '', city: ''});
        setRadius(0);
        setRange(0);
        if (isOpen && userLocation) {
            setCenter(userLocation)
        } else {
            setCenter(DEFAULT_OFFER_LOCATION.coordinates);
        }
    };

    const isDisabled = () => {
        if (!range) {
            return some(address, field => !field) || !validateZipCode(address.zip_code)
        } else return false
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                onClose();
                if (onSaveAddress) toggleModal();
            }}
            contentLabel="Select Location and Radius"
            overlayClassName="modal-wrapper"
            className="modal-content map-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <h5 className="modal-title">Ustaw Lokalizację i {onSaveRange ? "Zasięg" : "Adres"}</h5>
                    <div className="modal-close" onClick={toggleModal}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    {!onSaveRange &&
                        <div className="address-form">
                            <label>Adres Punktu<span className="required-mark"> *</span></label>
                            <div className="merchant-address">
                                <Input<Address, 'street'>
                                    value={address}
                                    onChange={setAddress}
                                    name='street'
                                    placeholder="Ulica i nr budynku"
                                />
                                <span className="vertical-divider"/>
                                <Input<Address, 'zip_code'>
                                    value={address}
                                    onChange={setAddress}
                                    name='zip_code'
                                    placeholder="Kod pocztowy"
                                />
                                <span className="vertical-divider"/>
                                <Input<Address, 'city'>
                                    value={address}
                                    onChange={setAddress}
                                    name='city'
                                    placeholder="Miasto"
                                />

                            </div>
                            {address.street && address.city && address.zip_code && !validateZipCode(address.zip_code) &&
                                <p className="error-message">Niepoprawny kod pocztowy </p>
                            }
                        </div>}
                    <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={10}
                            onClick={handleMapClick}
                        >
                            <Marker position={markerPosition} draggable={true} onDrag={handleMapClick}/>
                            {range != null && <Circle
                                center={markerPosition}
                                radius={radius}
                                options={{fillColor: 'blue', fillOpacity: 0.1, strokeColor: 'blue'}}
                            />}
                        </GoogleMap>
                    </LoadScript>
                    {onSaveRange &&
                        <div style={{display: "flex", flexDirection: "row", width: '100%', marginTop: 16}}>
                            <Slider
                                min={0}
                                max={400}
                                onChange={handleRangeChange}
                                value={range}
                                step={1}
                                style={{width: "80%", marginRight: 8}}
                            />
                            <span>Zasięg: {range} km</span>
                        </div>
                    }

                </div>
                <div className="modal-footer">
                    <Button label="Anuluj"
                            onClick={() => {
                                onClose();
                                if (onSaveAddress) {
                                    toggleModal()
                                }
                            }}
                            type="Default"
                    />
                    <Button label="Zapisz"
                            disabled={isDisabled()}
                            onClick={() => {
                                if (onSaveRange) {
                                    handleSave();
                                }
                                if (onSaveAddress && tempLocation) {
                                    onSaveAddress(tempLocation.coordinates, address);
                                }
                                onClose();
                            }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default MapModal;