import {Calendar, CalendarProps} from "antd";
import dayjs from "dayjs";
import {icons} from "../../../services/images";
import {capitalize} from "lodash";
import {EmployeeCalendar, EmployeeCalendarDay, TimeSlot} from "../../../services/interfaces";
import React, {Dispatch, SetStateAction, useMemo} from "react";
import {getEmployeeCalendarDay} from "../../../services/helpers";

interface Props {
    onSlotClick: (slot: [string, TimeSlot]) => void;
    setCurrentDate: Dispatch<SetStateAction<dayjs.Dayjs>>;
    setMonth: Dispatch<SetStateAction<dayjs.Dayjs>>;
    currentDate: dayjs.Dayjs;
    employeeCalendar: EmployeeCalendar;
    currentDay: [string, EmployeeCalendarDay] | undefined
    disabledPreviousPeriod?: boolean;
    slotClassName?: (slot: [string, TimeSlot]) => string;
    cellClassName?: (date: dayjs.Dayjs) => string;
}

const CalendarWithSlots = ({
                               onSlotClick,
                               setCurrentDate,
                               currentDate,
                               employeeCalendar,
                               currentDay,
                               setMonth,
                               disabledPreviousPeriod = false,
                               slotClassName,
                               cellClassName
                           }: Props) => {
    const onPanelChange = (value: dayjs.Dayjs, mode: CalendarProps<dayjs.Dayjs>['mode']) => {
        setMonth(value);
    };

    const getReservationsCount = (date: dayjs.Dayjs) => {
        const calendarDay = getEmployeeCalendarDay(employeeCalendar, date);
        if (calendarDay)
            return calendarDay[1].reservation_ids.length

    };

    const disabledDays = useMemo(() => employeeCalendar.days.filter(day => day[1].is_day_off).map(day => dayjs(day[0]).format('YYYY-MM-DD')), [employeeCalendar]);

    return (
        <div className="calendar-with-slots">
            <Calendar
                fullscreen={false}
                className="calendar"
                fullCellRender={(current, info) => {
                    const reservationCount = getReservationsCount(current);
                    const children = [
                        <div className="ant-picker-calendar-date-value">
                            {info.originNode.props.children}
                        </div>
                    ];
                    if (reservationCount && reservationCount > 0)
                        children.push(
                            <div className="ant-picker-calendar-date-content">
                                <span className="calendar-day-badge">{reservationCount}</span>
                            </div>
                        );
                    return React.cloneElement(
                        info.originNode,
                        {
                            className: `${info.originNode.props.className} ${cellClassName?.(current) || ''}`,
                            children
                        },
                    );
                }}
                disabledDate={(current) => disabledDays.includes(current.format('YYYY-MM-DD')) ||
                    (disabledPreviousPeriod && current.isBefore(dayjs(), 'day'))}
                headerRender={({value, onChange}) => {
                    let current = value.clone();
                    const onMonthChange = (direction: 'prev' | 'next') => {
                        const newMonth = direction === 'next' ? current.month() + 1 : current.month() - 1;
                        const now = value.clone().month(newMonth);
                        onChange(now);
                    };
                    const isPrevMonthDisabled = disabledPreviousPeriod && (value.clone().month(current.month() - 1)).isBefore(dayjs(), 'month');
                    return (
                        <div className="calendar-header">
                            <div onClick={() => !isPrevMonthDisabled && onMonthChange('prev')}
                                 className={`month-button ${isPrevMonthDisabled ? "disabled" : ""}`}>
                                <img src={icons.backIcon} alt=""/>
                            </div>
                            <span
                                className="title">{capitalize(current.locale('pl').format("MMMM"))} {current.year()}</span>
                            <div onClick={() => onMonthChange('next')} className="month-button">
                                <img src={icons.backIcon} alt=""/>
                            </div>
                        </div>
                    );
                }}
                onPanelChange={onPanelChange}
                onChange={setCurrentDate}
                value={currentDate}
            />
            <span className="horizontal-divider"/>
            <div className="slots">
                {currentDay && currentDay[1].slots.map((slot, index) => (
                    <div className={`slot ${(slotClassName && slotClassName(slot)) || slot[1].status}`}
                         key={`Slot${index}`} onClick={() => onSlotClick(slot)}>
                        <span>{dayjs(slot[0]).format('HH:mm')}</span>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default CalendarWithSlots;