import {RootState} from "./rootSelectors";
import {createSelector, Selector} from "reselect";
import {
    AffectedReservations,
    Category, CategoryType, Employee, EmployeeSchedule, EmployeeVacation, ImportExcelError,
    Order, OrderDetails,
    OrderFilterOptions,
    ProductCategory,
    Reservation,
    ReservationFilterOptions,
    ServiceCategory
} from "../../services/interfaces";


export const getBookingState = (state: RootState) => state.booking;

export const getBookingEditMode: Selector<RootState, boolean> = createSelector(
    getBookingState,
    (bookingState) => {
        return bookingState.editMode
    }
);

export const getOrderTableData: Selector<RootState, Order[]> = createSelector(
    getBookingState,
    (bookingState) => {
        return bookingState.orderTable.orders
    }
);

export const getOrderTableCount: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderTable.ordersCount
);

export const getOrderTableMaxPage: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderTable.maxPage
);

export const getOrderFilters: Selector<RootState, OrderFilterOptions | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderFilterOptions
);

export const getReservationTableData: Selector<RootState, Reservation[]> = createSelector(
    getBookingState,
    (bookingState) => {
        return bookingState.reservationTable.reservations;
    }
);

export const getReservationTableCount: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationTable.reservationsCount
);

export const getReservationTableMaxPage: Selector<RootState, number> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationTable.maxPage
);

export const getReservationFilters: Selector<RootState, ReservationFilterOptions | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationFilterOptions
);


export const getReservationDetails: Selector<RootState, Reservation | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.reservationDetails
);


export const getOrderDetails: Selector<RootState, OrderDetails | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.orderDetails
);


export const getCategoriesByType = (type: CategoryType) => createSelector(
    getBookingState,
    (booking): ServiceCategory[] | ProductCategory[] => booking.categories[type]
);


export const getCategoryOptions: Selector<RootState, Category[]> = createSelector(
    getBookingState,
    (bookingState) => bookingState.categoryOptions
);


export const getCategoryImportErrors: Selector<RootState, ImportExcelError[] | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.importErrors
);


export const getEmployeeSchedules: Selector<RootState, EmployeeSchedule[]> = createSelector(
    getBookingState,
    (bookingState) => bookingState.employeeSchedules
);

export const getEmployeeVacations: Selector<RootState, EmployeeVacation[]> = createSelector(
    getBookingState,
    (bookingState) => bookingState.employeeVacations
);

export const getEmployees: Selector<RootState, Employee[]> = createSelector(
    getBookingState,
    (bookingState) => bookingState.employees
);

export const getAffectedReservations: Selector<RootState, AffectedReservations | null> = createSelector(
    getBookingState,
    (bookingState) => bookingState.affectedReservations
);