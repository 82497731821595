import React, {useCallback, useEffect, useState} from 'react';
import Button from "../../common/Button";
import {Select, SelectProps, Slider, Tag} from "antd";
import FiltersDatePicker from "../filters/FiltersDatePicker";
import Switch from "../../common/Switch";
import {
    CashbackMerchantLevel,
    MerchantPointSimple,
    OfferDays,
    OfferType,
    OfferUsage
} from "../../../services/interfaces";
import {getAddressWithoutCountry, showCustomersNumber} from "../../../services/utils";
import {getMerchantPoints} from "../../../redux/selectors/merchantSelector";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, formatNumber} from "../../../services/numbers";
import InputDescription from "../../common/InputDescription";
import {cashbackMerchantDictionary} from "../../../services/dictionaries/cashbackDict";

interface StepProps {
    formData: {
        name: string;
        type: OfferType | undefined;
        extra_cashback: number;
        offer_merchant_points: number[];
        start_date: Date | null;
        end_date: Date | null;
        offer_days: { value: string, label: string }[];
        usage: OfferUsage;
        description: string;
        direct_addressing: boolean;
        direct_addressing_style: string,
        send_push_notification: boolean;
    };
    handleChange: (field: string, value: any) => void;
    nextStep: () => void;
    prevStep: () => void;
    numberOfCustomers: number;
    estimateCost: number;
}

interface FormErrors {
    step2?: {
        name?: string;
        type?: string;
        offer_merchant_points?: string;
        date?: string;
        offer_days?: string;
    },
}

type TagRender = SelectProps['tagRender'];

const Option = Select.Option;

export const tagRender: TagRender = (props) => {
    const {label, closable, onClose} = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={"#0071FC"}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            className="tag"
        >
            {label}
        </Tag>
    );
};

const Step2: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        nextStep,
                                        prevStep,
                                        numberOfCustomers,
                                        estimateCost,
                                    }) => {
    const merchantPoints = useSelector(getMerchantPoints);

    const [step2Errors, setStep2Errors] = useState<FormErrors>({});
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const cashbackMerchant = formData.extra_cashback;
    const isPillSelected = (level: CashbackMerchantLevel) =>
        level.min <= cashbackMerchant &&
        level.max >= cashbackMerchant;


    const validateStep2 = useCallback(() => {
        const errors: FormErrors = {};
        if (formData.name === "") {
            errors.step2 = {...errors.step2, name: 'Nazwa jest wymagana'};
        }
        if (!formData.type) {
            errors.step2 = {...errors.step2, type: 'Typ promocji jest wymagany'};
        }
        if (Object.keys(formData.offer_merchant_points).length === 0) {
            errors.step2 = {...errors.step2, offer_merchant_points: 'Wybranie punktów jest wymagane'};
        }
        if (!formData.start_date || !formData.end_date) {
            errors.step2 = {...errors.step2, date: 'Czas trwania promocji jest wymagany'};
        }
        setStep2Errors(errors);
        return Object.keys(errors).length === 0;
    }, [formData]);

    useEffect(() => {
        if (isSubmitted) {
            setIsNextDisabled(!validateStep2());
        }
    }, [formData, isSubmitted, validateStep2]);


    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <p className="label label-margin">Nazwa promocji<span className="required-mark"> *</span></p>
                    <div className="input">
                        <input type="text"
                               className="input-text"
                               value={formData.name}
                               onChange={e => handleChange("name", e.target.value)}
                               placeholder="Nadaj nazwę swojej promocji..."
                               maxLength={50}
                        />
                    </div>
                    {step2Errors.step2?.name &&
                    <div className="section validation">
                        <div className="error-message">{step2Errors.step2.name}</div>
                    </div>
                    }
                </div>

                <div className="section">
                    <p className="label label-margin">Opis promocji</p>
                    <div className="input-description">
                        <InputDescription
                            className="input-text"
                            value={formData.description}
                            onChange={(e) => handleChange("description", e)}
                            maxLength={150}
                            placeholder="Opis..."
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Rodzaj promocji<span className="required-mark"> *</span></p>
                        <Select
                            value={formData.type ? formData.type : undefined}
                            onChange={(value) => handleChange("type", value)}
                            allowClear={true}
                            placeholder="Wybierz rodzaj promocji..."
                            showSearch={false}
                            options={[
                                {value: "gratis" as OfferType, label: 'Gratis'},
                                {value: "contest" as OfferType, label: 'Konkurs'},
                                {value: "discount" as OfferType, label: 'Rabat'},
                                {value: "event" as OfferType, label: 'Wydarzenie'},
                                {value: "news" as OfferType, label: 'Nowości'},
                                {value: "extra_cashback" as OfferType, label: 'Dodatkowy Cashback'},
                            ]}
                            className="dropdown-select"
                        />
                    </div>
                    {step2Errors.step2?.type &&
                        <div className="section validation">
                            <div className="error-message">{step2Errors.step2?.type}</div>
                        </div>
                    }
                </div>
                {formData.type === "extra_cashback" &&
                    <div className="section">
                        <p className="label label-margin">Wysokość dodatkowego Cashbacku<span
                            className="required-mark"> *</span></p>
                        <div className="slider-value-container">
                    <span
                        className="label">{formatNumber(cashbackMerchantDictionary[0].min, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}</span>
                            <div className="slider-input-wrapper">
                                <div className="input-wrapper">
                                    <span className="label">+</span>
                                    <input
                                        value={formData.extra_cashback}
                                        onChange={(value) => handleChange("extra_cashback", value)}
                                        min={cashbackMerchantDictionary[0].min}
                                        max={cashbackMerchantDictionary[2].max}
                                        className="form-control"
                                        type="number"
                                    />
                                </div>
                                <span className="label">%</span>
                            </div>
                            <span
                                className="label">{formatNumber(cashbackMerchantDictionary[2].max, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}</span>
                        </div>
                        <Slider
                            min={cashbackMerchantDictionary[0].min}
                            max={cashbackMerchantDictionary[2].max}
                            onChange={(value) => handleChange("extra_cashback", value)}
                            value={formData.extra_cashback}
                            step={0.01}
                            tooltip={{formatter: (value) => formatNumber(value, DOUBLE_DECIMAL_NUMBER_FORMAT_PERCENTAGE, 100)}}
                        />
                        <div className="cashback-size-select">
                            {cashbackMerchantDictionary.map((size, index) =>
                                <div
                                    key={`CashbackPill${index}`}
                                    className={`cashback-size-pill ${size.name.toLowerCase()} ${isPillSelected(size) ? 'selected' : ''}`}
                                >
                                    <img src={size.iconUri} alt=""/>
                                    <span>{size.label}</span>
                                </div>
                            )}
                        </div>
                    </div>
                }
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Wybierz punkty biorące udział w promocji
                            <span className="required-mark"> *</span>
                        </p>
                        <Select
                            value={formData.offer_merchant_points}
                            onChange={(value) => {
                                handleChange("offer_merchant_points", value)
                            }}
                            allowClear={true}
                            mode="multiple"
                            placeholder="Wybierz punkty..."
                            tagRender={tagRender}
                            showSearch={false}
                            className="dropdown-select"
                        >
                            {merchantPoints.map((merchantPoint: MerchantPointSimple) => (
                                <Option
                                    key={merchantPoint.id}
                                    value={merchantPoint.id}
                                >
                                    {getAddressWithoutCountry(merchantPoint)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                 {step2Errors.step2?.offer_merchant_points &&
                    <div className="section validation">
                        <div className="error-message">{step2Errors.step2?.offer_merchant_points}</div>
                    </div>
                }
                </div>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Czas trwania
                            <span className="required-mark"> *</span>
                        </p>
                        <FiltersDatePicker
                            fromToday
                            title={null}
                            withIcon
                            fromDate={formData.start_date}
                            setFromDate={(value) => handleChange("start_date", value)}
                            toDate={formData.end_date}
                            setToDate={(value) => {
                                handleChange("end_date", value)
                                if (isEmpty(formData.offer_days)) {
                                    handleChange("offer_days", [
                                        {value: "MONDAY" as OfferDays, label: 'W poniedziałek'},
                                        {value: "TUESDAY" as OfferDays, label: 'We wtorek'},
                                        {value: "WEDNESDAY" as OfferDays, label: 'W środę'},
                                        {value: "THURSDAY" as OfferDays, label: 'W czwartek'},
                                        {value: "FRIDAY" as OfferDays, label: 'W piątek'},
                                        {value: "SATURDAY" as OfferDays, label: 'W sobotę'},
                                        {value: "SUNDAY" as OfferDays, label: 'W niedzielę'}
                                    ])
                                }
                            }}
                        />
                    </div>
                </div>
                {step2Errors.step2?.date &&
                    <div className="section">
                        <div className="error-message">{step2Errors.step2?.date}</div>
                    </div>
                }
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Dni obowiązywania promocji</p>
                        <Select
                            value={formData.offer_days}
                            onChange={(value) => {
                                handleChange("offer_days", value)
                            }}
                            allowClear={true}
                            mode="multiple"
                            placeholder="Ustal w jakie dni ma obowiązywać promocja..."
                            tagRender={tagRender}
                            showSearch={false}
                            className="dropdown-select"
                            options={[
                                {value: "MONDAY" as OfferDays, label: 'W poniedziałek'},
                                {value: "TUESDAY" as OfferDays, label: 'We wtorek'},
                                {value: "WEDNESDAY" as OfferDays, label: 'W środę'},
                                {value: "THURSDAY" as OfferDays, label: 'W czwartek'},
                                {value: "FRIDAY" as OfferDays, label: 'W piątek'},
                                {value: "SATURDAY" as OfferDays, label: 'W sobotę'},
                                {value: "SUNDAY" as OfferDays, label: 'W niedzielę'}
                            ]}
                        />
                    </div>
                </div>
                <div className="section">
                    <p className="label label-margin">Liczba dostępnych użyć</p>
                    <div className="radio-wrapper">
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="usage"
                                checked={formData.usage === "one_time"}
                                value="one_time"
                                onChange={e => handleChange("usage", e.target.value)}
                            />
                            <p className="label">jednorazowa</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="usage"
                                checked={formData.usage === "unlimited"}
                                value="unlimited"
                                onChange={e => handleChange("usage", e.target.value)}
                            />
                            <p className="label">nielimitowana</p>
                        </label>
                    </div>
                </div>
                <div className="section">
                    <div className="one-line select-wrapper">
                        <p className="label">Użyj bezpośrednich zwrotów do klienta</p>
                        <Switch checked={formData.direct_addressing}
                                setChecked={(value) => {
                                    handleChange("direct_addressing", value);
                                }}/>
                    </div>
                    <div className="radio-wrapper">
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.direct_addressing_style === "Hej Adam,..."}
                                disabled={!formData.direct_addressing}
                                value="Hej Adam,..."
                                onChange={e => handleChange("direct_addressing_style", e.target.value)}
                            />
                            <p className="label">"Hej Adam,..."</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.direct_addressing_style === "Cześć Adam,..."}
                                disabled={!formData.direct_addressing}
                                value="Cześć Adam,..."
                                onChange={e => handleChange("direct_addressing_style", e.target.value)}
                            />
                            <p className="label">"Cześć Adam,..."</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="addressing"
                                checked={formData.direct_addressing_style === "Adam,..."}
                                disabled={!formData.direct_addressing}
                                value="Adam,..."
                                onChange={e => handleChange("direct_addressing_style", e.target.value)}
                            /><p className="label">"Adam,..."</p>
                        </label>
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper one-line">
                        <p className="label">Wyślij powiadomienia push do użytkowników</p>
                        <Switch checked={formData.send_push_notification}
                                setChecked={(value) => {
                                    handleChange("send_push_notification", value);
                                }}/>
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label w700">{showCustomersNumber(numberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper">
                        <span className="label">Łączny koszt promocji: </span>
                        <span className="label w700">{formatNumber(estimateCost)}</span>
                    </div>
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <div className="modal-footer">
                    <Button label="Wstecz"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Dalej"
                            disabled={isNextDisabled}
                            onClick={() => {
                                if (!isSubmitted) {
                                    setIsSubmitted(true);
                                } else {
                                    setIsNextDisabled(!validateStep2)
                                    nextStep()
                                }
                            }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step2;