import {
    AccountingFilterAdminOptions,
    FilterButtonsInterface,
    FilterModalProps
} from "../../../services/interfaces";
import Modal from "react-modal";
import {icons} from "../../../services/images";
import React, {useEffect, useState} from "react";
import Button from "../../common/Button";
import FiltersButtons from "../filters/FiltersButtons";
import FiltersDatePicker from "../filters/FiltersDatePicker";
import {toLocalISOString} from "../../../services/dates";
import FiltersSlider from "../filters/FiltersSlider";
import {getAccountingFiltersHandler} from "../../../handlers/merchantHandler";
import {getAccountingFiltersAdminHandler} from "../../../admin/handlers/adminAccountingHandlers";
import FiltersDropdown from "../filters/FiltersDropdown";

const AccountingDocumentsFiltersModal = ({open, setOpen, setFilters, extraParam}: FilterModalProps) => {
    const [filterOptions, setFilterOptions] = useState<AccountingFilterAdminOptions>({
        min_net: 0,
        max_net: 100,
        min_gross: 0,
        max_gross: 100,
        merchant: [],
    });

    type DocumentType = 'invoice' | 'debit_note';
    const documentTypesOptions: FilterButtonsInterface<DocumentType>[] = [
        {value: 'invoice', label: 'Faktura', icon: icons.coinGreenIcon, color: "#00F6A1"},
        {value: 'debit_note', label: 'Nota obciążeniowa', icon: icons.coinBluePrimaryIcon, color: '#213DFC'},
    ]
    const DEFAULT_DOCUMENT_TYPES_STATE: Record<DocumentType, boolean> = {
        invoice: false,
        debit_note: false,
    }

    const isAdminModal = extraParam && extraParam === 'admin';

    const [documentTypes, setDocumentTypes] = useState<Record<DocumentType, boolean>>(DEFAULT_DOCUMENT_TYPES_STATE);
    const [issueFromDate, setIssueFromDate] = useState<Date | null>(null);
    const [issueToDate, setIssueToDate] = useState<Date | null>(null);
    const [sellFromDate, setSellFromDate] = useState<Date | null>(null);
    const [sellToDate, setSellToDate] = useState<Date | null>(null);
    const [netSliderAmount, setNetSliderAmount] = useState<[number, number] | null>([0, 100]);
    const [grossSliderAmount, setGrossSliderAmount] = useState<[number, number] | null>([0, 100]);
    const [merchants, setMerchants] = useState<string[]>([]);

    useEffect(() => {
        if (isAdminModal) {
            getAccountingFiltersAdminHandler(setFilterOptions)
        } else {
            getAccountingFiltersHandler(setFilterOptions)
        }
    }, []);

    useEffect(() => {
        setNetSliderAmount([filterOptions.min_net, filterOptions.max_net]);
        setGrossSliderAmount([filterOptions.min_gross, filterOptions.max_gross]);
    }, [filterOptions]);

    const clearFilters = () => {
        setDocumentTypes(DEFAULT_DOCUMENT_TYPES_STATE);
        setIssueToDate(null);
        setIssueFromDate(null);
        setSellToDate(null);
        setSellFromDate(null);
        setNetSliderAmount([0, 100]);
        setGrossSliderAmount([0, 100]);
        setMerchants([]);
    }

    const applyFilters = () => {

        if (!netSliderAmount || !grossSliderAmount) {
            return;
        }

        const documentTypeString = Object.keys(documentTypes)
            .filter(key => documentTypes[key as keyof Record<DocumentType, boolean>])
            .join(',');

        const urlFilters =
            (documentTypeString !== '' ? `document_types=${documentTypeString};` : '') +
            (isAdminModal && merchants.length > 0 ? `merchants=${merchants.join(',')};` : '') +
            (issueFromDate ? `issue_from=${toLocalISOString(issueFromDate)};` : '') +
            (issueToDate ? `issue_to=${toLocalISOString(issueToDate)};` : '') +
            (sellFromDate ? `sale_from=${toLocalISOString(sellFromDate)};` : '') +
            (sellToDate ? `sale_to=${toLocalISOString(sellToDate)};` : '') +
            `net_amount=${netSliderAmount.join(',')};` +
            `gross_amount=${grossSliderAmount.join(',')}`;


        setFilters(urlFilters);
        setOpen(false);
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersButtons<DocumentType>
                    selected={documentTypes}
                    setSelected={setDocumentTypes}
                    options={documentTypesOptions}
                    title="Typ dokumentu"
                />
                {isAdminModal &&
                    <FiltersDropdown
                        title="Firma"
                        placeholder="Wybierz firmy"
                        options={filterOptions.merchant && filterOptions.merchant.map(merchant =>
                            ({children: merchant.id.toString(), value: `${merchant.name} (NIP: ${merchant.nip})`}))
                        }
                        values={merchants}
                        setValues={setMerchants}
                        setChildren={true}
                    />
                }
                <FiltersDatePicker
                    fromDate={issueFromDate}
                    setFromDate={setIssueFromDate}
                    toDate={issueToDate}
                    setToDate={setIssueToDate}
                    title="Data wystawienia"
                />
                <FiltersDatePicker
                    fromDate={sellFromDate}
                    setFromDate={setSellFromDate}
                    toDate={sellToDate}
                    setToDate={setSellToDate}
                    title="Data sprzedaży"
                />
                <FiltersSlider
                    label="Wartość netto"
                    min={filterOptions.min_net}
                    max={filterOptions.max_net}
                    value={netSliderAmount}
                    onChange={setNetSliderAmount}
                />
                <FiltersSlider
                    label="Wartość brutto"
                    min={filterOptions.min_gross}
                    max={filterOptions.max_gross}
                    value={grossSliderAmount}
                    onChange={setGrossSliderAmount}
                />
            </div>
            <div className="modal-footer">
                <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>
            </div>
        </Modal>
    );
}

export default AccountingDocumentsFiltersModal;