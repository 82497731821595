import {
    Employee,
    EmployeeCalendar,
    OrderType,
    Reservation,
    ReservationSort,
    TableHeader
} from "../../../services/interfaces";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {
    getEmployees,
    getReservationTableCount,
    getReservationTableData,
    getReservationTableMaxPage
} from "../../../redux/selectors/bookingSelector";
import {
    downloadReservationTableHandler,
    getEmployeeCalendarHandler,
    getEmployeesHandler, getReservationHandler,
    getReservationTableHandler
} from "../../../handlers/bookingHandlers";
import Table from "../../common/Table";
import ReservationDetailsModal from "./ReservationDetailsModal";
import ReservationTableRow from "./ReservationTableRow";
import {icons, sidebarIcons} from "../../../services/images";
import ReservationFiltersModal from "./ReservationFiltersModal";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";
import ReservationTableCalendarView from "./ReservationTableCalendarView";
import EmployeeScheduleModal from "../edit/EmployeeScheduleModal";
import EmployeeVacationModal from "../edit/EmployeeVacationModal";
import EmployeeUnavailabilityModal from "../edit/EmployeeUnavailabilityModal";
import ActionModal from "../../modals/ActionModal";
import {isEmpty} from "lodash";
import dayjs from "dayjs";


export const RESERVATION_TABLE_HEADERS: TableHeader<ReservationSort>[] = [
    {name: 'ID rezerwacji', sort: 'id', digit: false},
    {name: 'ID klienta', sort: 'client_id', digit: false},
    {name: 'Pracownik', sort: 'employee', digit: false},
    {name: 'Data wizyty', sort: 'appointment_date', digit: false},
    {name: 'Status', sort: 'status', digit: false},
    {name: 'Rodzaj usługi', sort: 'service_name', digit: false},
    {name: 'Kwota (zł)', sort: 'amount', digit: true},
];


const ReservationTable = () => {
    // Common State
    const [showCalendar, setShowCalendar] = useState(false);
    const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [isVacationModalOpen, setVacationModalOpen] = useState(false);
    const [isUnavailabilityModalOpen, setUnavailabilityModalOpen] = useState(false);

    // Table State
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const [tableId, setTableId] = useState<number | null>(null);
    const [calendarId, setCalendarId] = useState<number | null>(null);
    const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const actionRef = useRef<(HTMLDivElement | null)>(null);
    const data: Reservation[] = useSelector(getReservationTableData);
    const maxPage: number = useSelector(getReservationTableMaxPage);
    const count: number = useSelector(getReservationTableCount);
    const merchantPoint = useSelector(getCurrentMerchantPoint);


    // Calendar State
    const [employee, setEmployee] = useState<Employee | null>(null);
    const [employeeCalendar, setEmployeeCalendar] = useState<EmployeeCalendar | null>(null);
    const [month, setMonth] = useState<dayjs.Dayjs>(dayjs());
    const employees = useSelector(getEmployees);

    const updateTableCallback = useCallback(() => {
        getReservationTableHandler({
            page,
            query,
            sort,
            order,
            filters: filterString,
            pageSize
        })
    }, [page, query, sort, order, filterString, pageSize]);


    const updateCalendarCallback = useCallback((shouldUpdate = false) => {
        const hasEmployeeChanged = employeeCalendar?.employee.id !== employee?.id;
        const hasMonthChanged = dayjs(employeeCalendar?.month_date).format('YYYY-MM') !== month.format('YYYY-MM');
        const shouldGetCalendarForEmployee = employeeCalendar && (hasEmployeeChanged || hasMonthChanged);

        const canUpdate = showCalendar && !isEmpty(employees);

        if (canUpdate) {
            if (!employee) {
                const firstEmployee = employees[0];
                setEmployee(firstEmployee);
                getEmployeeCalendarHandler(firstEmployee.id, month.format('YYYY-MM-DD'), setEmployeeCalendar)
            } else if (shouldUpdate || shouldGetCalendarForEmployee) {
                getEmployeeCalendarHandler(employee.id, month.format('YYYY-MM-DD'), setEmployeeCalendar)
                if (calendarId) {
                    getReservationHandler(calendarId);
                }
            }
        }
    }, [employees, month, employee, showCalendar, employeeCalendar, calendarId]);


    // Table Update Effects
    useEffect(() => {
        updateTableCallback()
    }, [updateTableCallback, merchantPoint]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);


    // Calendar Update Effects
    useEffect(() => {
        updateCalendarCallback()
    }, [updateCalendarCallback]);

    useEffect(() => {
        showCalendar && getEmployeesHandler()
    }, [showCalendar]);


    const toggleActionModal = () => {
        const item = actionRef.current;
        if (item) {
            const {right, bottom} = item.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const TableViewButton = () => (
        <div className="manage" onClick={() => setShowCalendar(!showCalendar)}>
            <img src={showCalendar ? sidebarIcons.historyActive : sidebarIcons.bookingActive} alt="Manage" width={20}/>
            <p className="label-small">{showCalendar ? "Pokaż tabele" : "Pokaż kalendarz"}</p>
        </div>
    );

    const MoreActionButton = () => (
        <div className="more-actions" onClick={toggleActionModal} ref={actionRef}>
            <img src={icons.cogIcon} alt="Manage"/>
            <p className="label-small">Więcej opcji</p>
            <ActionModal isOpen={isActionModalOpen}
                         onClose={toggleActionModal}
                         actions={actions}
                         x={actionModalPosition.x} y={actionModalPosition.y}/>
        </div>
    );

    const TableButtons = () => (
        <>
            <MoreActionButton/>
            <TableViewButton/>
        </>
    );

    const DEFAULT_ACTIONS = useMemo(() => [
        {
            title: 'Zarządzaj Harmonogramem',
            onClick: () => {
                setScheduleModalOpen(true);
                setIsActionModalOpen(false);
            },
            className: '',
        },
        {
            title: 'Ustal niedyspozycjność',
            onClick: () => {
                setUnavailabilityModalOpen(true);
                setIsActionModalOpen(false);
            },
            className: '',
        },
        {
            title: 'Ustal urlop',
            onClick: () => {
                setVacationModalOpen(true);
                setIsActionModalOpen(false);
            },
            className: '',
        },
    ], []);

    const actions = useMemo(() => {
        if (showCalendar) return DEFAULT_ACTIONS;
        return [
            {
                title: 'Pobierz',
                onClick: () => {
                    downloadReservationTableHandler({query, sort, order, filters: filterString});
                    setIsActionModalOpen(false);
                },
                className: '',
            },
            ...DEFAULT_ACTIONS];
    }, [showCalendar, DEFAULT_ACTIONS, query, sort, order, filterString]);


    return (
        <>
            <h3>Rezerwacje</h3>
            <div className="block-wrapper">
                {showCalendar ?
                    <ReservationTableCalendarView employeeCalendar={employeeCalendar}
                                                  employee={employee}
                                                  setEmployee={setEmployee}
                                                  setMonth={setMonth}
                                                  reservationId={calendarId}
                                                  setReservationId={setCalendarId}
                                                  TableButtons={<TableButtons/>}
                    /> :
                    <Table page={page}
                           setPage={setPage}
                           pageSize={pageSize}
                           setPageSize={setPageSize}
                           query={query}
                           setQuery={setQuery}
                           sort={sort}
                           setSort={setSort}
                           order={order}
                           setOrder={setOrder}
                           count={count}
                           filters={filterString}
                           setFilters={setFilterString}
                           maxPage={maxPage}
                           headers={RESERVATION_TABLE_HEADERS}
                           renderRows={() => data.map((reservation, index) =>
                               <ReservationTableRow reservation={reservation}
                                                    filters={filterString}
                                                    key={`ReservationRow${index}`}
                                                    onClick={() => setTableId(reservation.id)}
                                                    updateTableCallback={updateTableCallback}
                               />
                           )}
                           Modal={ReservationFiltersModal}
                           extraButtons={<TableButtons/>}
                           hasActionButton={true}
                    />
                }
            </div>
            <ReservationDetailsModal reservationId={tableId} onClose={() => setTableId(null)}
                                     updateTableCallback={updateTableCallback}/>
            <EmployeeScheduleModal isOpen={isScheduleModalOpen} setIsOpen={setScheduleModalOpen}
                                   updateCallback={() => updateCalendarCallback(true)}/>
            <EmployeeVacationModal isOpen={isVacationModalOpen} setIsOpen={setVacationModalOpen}
                                   updateCallback={() => updateCalendarCallback(true)}/>
            <EmployeeUnavailabilityModal isOpen={isUnavailabilityModalOpen} setIsOpen={setUnavailabilityModalOpen}
                                         updateCallback={() => updateCalendarCallback(true)}/>
        </>
    )
};

export default ReservationTable;