import {SidebarItem} from "../../services/interfaces";
import {getSidebarIcon} from "../../services/images";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import SidebarCupsCount from "./SidebarCupsCount";
import {getIsMerchantSelected, getMerchantReturnsCount} from "../../redux/selectors/merchantSelector";
import {useDispatch, useSelector} from "react-redux";
import {modalOpenAction, NavigationModal} from "../../redux/navigation";
import {getUnreadChatsCount} from "../../redux/selectors/chatSelectors";


interface SidebarItemProps extends SidebarItem {
    isMerchantConfigured?: boolean
}

const NavigationSidebarItem = ({path, title, name, modal, isMerchantConfigured}: SidebarItemProps) => {
    const location = useLocation();
    const isActive = path ? location.pathname.includes(path) : false;
    let isDisabled = !['contact', 'settings'].includes(name) && !isMerchantConfigured;
    const showCupCount = name === 'cups' && !isDisabled;
    const dispatch = useDispatch();

    const returnsCount = useSelector(getMerchantReturnsCount);
    const unreadChatsCount = useSelector(getUnreadChatsCount);
    const isMerchantSelected = useSelector(getIsMerchantSelected);

    if (isMerchantSelected && name === 'booking') {
        isDisabled = true
    }

    let className = '';
    if (isDisabled)
        className += 'disabled';
    if (isActive)
        className += 'active';
    if (showCupCount)
        className += ' sidebar-cups-item';

    return (
        <li className={className}>
            {isDisabled ?
                <span className="link disabled">
                    <img className="list-icon" alt={`${name}icon`} src={getSidebarIcon(name, false)}/>
                    {title}
                </span> :
                (modal ?
                        <a href="#" onClick={() => dispatch(modalOpenAction(name as NavigationModal))}>
                            <img className="list-icon" alt={`${name}icon`}
                                 src={getSidebarIcon(name, isActive)}></img>{title}
                            {name === 'contact' && unreadChatsCount && unreadChatsCount > 0 ?
                                <span className="badge-attention">{unreadChatsCount}</span> : <span/>}
                        </a> :
                        <Link to={path || '/'}>
                            <img className="list-icon" alt={`${name}icon`} src={getSidebarIcon(name, isActive)}/>
                            {title} {name === 'history' && returnsCount > 0 && <span className="badge-attention">{returnsCount}</span>}
                        </Link>
                )
            }
            {name === 'cups' && !isDisabled && <SidebarCupsCount/>}
        </li>
    )
};

export default NavigationSidebarItem;
