import request, {DELETE, GET, POST, PUT} from "./request";
import {handleError} from "./handleError";
import {
    UpdateOfferData
} from "../services/interfaces";

export const getMerchantOffers = async () => {
    try {
        const {data} = await request(GET, `offer/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointOffers = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `offer/point/${merchantPointId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const createOffer = async (offerData: FormData) => {
    try {
        const {data} = await request(POST, `offer/`, offerData,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateOffer = async (offerId: number, updateData: FormData) => {
    try {
        const {data} = await request(PUT, `offer/${offerId}`, updateData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteOffer = async (offerId: number) => {
    try {
        const {data} = await request(DELETE, `offer/${offerId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const duplicateOffer = async (offerId: number) => {
    try {
        const {data} = await request(POST, `offer/${offerId}/duplicate`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantOfferDashboard = async (offer_id: number, urlParam: string) => {
    try {
        const {data} = await request(GET,`offer/${offer_id}/dashboard?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
}
export const getMerchantPointOfferDashboard = async (offer_id: number, merchantPointId: number, urlParam: string) => {
    try {
        const {data} = await request(GET,`offer/${offer_id}/point/${merchantPointId}/dashboard?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
}