import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import CalendarWithSlots from "../edit/CalendarWithSlots";
import {Employee, EmployeeCalendar, EmployeeCalendarDay, TimeSlot} from "../../../services/interfaces";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {getEmployees} from "../../../redux/selectors/bookingSelector";
import {isEmpty} from "lodash";
import LoadingScreen from "../../common/LoadingScreen";
import NoReservations from "./NoReservations";
import {mapToSlotsWithReservations, useEmployeeCalendarCurrentDay} from "../../../services/helpers";
import ReservationDetailsView from "./ReservationDetailsView";

interface Props {
    employeeCalendar: EmployeeCalendar | null;
    TableButtons: React.ReactNode
    employee: Employee | null;
    setEmployee: Dispatch<SetStateAction<Employee | null>>;
    setMonth: Dispatch<SetStateAction<dayjs.Dayjs>>
    reservationId: number | null;
    setReservationId: Dispatch<SetStateAction<number | null>>;
}


const ReservationTableCalendarView = ({employeeCalendar, employee, setEmployee, setMonth, TableButtons, reservationId, setReservationId}: Props) => {
    const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(dayjs());
    const [selectedSlot, setSelectedSlot] = useState<[string, TimeSlot] | null>(null);
    const employees = useSelector(getEmployees);


    const currentDay: [string, EmployeeCalendarDay] | undefined = useEmployeeCalendarCurrentDay(employeeCalendar, currentDate);

    const slotClassName = useCallback((slot: [string, TimeSlot]) => {
        let className = slot[1].status;
        if ((selectedSlot?.[0] === slot[0]) || (slot[1].reservation_id !== null && slot[1].reservation_id === reservationId))
            className += ' selected';
        return className
    }, [reservationId, selectedSlot]);


    useEffect(() => {
        if (currentDay) {
            const slotsWithReservations = mapToSlotsWithReservations(currentDay[1].slots);
            if (!isEmpty(slotsWithReservations))
                return setReservationId(slotsWithReservations[0].reservation_id)
        }
        setReservationId(null);
    }, [currentDay]);


    return (
        <div className="block block-1-1 table-block reservation-calendar-view">
            <div className="table-header">
                <div/>
                <div className="table-button-wrapper">
                    {TableButtons}
                </div>
            </div>
            <div className="table-content-wrapper">
                <div className="calendar-view">
                    {!employee || isEmpty(employees) || !employeeCalendar ?
                        <LoadingScreen/> :
                        <>
                            <div className="tab-manager">
                                {employees.map(e =>
                                    <div
                                        onClick={() => (e.id !== employee.id) && setEmployee(e)}
                                        className={`tab-pill ${e.id === employee.id ? 'selected' : ''}`}
                                        key={`Employee-${e.id}`}
                                    >
                                        <span>{e.name}</span>
                                    </div>
                                )}
                            </div>
                            <CalendarWithSlots
                                employeeCalendar={employeeCalendar}
                                setCurrentDate={setCurrentDate}
                                currentDate={currentDate}
                                currentDay={currentDay}
                                setMonth={setMonth}
                                slotClassName={slotClassName}
                                disabledPreviousPeriod={true}
                                onSlotClick={(slot: [string, TimeSlot]) => {
                                    setSelectedSlot(slot);
                                    setReservationId(slot[1].reservation_id)
                                }}/>
                        </>
                    }
                </div>
                {reservationId ?
                    <ReservationDetailsView reservationId={reservationId}/> :
                    <NoReservations/>
                }
            </div>
        </div>
    )
};

export default ReservationTableCalendarView;