import {
    ChartInterval,
    DateRange, OfferDashboardData,
    OfferData, PreviousPeriod, UpdateOfferData
} from "../services/interfaces";
import {store} from "../redux/store";
import {
    setCurrentOfferAction, setOfferDashboardAction,
    setOffersAction,
} from "../redux/merchant";
import {
    createOffer,
    deleteOffer,
    duplicateOffer,
    getMerchantOffers, getMerchantPointOffers,
    getMerchantOfferDashboard,
    updateOffer, getMerchantPointOfferDashboard
} from "../http/offers";
import {getCurrentMerchantPoint, getCurrentOffer, getIsMerchantSelected} from "../redux/selectors/merchantSelector";
import {isEmpty} from "lodash";
import {setScreenLoadingAction} from "../redux/navigation";


export const getOffersHandler = () => {
        const isMerchantSelected = getIsMerchantSelected(store.getState());
        const merchantPoint = getCurrentMerchantPoint(store.getState());
    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointOffers(merchantPoint.id).then((data: OfferData[]) => {
            store.dispatch(setOffersAction(data));
            !isEmpty(data) && store.dispatch(setCurrentOfferAction(data[0]));
        }).catch(error => {
        console.error(error);
        })
    } else {
        getMerchantOffers().then((data: OfferData[]) => {
            store.dispatch(setOffersAction(data));
            !isEmpty(data) && store.dispatch(setCurrentOfferAction(data[0]));
        }).catch(error => {
        console.error(error);
    })
    }
}

export const createOfferHandler = ( formData: FormData) => {
    createOffer((formData)).then((data: OfferData) => {
        getOffersHandler();
        store.dispatch(setCurrentOfferAction(data))
    }).catch(error => {
        console.error(error);
    })
}

export const updateOfferHandler = (originalOffer: OfferData,
                                         editedOffer: OfferData) => {

    const addDataFromBasicFields = (updateData: UpdateOfferData) => {
        const basicFields: (keyof OfferData)[] = ["clients", 'name', 'target_group', 'offer_localizations', 'estimated_cost','number_of_customers','extra_customers',
        "type","extra_cashback","offer_merchant_points","start_date","end_date","offer_days","usage","description","direct_addressing","direct_addressing_style","send_push_notification",
        "image_path","film_path","film_title","film_layout","film_file"];
        basicFields.forEach(field => {
            if (editedOffer[field] !== originalOffer[field]) {
                updateData[field] = editedOffer[field] as any;
            }
        });
    };

    if (editedOffer.id) {
        const updateData: UpdateOfferData = {};
        addDataFromBasicFields(updateData);
        const sendData = new FormData();
        const offerData: UpdateOfferData = {
            ...updateData,
        }
        sendData.append('data', JSON.stringify(offerData));
        if (updateData.film_file) {
        sendData.append('film_file', updateData.film_file);
        }
        updateOffer(editedOffer.id, sendData).then((updatedOffer: OfferData) => {
            getOffersHandler();
            const currentOffer = getCurrentOffer(store.getState());
            if (currentOffer && currentOffer.id === updatedOffer.id)
                setCurrentOfferAction(updatedOffer);
        }).catch(error => {
            console.error(error);
        })
    }

};

export const deleteOfferHandler = (offerId: number) => {
    deleteOffer(offerId).then(() => {
        getOffersHandler();
    }).catch(error => {
        console.error(error);
    })
};

export const duplicateOfferHandler = (offerId: number) => {
    duplicateOffer(offerId).then(() => {
        getOffersHandler();
    }).catch(error => {
        console.error(error);
    })
};

export const getOfferDashboardDataHandler = (
    dateRange: DateRange,
    previousPeriod: PreviousPeriod,
    chartInterval: ChartInterval
) => {
    const isMerchantSelected = getIsMerchantSelected(store.getState());
    const merchantPoint = getCurrentMerchantPoint(store.getState());
    const currentOffer = getCurrentOffer(store.getState())
    const urlParam = new URLSearchParams();
    urlParam.append('start_date', dateRange.startDate);
    urlParam.append('end_date', dateRange.endDate);
    const offerID = currentOffer.id ? currentOffer.id : 0;
    const calculateFrom = previousPeriod === "year" ? "PREVIOUS_YEAR" : chartInterval;
    urlParam.append('calculate_previous_period_from', calculateFrom);
    store.dispatch(setScreenLoadingAction(true));
    if (!isMerchantSelected && merchantPoint && merchantPoint.id) {
        getMerchantPointOfferDashboard(offerID, merchantPoint.id, urlParam.toString()).then((dashboardData: OfferDashboardData) => {
            store.dispatch(setOfferDashboardAction(dashboardData));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        })
    } else {
        getMerchantOfferDashboard(offerID,urlParam.toString()).then((dashboardData: OfferDashboardData) => {
            store.dispatch(setOfferDashboardAction(dashboardData));
            store.dispatch(setScreenLoadingAction(false));
        }).catch(error => {
            console.error(error);
            store.dispatch(setScreenLoadingAction(false));
        })
    }
}