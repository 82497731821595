import React, {useCallback, useState} from 'react';
import Modal from 'react-modal';
import {AffectedReservations, EmployeeSchedule} from "../../../services/interfaces";
import {icons} from "../../../services/images";
import Button from "../../common/Button";
import {updateEmployeeScheduleHandler} from "../../../handlers/bookingHandlers";
import LoadingScreen from "../../common/LoadingScreen";
import ErrorComponent from "../../common/ErrorComponent";
import {useDispatch, useSelector} from "react-redux";
import {getAffectedReservations} from "../../../redux/selectors/bookingSelector";
import {setAffectedReservationsAction} from "../../../redux/booking";
import {getReservationDateTime, getReservationTime} from "../../../services/dates";

interface Props {
    employeeSchedule: EmployeeSchedule
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
}


const AffectedReservationsModal = ({isOpen, setIsOpen, employeeSchedule, onSave}: Props) => {
    const [tab, setTab] = useState<'cancel' | 'postpone'>('cancel');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch();
    const affectedReservations: AffectedReservations | null = useSelector(getAffectedReservations);

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setError(null);
        dispatch(setAffectedReservationsAction(null));
    };

    const saveCallback = useCallback(() => {
        setIsLoading(false);
        toggleModal();
        onSave();
    }, [setIsLoading, onSave]);

    const errorCallback = useCallback((error: string) => {
        setError(error);
        setIsLoading(false);
    }, [setIsLoading, setError]);

    const onConfirm = useCallback(() => {
        if (affectedReservations) {
            setIsLoading(true);
            updateEmployeeScheduleHandler(employeeSchedule, saveCallback, errorCallback)
        }

    }, [affectedReservations, saveCallback, errorCallback, employeeSchedule]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content affected-reservations-modal"
        >
            <div className="modal-header">
                <div/>
                <h5>Zmiana harmonogramu</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {!affectedReservations ?
                <LoadingScreen/> :
                <div className="modal-body">
                    <div className="tab-manager">
                        <div onClick={() => tab === 'postpone' && setTab('cancel')}
                             className={`tab-pill ${tab === 'cancel' ? 'selected' : ''}`}>
                            <span>Rezerwacje do odwołania: <b>{affectedReservations.reservations_to_cancel.length}</b></span>
                        </div>
                        <div onClick={() => tab === 'cancel' && setTab('postpone')}
                             className={`tab-pill ${tab === 'postpone' ? 'selected' : ''}`}>
                            <span>Rezerwacje do przełożenia: <b>{affectedReservations.reservations_to_postpone.length}</b></span>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        {tab === 'cancel' ?
                            <table>
                                <thead>
                                <tr>
                                    <th>ID klienta</th>
                                    <th>ID rezerwacji</th>
                                    <th className="date-cell">Data wizyty</th>
                                </tr>
                                </thead>
                                <tbody>
                                {affectedReservations.reservations_to_cancel.map(r => (
                                    <tr>
                                        <td>#{r.user_id}</td>
                                        <td>#{r.id}</td>
                                        <td className="date-cell">{getReservationDateTime(r.appointment_date, r.duration)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table> :
                            <table>
                                <thead>
                                <tr>
                                    <th>ID klienta</th>
                                    <th>ID rezerwacji</th>
                                    <th className="date-cell">Data wizyty</th>
                                    <th className="date-cell">Nowa godzina wizyty</th>
                                </tr>
                                </thead>
                                <tbody>
                                {affectedReservations.reservations_to_postpone.map(r => (
                                    <tr>
                                        <td>#{r[0].user_id}</td>
                                        <td>#{r[0].id}</td>
                                        <td className="date-cell previous">{getReservationDateTime(r[0].appointment_date, r[0].duration)}</td>
                                        <td className="date-cell"><b>{getReservationTime(r[1], r[0].duration)}</b></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        }
                    </div>

                    {error && <ErrorComponent error={error}/>}
                </div>
            }

            <div className="modal-footer">
                <Button label="Potwierdź" onClick={onConfirm} fullWidth disabled={!affectedReservations}
                        loading={isLoading}/>
            </div>
        </Modal>
    );
};

export default AffectedReservationsModal;

