import React, {useEffect, useRef, useState} from "react";
import {
    downloadAccountingDocumentAdminPDFHandler,
    getAccountingDocumentsAdminHandler, getAccountingDocumentsXLSXAdminHandler
} from "../../handlers/adminAccountingHandlers";
import {AccountingDocumentAdminResponse, HTMLElementPosition, OrderType} from "../../../services/interfaces";
import Table from "../../../components/common/Table";
import {dateFormatAccountingDocument} from "../../../services/dates";
import {icons} from "../../../services/images";
import {getDocumentTypeFormatName} from "../../../services/helpers";
import AccountingDocumentsFiltersModal from "../../../components/modals/deposit/AccountingDocumentsFiltersModal";
import ActionModal from "../../../components/modals/ActionModal";


const AccountingTable = () => {

    const [accountingDocuments, setAccountingDocuments] = useState<AccountingDocumentAdminResponse>({
        total: 0,
        max_page: 0,
        invoices: []
    });

    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sortBy, setSortBy] = useState<string>('issued_date');
    const [sortOrder, setSortOrder] = useState<OrderType>('desc');
    const [query, setQuery] = useState<string>('');
    const [filterString, setFilterString] = useState<string>('');

    const [actionModalOpen, setActionModalOpen] = useState<boolean>(false);
    const [actionModalPosition, setActionModalPosition] = useState<HTMLElementPosition | null>(null);
    const [actionDocumentID, setActionDocumentID] = useState<number>(0);
    const rowRefs = useRef<{ [key: number]: HTMLImageElement | null }>({});
    const [activeRow, setActiveRow] = useState<number | null>(null);

    const handleRowClick = (id: number) => {
        setActiveRow(id);
    };

    useEffect(() => {
        getAccountingDocumentsAdminHandler(
            setAccountingDocuments,
            page,
            pageSize,
            sortBy,
            sortOrder,
            filterString,
            query,
        );
    }, [page, pageSize, sortBy, sortOrder, filterString, query]);


    useEffect(() => {
        if (activeRow !== null && rowRefs.current[activeRow]) {
            const rect = rowRefs.current[activeRow]!.getBoundingClientRect();
            setActionModalPosition({x: rect.x, y: rect.y});
        }
    }, [activeRow]);

    const isInvoiceSelected = accountingDocuments.invoices?.find(invoice => invoice.id === actionDocumentID)?.document_type === 'invoice';
    const actionModalActions = [
        {
            title: 'Pobierz PDF',
            onClick: () => {
                downloadAccountingDocumentAdminPDFHandler(actionDocumentID);
            },
            className: 'primary'
        },
        ...(isInvoiceSelected ? [{
            title: 'Pobierz XML (KSeF)',
            onClick: () => {
                console.log('XML');
            },
            className: 'danger'
        }] : [])
    ]

    return (
        <div>
            <Table
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                query={query}
                setQuery={setQuery}
                sort={sortBy}
                setSort={setSortBy}
                order={sortOrder}
                setOrder={setSortOrder}
                filters={filterString}
                setFilters={setFilterString}
                count={accountingDocuments.total}
                maxPage={accountingDocuments.max_page}
                downloadHandler={() => getAccountingDocumentsXLSXAdminHandler(
                    sortBy,
                    sortOrder,
                    query,
                    filterString
                )}
                Modal={AccountingDocumentsFiltersModal}
                modalExtraParam="admin"
                hasActionButton={false}
                headers={[
                    {name: 'Firma', sort: 'merchant__name'},
                    {name: 'NIP', sort: 'merchant__nip'},
                    {name: 'Nr dokumentu', sort: 'invoice_number'},
                    {name: 'Typ', sort: 'document_type'},
                    {name: 'Data wystawienia', sort: 'issued_date'},
                    {name: 'Data sprzedaży', sort: 'sale_date'},
                    {name: 'Wartość netto (zł)', sort: 'amount_net', digit: true},
                    {name: 'Wartość brutto (zł)', sort: 'amount_gross', digit: true},
                    {name: 'Akcje', sort: '', digit: true}
                ]}
                renderRows={() => accountingDocuments.invoices.map(document => {
                    return <tr key={document.id} onClick={() => handleRowClick(document.id)}>
                        <td>{document.merchant.name}</td>
                        <td>{document.merchant.nip}</td>
                        <td>{document.invoice_number}</td>
                        <td>{getDocumentTypeFormatName(document.document_type)}</td>
                        <td>{dateFormatAccountingDocument(document.issued_date, false)}</td>
                        <td>{dateFormatAccountingDocument(document.sale_date, document.monthly_sale)}</td>
                        <td className="cell-digit">{document.amount_net}</td>
                        <td className="cell-digit">{document.amount_gross}</td>
                        <td className="cell-digit">
                            <img
                                ref={(el) => (rowRefs.current[document.id] = el)}
                                className="dots"
                                src={icons.actionsButtonIcon}
                                alt="Akcje"
                                onClick={() => {
                                    setActionModalOpen(true);
                                    setActionDocumentID(document.id);
                                }}
                            />
                        </td>
                    </tr>
                })}
            />
            <ActionModal
                isOpen={actionModalOpen}
                onClose={() => {
                    setActionModalOpen(false);
                }}
                actions={actionModalActions}
                x={activeRow !== null && actionModalPosition ? actionModalPosition.x - 10 : 0}
                y={activeRow !== null && actionModalPosition ? actionModalPosition.y : 0}
            />
        </div>
    )
}

export default AccountingTable;