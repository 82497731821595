import {OrderType} from "../../services/interfaces";
import request, {GET} from "../../http/request";
import {handleError} from "../../http/handleError";


export const getAccountingDocumentsAdmin = async (
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: OrderType,
    filterString: string,
    query: string,
) => {
    try {
        const {data} = await request(
            GET,
            `accounting/?page=${page}&page_size=${pageSize}&sort_by=${sortBy}&sort_order=${sortOrder}&query=${query}&filters=${filterString}`,
            {}, {}, true
        );
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getAccountingAdminFilters = async () => {
    try {
        const {data} = await request(GET, `accounting/filters/`, {}, {}, true);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const downloadAccountingDocumentAdminPDF = async (documentId: number) => {
    try {
        const {data} = await request(GET, `accounting/${documentId}/pdf/`, {}, {}, true);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getAccountingDocumentsXLSXAdmin = async (
    sortBy: string,
    sortOrder: OrderType,
    query: string,
    filterString: string,
) => {
    try {
        const {data} = await request(
            GET,
            `accounting/list/?sort_by=${sortBy}&sort_order=${sortOrder}&query=${query}&filters=${filterString}`,
            {}, {}, true);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}
