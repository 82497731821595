import {
    CategoryType,
    CreateCategoryItem, EmployeeSchedule,
    ImportCategoriesData, OrderDetails,
    OrderTableResponse, ReservationDetails,
    ReservationTableResponse,
    UpdateCategoryItem
} from "../services/interfaces";
import request, {DELETE, GET, POST, PUT} from "./request";
import {handleError} from "./handleError";


export const getReservationTable = async (merchantPointId: number, urlParams: string): Promise<ReservationTableResponse> => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/reservation/?${urlParams}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadReservations = async (merchantPointId: number, urlParams: string) => {
    try {
        return await request(GET, `booking/${merchantPointId}/reservation/download/?${urlParams}`)
    } catch (error) {
        throw handleError(error);
    }
};


export const getReservationFilterOptions = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/reservation/filter_options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getOrderTable = async (merchantPointId: number, urlParams: string): Promise<OrderTableResponse> => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/order/?${urlParams}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadOrders = async (merchantPointId: number, urlParams: string) => {
    try {
        return await request(GET, `booking/${merchantPointId}/order/download/?${urlParams}`)
    } catch (error) {
        throw handleError(error);
    }
};


export const getOrderFilterOptions = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/order/filter_options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getCategories = async (merchantPointId: number, categoryType: CategoryType) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/${categoryType}/category/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const createCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    name: string
) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/category/`, {name});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    name: string,
    categoryId: number
) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/${categoryType}/category/${categoryId}`, {name});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const deleteCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    categoryId: number
) => {
    try {
        const {data} = await request(DELETE, `booking/${merchantPointId}/${categoryType}/category/${categoryId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const moveCategory = async (
    merchantPointId: number,
    categoryType: CategoryType,
    categoryId: number,
    direction: "up" | "down",
) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/${categoryType}/category/${categoryId}/move/`, {direction});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getCategoryOptions = async (merchantPointId: number, categoryType: CategoryType) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/${categoryType}/category/options/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const importCategories = async (merchantPointId: number, categoryType: CategoryType, importData: ImportCategoriesData) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/import_categories/`, {...importData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const createCategoryItem = async (
    merchantPointId: number,
    categoryType: CategoryType,
    createData: CreateCategoryItem
) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/`, {...createData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateCategoryItem = async (
    merchantPointId: number,
    categoryType: CategoryType,
    updateData: UpdateCategoryItem,
    itemId: number,
) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/${categoryType}/${itemId}`, {...updateData});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const deleteCategoryItem = async (
    merchantPointId: number,
    categoryType: CategoryType,
    itemId: number,
) => {
    try {
        const {data} = await request(DELETE, `booking/${merchantPointId}/${categoryType}/${itemId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const downloadCategories = async (
    merchantPointId: number,
    categoryType: CategoryType
) => {
    try {
        return await request(GET, `booking/${merchantPointId}/${categoryType}/download/`)
    } catch (error) {
        throw handleError(error);
    }
};

export const importCategoryExcel = async (
    merchantPointId: number,
    categoryType: CategoryType,
    file: string
) => {
    try {
        const {data} = await request(POST, `booking/${merchantPointId}/${categoryType}/import/`, {file});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getEmployeeSchedules = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/employee_schedule/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getReservationsAffectedByEmployeeScheduleUpdate = async (merchantPointId: number, employeeScheduleId: number, scheduleJson: string) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/employee_schedule/${employeeScheduleId}/affected_reservations/?schedule_json=${scheduleJson}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateEmployeeSchedule = async (merchantPointId: number, employeeSchedule: EmployeeSchedule) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/employee_schedule/${employeeSchedule.id}`, {...employeeSchedule});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getEmployeeVacations = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/vacation/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const updateVacation = async (merchantPointId: number, employeeId: number, vacationDays: string[]) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/vacation/${employeeId}`, {new_vacation_days: vacationDays});
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getEmployees = async () => {
    try {
        const {data} = await request(GET, `booking/employees/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getEmployeeCalendar = async (merchantPointId: number, employeeId: number, month: string) => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/calendar/${employeeId}?month_date=${month}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const updateCalendar = async (merchantPointId: number, employeeId: number, newTimeOffs: string[], timeOffsToDelete: number[]) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/calendar/${employeeId}`, {new_time_offs: newTimeOffs, time_offs_to_delete: timeOffsToDelete});
        return data
    } catch (error) {
        throw handleError(error);
    }
};


export const getOrder = async (merchantPointId: number, orderId: number): Promise<OrderDetails> => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/order/${orderId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getReservation = async (merchantPointId: number, reservationId: number): Promise<ReservationDetails> => {
    try {
        const {data} = await request(GET, `booking/${merchantPointId}/reservation/${reservationId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const cancelReservation = async (merchantPointId: number, reservationId: number) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/reservation/${reservationId}/cancel/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

// export const cancelOrder = async (merchantPointId: number, orderId: number) => {
//     try {
//         const {data} = await request(PUT, `booking/${merchantPointId}/order/${orderId}/cancel/`);
//         return data
//     } catch (error) {
//         throw handleError(error);
//     }
// };

export const readyOrder = async (merchantPointId: number, orderId: number) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/order/${orderId}/ready/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const completeOrder = async (merchantPointId: number, orderId: number) => {
    try {
        const {data} = await request(PUT, `booking/${merchantPointId}/order/${orderId}/complete/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};