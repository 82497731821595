import {Order, OrderSort, OrderType, TableHeader} from "../../../services/interfaces";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getOrderTableCount, getOrderTableData, getOrderTableMaxPage} from "../../../redux/selectors/bookingSelector";
import {
    downloadOrderTableHandler,
    getOrderTableHandler,
} from "../../../handlers/bookingHandlers";
import Table from "../../common/Table";
import OrderTableRow from "./OrderTableRow";
import OrderFiltersModal from "./OrderFiltersModal";
import OrderDetailsModal from "./OrderDetailsModal";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";


const ORDER_TABLE_HEADERS: TableHeader<OrderSort>[] = [
    {name: 'ID zamówienia', sort: 'id', digit: false},
    {name: 'ID klienta', sort: 'client_id', digit: false},
    {name: 'Data zamówienia', sort: 'created_at', digit: false},
    {name: 'Status', sort: 'status', digit: false},
    {name: 'Liczba produktów', sort: 'number_of_products', digit: true},
    {name: 'Kwota zakupów (zł)', sort: 'total_price', digit: true}
];

const OrderTable = () => {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [order, setOrder] = useState<OrderType>(null);
    const [filterString, setFilterString] = useState<string>('');
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const data: Order[] = useSelector(getOrderTableData);
    const maxPage: number = useSelector(getOrderTableMaxPage);
    const count: number = useSelector(getOrderTableCount);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    const updateTableCallback = useCallback(() => {
        getOrderTableHandler({
            page,
            query,
            sort,
            order,
            filters: filterString,
            pageSize
        })
    }, [page, query, sort, order, filterString, pageSize]);

    useEffect(() => {
        updateTableCallback()
    }, [updateTableCallback, merchantPoint]);

    useEffect(() => {
        setPage(1)
    }, [maxPage]);

    return (
        <>
            <h3>Zamówienia</h3>
            <div className="block-wrapper">
                <Table page={page}
                       setPage={setPage}
                       pageSize={pageSize}
                       setPageSize={setPageSize}
                       query={query}
                       setQuery={setQuery}
                       sort={sort}
                       setSort={setSort}
                       order={order}
                       setOrder={setOrder}
                       count={count}
                       filters={filterString}
                       setFilters={setFilterString}
                       maxPage={maxPage}
                       headers={ORDER_TABLE_HEADERS}
                       renderRows={() => data.map((order, index) =>
                           <OrderTableRow order={order}
                                          filters={filterString}
                                          key={`OrderRow${index}`}
                                          onClick={() => setSelectedId(order.id)}
                                          updateTableCallback={updateTableCallback}
                           />
                       )}
                       downloadHandler={downloadOrderTableHandler}
                       Modal={OrderFiltersModal}
                       hasActionButton={true}
                />
            </div>
            <OrderDetailsModal orderId={selectedId} onClose={() => setSelectedId(null)} updateTableCallback={updateTableCallback}/>
        </>
    )
};

export default OrderTable;