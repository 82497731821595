import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {getReservationDetails} from "../../../redux/selectors/bookingSelector";
import {getReservationHandler} from "../../../handlers/bookingHandlers";
import {ReservationDetails} from "../../../services/interfaces";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";
import ReservationDetailsSection from "./ReservationDetailsSection";


interface Props {
    reservationId: number | null,
}

const ReservationDetailsView = ({reservationId}: Props) => {
    const reservation: ReservationDetails | null = useSelector(getReservationDetails);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (reservationId !== null)
            getReservationHandler(reservationId);
        setError(false);
    }, [reservationId]);


    if (!reservation || !merchantPoint) {
        return <LoadingScreen/>
    }


    return (
        <div className="reservation-details-view">
            <h3>
                Szczegóły rezerwacji <span className="blue-primary">#{reservation.id}</span>
            </h3>
            <ReservationDetailsSection reservation={reservation} merchantPoint={merchantPoint}
                                       saveCallback={() => getReservationHandler(reservation.id)} error={error}
                                       setError={setError}/>
        </div>
    )
};

export default ReservationDetailsView;