import Modal from "react-modal";
import React from "react";

interface ActionModalProps {
    isOpen: boolean;
    onClose: () => void;
    actions: {
        onClick: () => void;
        title: string;
        className: string;
    }[]
    x: number;
    y: number;
    direction?: 'up' | 'down' | 'right';
}

export interface Action {
    onClick: () => void;
    title: string;
    className: string
}

const ActionModal = ({
                         isOpen,
                         onClose,
                         actions,
                         x,
                         y,
                         direction = 'down',
                     }: ActionModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="actions-modal-wrapper"
            style={{
                content: {
                    top: y,
                    left: x,
                    transform: `translate(${direction === 'right' ? '30px' : '-100%'}, ${direction === 'up' ? '-100%' : '0'})`
                },
            }}
            className="actions-modal-content"
            ariaHideApp={false}
        >
            {actions.map((action, index) => (
                <div className="action" onClick={action.onClick} key={`Action${index}`}>
                    <span className={action.className}>{action.title}</span>
                </div>
            ))}
        </Modal>
    );
};


export default ActionModal