import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Modal from 'react-modal';
import {every, find, isEmpty, isEqual, omit} from "lodash";
import {EmployeeSchedule, EmployeeScheduleDay} from "../../../services/interfaces";
import ActionModal from "../../modals/ActionModal";
import {icons} from "../../../services/images";
import {DayOfWeekMapping} from "../../../services/dictionaries/enums";
import Button from "../../common/Button";
import {
    getEmployeeSchedulesHandler,
    getReservationsAffectedByEmployeeScheduleUpdateHandler,
    updateEmployeeScheduleHandler
} from "../../../handlers/bookingHandlers";
import {useSelector} from "react-redux";
import {getEmployeeSchedules} from "../../../redux/selectors/bookingSelector";
import {formatDateToDuration, formatDurationToDate} from "../../../services/dates";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pl_PL";
import {TimePicker, TimePickerProps} from "antd";
import LoadingScreen from "../../common/LoadingScreen";
import ErrorComponent from "../../common/ErrorComponent";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AffectedReservationsModal from "./AffectedReservationsModal";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateCallback?: () => void;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const formatTimeToUTC = (time: dayjs.Dayjs): string => {
  return time.utc().format('HH:mm');
};

const formatTimeFromUTC = (timeString: string): dayjs.Dayjs => {
  // Convert UTC time string to local time
  const today = dayjs().format('YYYY-MM-DD');
  return dayjs.utc(`${today} ${timeString}`).local();
};


const EmployeeScheduleModal = ({isOpen, setIsOpen, updateCallback}: Props) => {
    const [selectedDay, setSelectedDay] = useState<EmployeeScheduleDay | null>(null);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const dayItemRefs = useRef<(HTMLLIElement | null)[]>([]);
    const [employeeSchedule, setEmployeeSchedule] = useState<EmployeeSchedule | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isAffectedReservationModalOpen, setIsAffectedReservationModalOpen] = useState(false);

    const format = "HH:mm";

    const employeeSchedules: EmployeeSchedule[] = useSelector(getEmployeeSchedules);

    useEffect(() => {
        isOpen && getEmployeeSchedulesHandler()
    }, [isOpen]);

    useEffect(() => {
        if (!isEmpty(employeeSchedules)) {
            setEmployeeSchedule(employeeSchedules[0]);
        }
    }, [employeeSchedules]);

    const employeeScheduleDays = useMemo(() => employeeSchedule?.days, [employeeSchedule]);

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setIsActionModalOpen(false);
        setIsAffectedReservationModalOpen(false);
        setEmployeeSchedule(null);
        setSelectedDay(null);
    };

    const toggleActionModal = (day: EmployeeScheduleDay, index: number) => {
        setSelectedDay(day);
        const dayItem = dayItemRefs.current[index];
        if (dayItem) {
            const {right, bottom} = dayItem.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const setEmployeeScheduleDays = (days: EmployeeScheduleDay[]) => {
        if (employeeSchedule) {
            setEmployeeSchedule({
                ...employeeSchedule,
                days: days,
            })
        }
    };

    const applyToAll = () => {
        if (selectedDay && employeeScheduleDays) {
            const changedDays = employeeScheduleDays.map(days => ({
                    ...days,
                    ...omit(selectedDay, ['id', 'day_of_week'])
                })
            );
            setEmployeeScheduleDays(changedDays)
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const setTime = (changedDay: EmployeeScheduleDay, startTime: dayjs.Dayjs | null, endTime: dayjs.Dayjs | null) => {
        const formattedStartTime = startTime ? formatTimeToUTC(startTime) : undefined;
        const formattedEndTime = endTime ? formatTimeToUTC(endTime) : undefined;

        const changedDays = employeeScheduleDays?.map(day =>
            day.day_of_week === changedDay.day_of_week ?
                {
                    ...day,
                    start_time: formattedStartTime,
                    end_time: formattedEndTime,
                    is_day_off: false
                } :
                day
        );
        changedDays && setEmployeeScheduleDays(changedDays)
    };

    const setSelectedDayAsOff = () => {
        if (selectedDay && employeeScheduleDays) {
            const changedDays = employeeScheduleDays.map(day =>
                day.day_of_week === selectedDay.day_of_week ?
                    {...day, is_day_off: true} :
                    day
            );
            setEmployeeScheduleDays(changedDays)
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const setDayAsAvailable = (changedDay: EmployeeScheduleDay) => {
        const changedDays = employeeScheduleDays?.map(day =>
            day.day_of_week === changedDay.day_of_week ?
                {...day, is_day_off: false} :
                day
        );
        changedDays && setEmployeeScheduleDays(changedDays)
    };

    const affectedReservationsCallback = useCallback(() => {
        setIsLoading(false);
        setIsAffectedReservationModalOpen(true);
    }, [setIsLoading]);

    const saveScheduleCallback = useCallback(() => {
        setIsLoading(false);
        toggleModal();
        updateCallback && updateCallback();
    }, [setIsLoading, updateCallback]);

    const errorCallback = useCallback((error: string) => {
        setError(error);
        setIsLoading(false);
    }, [setIsLoading, setError]);

    const onConfirm = useCallback(() => {
        if (employeeSchedule)
            getReservationsAffectedByEmployeeScheduleUpdateHandler(
                employeeSchedule,
                affectedReservationsCallback,
                saveScheduleCallback,
                errorCallback
            );
    }, [employeeSchedule]);

    const isFilled = useMemo(() => {
        return every(employeeScheduleDays, (day: EmployeeScheduleDay) => (day.start_time && day.end_time) || day.is_day_off);
    }, [employeeScheduleDays]);

    const isChanged = useMemo(() => {
        if (employeeSchedule) {
            const foundEmployeeSchedule = find(employeeSchedules, e => e.id === employeeSchedule.id);
            if (foundEmployeeSchedule)
                return !isEqual(employeeSchedule, foundEmployeeSchedule);
        }
        return false
    }, [employeeSchedule, employeeSchedules]);

    const isDisabled = useMemo(() => {
        return !isChanged || !isFilled;
    }, [isChanged, isFilled]);

    const onChangeDuration: TimePickerProps['onChange'] = (date, dateString) => {
        if (employeeSchedule && date) {
            setEmployeeSchedule({
                ...employeeSchedule,
                slot_duration: formatDateToDuration(new Date(date.toString())),
            })
        }
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content opening-hours-modal employee-schedule-modal"
        >
            <div className="modal-header">
                <h5>Zarządzaj harmonogramem</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {!employeeSchedule || !employeeScheduleDays || isEmpty(employeeSchedules) ?
                <LoadingScreen/> :
                <div className="modal-body">
                    <div className="tab-manager">
                        {employeeSchedules.map(schedule =>
                            <div onClick={() => employeeSchedule.id !== schedule.id && setEmployeeSchedule(schedule)}
                                 className={`tab-pill ${employeeSchedule.id === schedule.id ? 'selected' : ''}`}
                                 key={`Employee-Schedule-${schedule.employee.id}`}
                            >
                                <span>{schedule.employee.name}</span>
                            </div>
                        )}
                    </div>
                    <ul className="modal-list">
                        {
                            employeeScheduleDays.map((day, index) => (
                                <li className="modal-list-item"
                                    key={`Terminal${index}`}
                                    ref={(ref) => (dayItemRefs.current[index] = ref)}
                                >
                                    <span className="label">{DayOfWeekMapping[day.day_of_week]}</span>
                                    {!day.is_day_off ?
                                        <div className="item-row">
                                            <TimePicker.RangePicker
                                                placeholder={['Od', 'Do']}
                                                format={format}
                                                minuteStep={15}
                                                value={[
                                                    day.start_time ? dayjs(formatTimeFromUTC(day.start_time)) : undefined,
                                                    day.end_time ? dayjs(formatTimeFromUTC(day.end_time)) : undefined]}
                                                onChange={(value) => setTime(
                                                    day,
                                                    value ? value[0] : null,
                                                    value ? value[1] : null
                                                )}
                                                needConfirm={true}
                                            />
                                            <div className="actions-button"
                                                 onClick={() => toggleActionModal(day, index)}>
                                                <img src={icons.actionsButtonIcon}
                                                     alt="actionsButtonIcon"
                                                />
                                            </div>
                                        </div> :
                                        <div className="item-row closed">
                                            <p>Dzień wolny</p>
                                            <div className="actions-button"
                                                 onClick={() => setDayAsAvailable(day)}>
                                                <img src={icons.penActiveIcon}
                                                     alt="editButtonIcon"
                                                />
                                            </div>
                                        </div>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                    <div className="select-wrapper">
                        <p className="label">Krok czasowy<span className="required-mark"> *</span></p>
                        <TimePicker
                            value={dayjs(formatDurationToDate(employeeSchedule.slot_duration))}
                            onChange={onChangeDuration}
                            className="date-picker-range-input booking"
                            suffixIcon={<img src={icons.calendarIcon} alt=""/>}
                            locale={locale}
                            format={"HH:mm"}
                            minuteStep={15}
                            showNow={false}
                            allowClear={false}
                        />
                    </div>
                </div>
            }

            <div className="modal-footer">
                <Button label="Potwierdź" onClick={onConfirm} fullWidth disabled={isDisabled}/>
            </div>
            <ActionModal isOpen={isActionModalOpen}
                         onClose={() => setIsActionModalOpen(false)}
                         actions={[
                             {
                                 title: 'Zastosuj do wszystkich',
                                 onClick: applyToAll,
                                 className: 'blue-primary'
                             },
                             {
                                 title: 'Oznacz jako dzień wolny',
                                 onClick: setSelectedDayAsOff,
                                 className: 'delete'
                             },
                         ]}
                         x={actionModalPosition.x} y={actionModalPosition.y}/>
            {employeeSchedule &&
                <AffectedReservationsModal employeeSchedule={employeeSchedule}
                                           isOpen={isAffectedReservationModalOpen}
                                           setIsOpen={setIsAffectedReservationModalOpen}
                                           onSave={saveScheduleCallback}/>
            }
        </Modal>
    );
};

export default EmployeeScheduleModal;

