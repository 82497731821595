import React, {useState} from 'react';

import Step1 from '../modals/stepper/Step1';
import Step2 from '../modals/stepper/Step2';
import Step3 from '../modals/stepper/Step3';
import Step4 from '../modals/stepper/Step4';
import FormStepIndicator from "../modals/stepper/FormStepIndicator";
import {OfferClients, OfferUsage, StepperOfferData, VideoLayout} from "../../services/interfaces";
import {TARGET_GROUP_DEFAULT_STATE} from "../../redux/merchant";
import {DEFAULT_OFFER_LOCATION} from "./MapModal";
import OfferSuccessModal from "./OfferSuccessModal";



export const DEFAULT_STEPPER_OFFER_DATA = {
  step1: {
    clients: "current" as OfferClients,
    target_group: TARGET_GROUP_DEFAULT_STATE,
    offer_localizations: DEFAULT_OFFER_LOCATION,
    estimated_cost: 0,
    number_of_customers: 0,
    extra_customers: 0,
    offer_user_changes: [],
    offer_rate: 0,
  },
  step2: {
    name: "",
    type: undefined,
    extra_cashback: 0,
    offer_merchant_points: [],
    start_date: null,
    end_date: null,
    offer_days: [],
    usage: "one_time" as OfferUsage,
    description: "",
    direct_addressing: false,
    direct_addressing_style: "Hej Adam,...",
    send_push_notification: false,
  },
  step3: {
    image_path: "",
    film_path: "",
    film_title: "",
    film_layout: "horizontal" as VideoLayout,
    film_file: null,
  }
}

const Stepper: React.FC<{ closeModal: () => void }> = ({closeModal}) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<StepperOfferData>(DEFAULT_STEPPER_OFFER_DATA);

  const nextStep = () => setStep(step + 1);


  const prevStep = () => setStep(step - 1);

  const handleChange = (step: keyof StepperOfferData, field: string, value: any) => {
    setFormData(prevData => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [field]: value,
      },
    }));
  };

  const handleChangeSubField = (step: keyof StepperOfferData, field: string, subField: string, value: any) => {
    setFormData(prevData => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [field]: {
          ...((prevData[step] as any)[field] || {}),
          [subField]: value,
        },
      },
    }));
  };


  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1
            formData={formData.step1}
            handleChangeSubField={(field: string, subField: string, value: any) => handleChangeSubField('step1', field, subField, value)}
            handleChange={(field: string, value: any) => handleChange('step1', field, value)}
            nextStep={nextStep}
            prevStep={closeModal}/>;
      case 2:
        return <Step2
            formData={formData.step2}
            handleChange={(field: string, value: any) => handleChange('step2', field, value)}
            nextStep={nextStep}
            prevStep={prevStep}
            numberOfCustomers={formData.step1.number_of_customers}
            estimateCost={formData.step1.estimated_cost}
        />;
      case 3:
        return <Step3
            formData={formData.step3}
            handleChange={(field: string, value: any) => handleChange('step3', field, value)}
            prevStep={prevStep}
            nextStep={nextStep}
            numberOfCustomers={formData.step1.number_of_customers}
            estimateCost={formData.step1.estimated_cost}
        />;
      case 4:
        return <Step4
            formData={formData}
            handleChange={(field: string, value: any) => handleChange('step3', field, value)}
            prevStep={prevStep}
            nextStep={nextStep}
            setStep={(value: number) => setStep(value)}
            numberOfCustomers={formData.step1.number_of_customers}
            estimateCost={formData.step1.estimated_cost}
            />;
      case 5:
        return <OfferSuccessModal
                  onClose={closeModal}
                  formData={formData}
        />
      default:
        return null;
    }
  };

  return (
      <div className="modal-body">
        <FormStepIndicator step={step}/>
        {
          renderStep()
        }
      </div>
  );
};

export default Stepper;