import {
    OfferDay,
    OfferDays,
    RangeBase,
    RangeBaseCreateData,
    StepperOfferData,
    TargetGroup,
    TargetGroupCreateData, UpdateOfferData
} from "./interfaces";
import {mapOfferDaysToString} from "./utils";

export const showOfferDays =(offerDays: {value: string, label: string}[])=> {
        let index = 0
        let convertDays: OfferDay[] = offerDays.map((day:{value: string, label: string})=> {

            if(day.value)
            {
                return {id: index++, days_offer: day.value as OfferDays}
            } else {
                return {id: index++, days_offer: day as unknown as OfferDays}
            }
        }
        )
        return mapOfferDaysToString(convertDays);
    }

export const convertStepperOfferToSendData = (formData: StepperOfferData) => {
    let daysOffer: any;
    if (Array.isArray(formData.step2.offer_days)) {
        daysOffer = formData.step2.offer_days.map((day) => {
            if(day.value) {
                return {days_offer: day.value};
            } else {
                return {days_offer: day};
            }
        });
    }

    let offer_user_changes: any;
    if(Array.isArray(formData.step1.offer_user_changes)){
        offer_user_changes = formData.step1.offer_user_changes.map((customer)=> {
            if(customer.id){
                return {
                    user_id: customer.id,
                    user_status: customer.status
                };
            }
        })
    }

    const tg = { ...formData.step1.target_group, is_offer_duplicate: true}
    const {id, ...targetGroup } = tg

    const prepareTargetGroup = (targetGroup: TargetGroup): TargetGroupCreateData => {
        const removeIdFromRange = (range: RangeBase): RangeBaseCreateData => {
            const {id, ...rest} = range;
            return rest;
        };
        return {
            ...targetGroup,
            age_ranges: targetGroup.age_ranges.map(removeIdFromRange),
            transactions_ranges: targetGroup.transactions_ranges.map(removeIdFromRange),
            amount_ranges: targetGroup.amount_ranges.map(removeIdFromRange),
            amount_sum_ranges: targetGroup.amount_sum_ranges.map(removeIdFromRange),
        };
    };

    const sendData = new FormData();

    const offerData = {
        clients: formData.step1.clients,
        target_group: prepareTargetGroup(targetGroup),
        offer_localizations: [{
          longitude: formData.step1.offer_localizations.coordinates.lng.toString(),
          latitude: formData.step1.offer_localizations.coordinates.lat.toString(),
          offer_range: formData.step1.offer_localizations.offer_range,
          place_name: formData.step1.offer_localizations.place_name
        }],
        estimated_cost: formData.step1.estimated_cost,
        number_of_customers: formData.step1.number_of_customers,
        extra_customers: formData.step1.extra_customers,
        offer_user_changes: offer_user_changes,
        offer_rate: formData.step1.offer_rate,
        name: formData.step2.name,
        type: formData.step2.type,
        extra_cashback: formData.step2.extra_cashback,
        merchant_point_ids: formData.step2.offer_merchant_points,
        start_date: formData.step2.start_date,
        end_date: formData.step2.end_date,
        offer_days: daysOffer,
        usage: formData.step2.usage,
        description: formData.step2.description,
        direct_addressing: formData.step2.direct_addressing,
        direct_addressing_style: formData.step2.direct_addressing_style,
        send_push_notification: formData.step2.send_push_notification,
        image_path: formData.step3.image_path,
        film_path: formData.step3.film_path,
        film_title: formData.step3.film_title,
        film_layout: formData.step3.film_layout,
        film_file: formData.step3.film_file,
    }

    sendData.append('data', JSON.stringify(offerData));
    if (formData.step3.film_file) {
    sendData.append('film_file', formData.step3.film_file);
  }

    return sendData;
};

export const convertUpdateOfferToSendData = (formData: UpdateOfferData) => {


    let offer_user_changes: any;
    if(Array.isArray(formData.offer_user_changes)){
        offer_user_changes = formData.offer_user_changes.map((customer)=> {
            if(customer.id){
                return {
                    user_id: customer.id,
                    user_status: customer.status
                };
            }
        }).filter(Boolean);
    }

    const sendData = new FormData();

    const offerData: UpdateOfferData = {
        ...formData,
        offer_localizations: formData.offer_localizations?.map(loc => ({
            longitude: loc.longitude,
            latitude: loc.latitude,
            offer_range: loc.offer_range,
            place_name: loc.place_name
        })),
        offer_days: formData.offer_days,
        offer_user_changes: offer_user_changes,
        offer_merchant_points: formData.offer_merchant_points
    };


    const filteredOfferData = Object.keys(offerData).reduce((acc, key) => {
    if ((offerData as any)[key] !== undefined) {
        (acc as any)[key] = (offerData as any)[key];
    }
    return acc;
}, {});

    sendData.append('data', JSON.stringify(filteredOfferData));

    if (formData.film_file) {
        sendData.append('film_file', formData.film_file);
    }

    return sendData;
};