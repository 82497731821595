import {Order} from "../../../services/interfaces";
import {OrderStatusEnum, ReservationStatusEnum} from "../../../services/dictionaries/enums";
import {parseQueryString} from "../../../services/utils";
import {icons} from "../../../services/images";
import ActionModal from "../../modals/ActionModal";
import React, {useRef, useState} from "react";
import dayjs from "dayjs";
import ConfirmChangeModal from "../../modals/ConfirmChangeModal";
import {completeOrderHandler, readyOrderHandler} from "../../../handlers/bookingHandlers";
import getNotificationMessage from "../../../services/dictionaries/notification";

interface Props {
    order: Order,
    filters: string,
    onClick: () => void
    updateTableCallback: () => void
}

const OrderTableRow = ({order, filters, onClick, updateTableCallback}: Props) => {
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    // const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
    const [showReadyConfirmation, setShowReadyConfirmation] = useState<boolean>(false);
    const [showCompleteConfirmation, setShowCompleteConfirmation] = useState<boolean>(false);
    const actionRef = useRef<(HTMLTableCellElement | null)>(null);

    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];

    let orderStatus = 'Inna';
    let statusColor = '';
    let hasWarning = false;

    let actions = [
        {
            title: 'Podgląd',
            onClick: () => {
                onClick();
                toggleActionModal();
            },
            className: ''
        }
    ];

    const setAsReady = () => {
        toggleActionModal();
        setShowReadyConfirmation(true);
    };

    const setAsCompleted = () => {
        toggleActionModal();
        setShowCompleteConfirmation(true);
    };

    // const setAsCanceled = () => {
    //     setShowCancelConfirmation(true);
    //     toggleActionModal();
    // };

    switch (order.status) {
        case "canceled":
            statusColor = 'red';
            break;
        case "paid":
            hasWarning = true;
            statusColor = 'blue';
            actions.push({
                title: 'Zaznacz jako gotowe',
                onClick: setAsReady,
                className: 'blue-primary'
            });
            // actions.push({
            //     title: 'Anuluj zamówienie',
            //     onClick: setAsCanceled,
            //     className: 'delete'
            // });
            break;
        case "ready":
            statusColor = 'blue';
            actions.push({
                title: 'Zaznacz jako odebrane',
                onClick: setAsCompleted,
                className: 'blue-primary'
            });
            break;
        case "completed":
            statusColor = 'grey';
            break;
    }
    if (order.status in ReservationStatusEnum) {
        orderStatus = OrderStatusEnum[order.status];
    }

    const formattedDate = dayjs(order.created_at).format('YYYY-MM-DD HH:mm');


    const toggleActionModal = () => {
        const item = actionRef.current;
        if (item) {
            const {right, bottom} = item.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };


    return (
        <tr>
            <td className="clickable-cell">
                <span onClick={onClick}>#{order.id}</span>
            </td>
            {!excludedColumns.includes('client_id') &&
                <td className="client-id-cell">{order.user_id}</td>}
            {!excludedColumns.includes('appointment_date') && <td>{formattedDate}</td>}
            {!excludedColumns.includes('status') &&
                <td className={`cashback-status-cell ${statusColor}`}>
                    {hasWarning && <span className="">!</span>}
                    {orderStatus}
                </td>
            }
            {!excludedColumns.includes('number_of_products') && <td>{order.number_of_products}</td>}
            {!excludedColumns.includes('total_price') && <td>{order.total_price}</td>}
            <td className="actions-button-cell" ref={actionRef}>
                <div className="actions-button" onClick={toggleActionModal}>
                    <img src={icons.actionsButtonIcon}
                         alt="actionsButtonIcon"
                    />
                </div>
                <ActionModal isOpen={isActionModalOpen}
                             onClose={() => setIsActionModalOpen(false)}
                             actions={actions}
                             x={actionModalPosition.x} y={actionModalPosition.y}/>
                {/*<ConfirmChangeModal isOpen={showCancelConfirmation}*/}
                {/*                    setIsOpen={setShowCancelConfirmation}*/}
                {/*                    title="Jesteś pewien?"*/}
                {/*                    onConfirm={() => cancelOrderHandler({*/}
                {/*                        orderId: order.id,*/}
                {/*                        callback: updateTableCallback,*/}
                {/*                    })}*/}
                {/*                    body={getNotificationMessage("cancel_order_info")}*/}
                {/*                    confirmLabel="Tak, Anuluj"*/}

                {/*/>*/}
                <ConfirmChangeModal isOpen={showReadyConfirmation}
                                    setIsOpen={setShowReadyConfirmation}
                                    title="Jesteś pewien?"
                                    type="question"
                                    onConfirm={() => readyOrderHandler({
                                        orderId: order.id,
                                        callback: updateTableCallback,
                                    })}
                                    body={getNotificationMessage("ready_order_info")}
                                    confirmLabel="Tak"
                />
                <ConfirmChangeModal isOpen={showCompleteConfirmation}
                                    setIsOpen={setShowCompleteConfirmation}
                                    title="Jesteś pewien?"
                                    type="question"
                                    onConfirm={() => completeOrderHandler({
                                        orderId: order.id,
                                        callback: updateTableCallback,
                                    })}
                                    body={getNotificationMessage("complete_order_info")}
                                    confirmLabel="Tak"
                />
            </td>
        </tr>
    )
};

export default OrderTableRow;