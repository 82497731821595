import {icons} from "../../../services/images";


const NoReservations = () => {
    return (
        <div className="no-reservations">
            <img src={icons.greyCalendar} alt=""/>
            <p>Brak Aktywnych rezerwacji</p>
        </div>
    )
};
export default NoReservations;