import React from "react";
import {FilterButtonsInterface} from "../../../services/interfaces";

interface Props<T extends string | number | symbol> {
    selected: Record<T, boolean>;
    setSelected: React.Dispatch<React.SetStateAction<Record<T, boolean>>>;
    options: FilterButtonsInterface<T>[];
    title: string;
}

const FiltersButtons = <T extends string>({selected, setSelected, options, title}: Props<T>) => {
    const areAllStatusActive = Object.values(selected).every((value) => value);

    const toggleStatus = (statusKey: T) => {
        setSelected((prevState) => ({
            ...prevState,
            [statusKey]: !prevState[statusKey],
        }));
    };

    const toggleAllStatuses = () => {
        setSelected(() =>
            areAllStatusActive
                ? Object.keys(selected).reduce((acc, key) => ({...acc, [key]: false}), {} as Record<T, boolean>)
                : Object.keys(selected).reduce((acc, key) => ({...acc, [key]: true}), {} as Record<T, boolean>)
        );
    };

    return (
        <div className="section">
            <div className="header">
                <h6>{title}</h6>
                <span onClick={toggleAllStatuses}>
                    {areAllStatusActive ? "Odznacz wszystko" : "Zaznacz wszystko"}
                </span>
            </div>
            <div className="status">
                {options.map((o, index) => (
                    <button
                        onClick={() => toggleStatus(o.value)}
                        className={`${selected[o.value] && "active"}`}
                        key={`Status${index}`}
                    >
                        <img src={o.icon} alt="icon"/>
                        <span style={{color: o.color}}>{o.label}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FiltersButtons;
