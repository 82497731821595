import React from "react";
import {Select, SelectProps} from "antd";
import countries, {Country} from "../../services/dictionaries/countriesDict";


interface Props {
    setCountry: (value: string) => void
}

const CountrySelect = ({setCountry}: Props) => {

    const [selectedCountry, setSelectedCountry] = React.useState<string>('PL');

    const selectOptions: SelectProps['options'] = Object.keys(countries).map(code => {
        const country: Country = countries[code];
        return {
            value: code,
            name: country.name,
            dial_code: country.dial_code,
            code: country.code,
            displayed: <div className="country-select-popup-box">
                <span>{country.flag}</span>
                <span>{country.dial_code}</span>
            </div>,
            label: (
                <div className="country-select-popup-box">
                    <span>{country.flag}</span>
                    <span>{country.dial_code}</span>
                    <span>{country.name}</span>
                </div>
            ),
        };
    });

    return (
        <Select
            showSearch
            style={{width: 100}}
            placeholder="Wybierz kraj"
            optionFilterProp="children"
            optionLabelProp="displayed"
            popupClassName="country-select-popup"
            value={selectedCountry}
            onChange={(value: string) => {
                setSelectedCountry(value);
                setCountry(value);
            }}
            options={selectOptions}
            filterOption={(input, option) => (
                option ? (
                    option.dial_code.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.code.toLowerCase().indexOf(input.toLowerCase()) >= 0
                ) : false
            )}
        />
    )
};

export default CountrySelect;