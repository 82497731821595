import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-modal';
import {find, isEmpty, without} from "lodash";
import {Employee, EmployeeCalendar, EmployeeCalendarDay, TimeSlot} from "../../../services/interfaces";
import {icons} from "../../../services/images";
import {
    getEmployeeCalendarHandler,
    getEmployeesHandler,
    updateCalendarHandler
} from "../../../handlers/bookingHandlers";
import {useSelector} from "react-redux";
import {getEmployees} from "../../../redux/selectors/bookingSelector";
import ErrorComponent from "../../common/ErrorComponent";
import LoadingScreen from "../../common/LoadingScreen";
import CalendarWithSlots from "./CalendarWithSlots";
import dayjs from "dayjs";
import Button from "../../common/Button";
import {useEmployeeCalendarCurrentDay} from "../../../services/helpers";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateCallback?: () => void
}


const EmployeeUnavailabilityModal = ({isOpen, setIsOpen, updateCallback}: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [employee, setEmployee] = useState<Employee | null>(null);
    const [employeeCalendar, setEmployeeCalendar] = useState<EmployeeCalendar | null>(null);
    const [month, setMonth] = useState<dayjs.Dayjs>(dayjs());
    const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(dayjs());
    const [selectedSlots, setSelectedSlots] = useState<[string, TimeSlot][]>([]);
    const employees = useSelector(getEmployees);


    useEffect(() => {
        if (isOpen && !isEmpty(employees)) {
            if (!employee) {
                const firstEmployee = employees[0];
                setEmployee(firstEmployee);
                getEmployeeCalendarHandler(firstEmployee.id, month.format('YYYY-MM-DD'), setEmployeeCalendar)
            } else if (employee && employeeCalendar && (
                employeeCalendar.employee.id !== employee.id ||
                dayjs(employeeCalendar.month_date).format('YYYY-MM') !== month.format('YYYY-MM')
            )){
                getEmployeeCalendarHandler(employee.id, month.format('YYYY-MM-DD'), setEmployeeCalendar)
            }
        }
    }, [employees, month, employee, isOpen, employeeCalendar]);


    useEffect(() => {
        isOpen && getEmployeesHandler()
    }, [isOpen]);

    const toggleModal = useCallback(() => {
        setIsOpen(!isOpen);
        setEmployee(null);
        setError(null);
        setSelectedSlots([]);
        setMonth(dayjs())
    }, [isOpen, setIsOpen]);

    const saveCallback = useCallback(() => {
        setIsLoading(false);
        toggleModal();
        updateCallback && updateCallback();
    }, [toggleModal, setIsLoading, updateCallback]);

    const errorCallback = useCallback((error: string) => {
        setError(error);
        setIsLoading(false);
    }, [setIsLoading, setError]);

    const onSave = useCallback(() => {
        if (employee && selectedSlots) {
            const newTimeOffs: string[] = [];
            const timeOffsToDelete: number[] = [];

            selectedSlots.forEach((slot) => {
                if (slot[1].time_off_id)
                    timeOffsToDelete.push(slot[1].time_off_id);
                else newTimeOffs.push(slot[0])
            });
            updateCalendarHandler({
                employeeId: employee.id,
                newTimeOffs,
                timeOffsToDelete,
                callback: saveCallback,
                errorCallback,
            })
        }
    }, [employee, selectedSlots, saveCallback, errorCallback]);

    const slotClassName = useCallback((slot: [string, TimeSlot]) => {
        let className = '';
        if (selectedSlots.includes(slot)) {
            className = slot[1].status === 'available' ? 'time_off' : 'available';
        } else {
            className = slot[1].status;
        }
        if (slot[1].status === 'reserved') {
            className += ' disabled'
        }
        return className
    }, [selectedSlots]);

    const cellClassName = useCallback((date: dayjs.Dayjs) => {
        let className = '';
        const slot = find(selectedSlots, (slot) => dayjs(slot[0]).local().format("YYYY-MM-DD") === date.format("YYYY-MM-DD"));
        if (slot)
            className = 'cell-edited';
        return className
    }, [selectedSlots]);

    const currentDay: [string, EmployeeCalendarDay] | undefined = useEmployeeCalendarCurrentDay(employeeCalendar, currentDate);

    const selectSlot = (slot: [string, TimeSlot]) => {
        if (slot[1].status !== 'reserved') {
            let newSlots = [...selectedSlots];
            if (selectedSlots.map(s => s[0]).includes(slot[0]))
                newSlots = without(selectedSlots, slot);
            else newSlots.push(slot);
            setSelectedSlots(newSlots);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content employee-schedule-modal"
        >
            <div className="modal-header">
                <h5>Ustal niedospozycyjność</h5>
                <div className="modal-close" onClick={toggleModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            {!employee || isEmpty(employees) || !employeeCalendar ?
                <LoadingScreen/> :
                <div className="modal-body">
                    <div className="tab-manager">
                        {employees.map(e =>
                            <div
                                onClick={() => {
                                    if(e.id !== employee.id) {
                                        setEmployee(e);
                                        setSelectedSlots([]);
                                    }
                                }}
                                className={`tab-pill ${e.id === employee.id ? 'selected' : ''}`}
                                key={`Employee-${e.id}`}
                            >
                                <span>{e.name}</span>
                            </div>
                        )}
                    </div>
                    <CalendarWithSlots
                        employeeCalendar={employeeCalendar} setCurrentDate={setCurrentDate}
                        currentDate={currentDate} setMonth={setMonth}
                        slotClassName={slotClassName}
                        currentDay={currentDay}
                        cellClassName={cellClassName}
                        disabledPreviousPeriod
                        onSlotClick={selectSlot}/>
                    {error && <ErrorComponent error={error}/>}
                </div>
            }
            <div className="modal-footer">
                <Button label="Potwierdź" onClick={onSave} fullWidth disabled={isEmpty(selectedSlots)}
                        loading={isLoading}/>
            </div>
        </Modal>
    );
};

export default EmployeeUnavailabilityModal;

