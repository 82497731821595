import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {cloudFrontUrl, getServiceTypeIcon, icons} from "../../../services/images";
import {useDispatch, useSelector} from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {getOrderDetails} from "../../../redux/selectors/bookingSelector";
import {setOrderDetailsAction} from "../../../redux/booking";
import {
    completeOrderHandler,
    getOrderHandler,
    readyOrderHandler
} from "../../../handlers/bookingHandlers";
import moment from "moment/moment";
import {getMainServiceType} from "../../../admin/services/utils";
import {getServiceTypeTile} from "../../../services/dictionaries/serviceTypesDict";
import {OrderStatusEnum} from "../../../services/dictionaries/enums";
import {formatNumber} from "../../../services/numbers";
import Button from "../../common/Button";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";
import {OrderDetails} from "../../../services/interfaces";
import ConfirmChangeModal from "../../modals/ConfirmChangeModal";
import getNotificationMessage from "../../../services/dictionaries/notification";
import ErrorComponent from "../../common/ErrorComponent";


interface Props {
    orderId: number | null,
    onClose: () => void;
    updateTableCallback: () => void;
}

const OrderDetailsModal = ({onClose, orderId, updateTableCallback}: Props) => {
    // const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false);
    const [showReadyConfirmation, setShowReadyConfirmation] = useState<boolean>(false);
    const [showCompleteConfirmation, setShowCompleteConfirmation] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const dispatch = useDispatch();
    const order: OrderDetails | null = useSelector(getOrderDetails);
    const merchantPoint = useSelector(getCurrentMerchantPoint);

    const closeModal = () => {
        onClose();
        dispatch(setOrderDetailsAction(null));
        setError(false);
    };

    const callback = () => {
        updateTableCallback();
        closeModal();
    };

    const errorCallback = () => {
        setError(true);
    };

    useEffect(() => {
        if (orderId !== null)
            getOrderHandler(orderId)
    }, [orderId]);



    if (!order || !merchantPoint) {
        return (
            <Modal
                isOpen={!!orderId}
                onRequestClose={closeModal}
                overlayClassName="modal-wrapper"
                ariaHideApp={false}
                className="modal-content booking-details-modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        Szczegóły zamówienia
                    </h5>
                    <div className="modal-close" onClick={closeModal}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <LoadingScreen/>
                </div>
            </Modal>
        )
    }

    const formattedDate = moment(order.created_at).locale('pl').format("YYYY-MM-DD hh:mm");
    const mainServiceType = getMainServiceType(merchantPoint);
    const serviceTypeDetails = getServiceTypeTile(mainServiceType?.name || '');
    let iconHeight, iconWidth = 41;
    if (!merchantPoint.logo_image) {
        iconHeight = serviceTypeDetails ? serviceTypeDetails.iconHeight : 41;
        iconWidth = serviceTypeDetails ? serviceTypeDetails.iconWidth : 41;
    }
    return (
        <Modal
            isOpen={!!orderId}
            onRequestClose={closeModal}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content booking-details-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title">
                    Szczegóły zamówienia <span className="blue-primary">#{order ? order.id : ""}</span>
                </h5>
                <div className="modal-close" onClick={closeModal}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <div className="booking-details">
                    <p className="booking-date">{formattedDate}</p>
                    <p className="booking-status">
                        Status: <span className={order.status}>{order.status in OrderStatusEnum ? OrderStatusEnum[order.status] : ''}</span>
                    </p>
                    <p className="booking-note">
                        {order.status === 'completed' && 'Zamówienie zostało pomyślnie odebrane przez klienta.'}
                        {order.status === 'canceled' && 'Zamówienie zostało anulowane.'}
                        {order.status === 'paid' && 'Postaraj się jak najszybciej skompletować zamówienie.'}
                        {order.status === 'ready' && 'Zamówienie zostało skompletowane. Przygotuj się na odbiór zamówienia przez klienta.'}
                    </p>
                    <div className="booking-summary-row">
                        <div>
                            <div className="logo">
                                <img src={merchantPoint.logo_image ?
                                    cloudFrontUrl + merchantPoint.logo_image :
                                    getServiceTypeIcon(mainServiceType?.name || '', true)
                                }
                                     alt=""
                                     height={iconHeight}
                                     width={iconWidth}
                                />
                            </div>
                            <div>
                                <span>{merchantPoint.name}</span>
                                <span>{order.number_of_products} produkty</span>
                            </div>
                        </div>
                        <div/>
                    </div>
                    <div className="order-products">
                        {order.order_products.map(product => (
                            <div>
                                <div className="order-product-row">
                                    <div>
                                        <div className="order-product-quantity">{product.quantity}</div>
                                        <span className="order-product-name">{product.product_name}</span>
                                    </div>
                                    <span
                                        className="order-product-price">{formatNumber(product.price_at_time * product.quantity)}</span>
                                </div>
                                <div className="horizontal-divider"/>
                            </div>
                        ))}
                    </div>
                    <div className="cashback-row">
                        <span>Cashback do opłacenia</span>
                        <div>
                            <img src={icons.cashbackCoinIcon} alt=""/>
                            <span>{formatNumber(1.2)}</span>
                            {/* TODO Calculate Cashback    */}
                        </div>
                    </div>
                    <div className="amount-row">
                        <span>Kwota zakupów</span>
                        <div>
                            <span>{formatNumber(order.total_price)}</span>
                        </div>
                    </div>
                    {error && <ErrorComponent error="unknown_error"/>}
                    {(order.status !== 'completed') &&
                        <div className="buttons">
                            {order.status === 'paid' &&
                                <>
                                    {/*<Button label="Anuluj Zamówienie"*/}
                                    {/*    type="Cancel"*/}
                                    {/*    onClick={() => setShowCancelConfirmation(true)}*/}
                                    {/*/>*/}
                                    <Button label="Zamówienie gotowe"
                                        onClick={() => setShowReadyConfirmation(true)}
                                    />
                                </>
                            }
                            {order.status === 'ready' &&
                                <Button label="Zamówienie odebrane"
                                        onClick={() => setShowCompleteConfirmation(true)}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="modal-footer"/>
            {/*<ConfirmChangeModal isOpen={showCancelConfirmation}*/}
            {/*                    setIsOpen={setShowCancelConfirmation}*/}
            {/*                    title="Jesteś pewien?"*/}
            {/*                    onConfirm={() => cancelOrderHandler({*/}
            {/*                        orderId: order.id,*/}
            {/*                        callback,*/}
            {/*                        errorCallback*/}
            {/*                    })}*/}
            {/*                    body={getNotificationMessage("cancel_order_info")}*/}
            {/*                    confirmLabel="Tak, Anuluj"*/}

            {/*/>*/}
            <ConfirmChangeModal isOpen={showReadyConfirmation}
                                setIsOpen={setShowReadyConfirmation}
                                title="Jesteś pewien?"
                                type="question"
                                onConfirm={() => readyOrderHandler({
                                    orderId: order.id,
                                    callback,
                                    errorCallback
                                })}
                                body={getNotificationMessage("ready_order_info")}
                                confirmLabel="Tak"
            />
            <ConfirmChangeModal isOpen={showCompleteConfirmation}
                                setIsOpen={setShowCompleteConfirmation}
                                title="Jesteś pewien?"
                                type="question"
                                onConfirm={() => completeOrderHandler({
                                    orderId: order.id,
                                    callback,
                                    errorCallback
                                })}
                                body={getNotificationMessage("complete_order_info")}
                                confirmLabel="Tak"
            />
        </Modal>
    );
};

export default OrderDetailsModal;