import {icons} from "../../../services/images";
import {getUserDetailsHandler, logOutHandler} from "../../../handlers/authHandlers";
import React, {useEffect} from "react";
import {SettingsPage} from "../../../services/interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetails} from "../../../redux/selectors/authSelectors";
import {modalCloseAction} from "../../../redux/navigation";

interface Props {
    setSettingsPage: React.Dispatch<React.SetStateAction<SettingsPage | null>>;
    isOpen: boolean
}

const SettingsMenu = ({setSettingsPage, isOpen}: Props) => {

    useEffect(() => {
        isOpen && getUserDetailsHandler();
    }, []);

    const userDetails = useSelector(getUserDetails);

    const dispatch = useDispatch();

    return (
        <ul className="list-bars">
            {userDetails && userDetails.is_superuser &&
                <li onClick={() => setSettingsPage("management")}>
                    <span className="img-wrapper"><img src={icons.userActive} alt=""/></span>
                    <span>Zarządzaj dostępem</span>
                </li>
            }
            <li onClick={() => setSettingsPage("password")}>
                <span className="img-wrapper"><img src={icons.lockActive} alt=""/></span>
                <span>Twoje hasło</span>
            </li>
            <li className="disabled" style={{cursor: 'not-allowed'}}>
                <span className="img-wrapper"><img src={icons.removeActive} alt=""/></span>
                <span>Usuń konto</span>
                <a href="#" className="link-arrow"></a>
            </li>
            <li onClick={() => {
                logOutHandler();
                dispatch(modalCloseAction('settings'));
            }}>
                <span className="img-wrapper"><img src={icons.logoutActive} alt=""/></span>
                <span>Wyloguj się</span>
            </li>
        </ul>
    )

};

export default SettingsMenu;