const notificationDict: { [key: string]: string } = {
    "error": "Ups coś poszło nie tak",
    "saved": "Zmiany zapisano pomyślnie",
    "no_file": "Błąd Importu: Nie wybrano pliku",
    "file_too_large": "Błąd Importu: Wybrany plik przekracza limit 5MB",
    "wrong_file_type": "Błąd Importu: Zły format pliku. Należy wybrać plik Excel o rozszczerzeniu (.xlsx lub .xls)",
    "reservation_canceled": "Rezerwacja została pomyślnie odwołana",
    "order_canceled": "Zamówienie zostało pomyślnie odwołane",
    "order_ready": "Zamówienie zostało pomyślnie oznaczone jako gotowe",
    "order_completed": "Zamówienie zostało pomyślnie oznaczone jako odebrane",
    "cancel_reservation_info": "Anulowania rezerwacji nie da się cofnąć",
    "cancel_order_info": "Anulowania zamówienia nie da się cofnąć",
    "ready_order_info": "Oznaczenie zamówienia jako gotowe, powiadomi użytkownika o możliwości odebrania zamówionych produktów.",
    "complete_order_info": "Oznaczenie zamówienia jako odebrane....",
};

const getNotificationMessage = (key: string): string => {
    if (notificationDict[key]) {
        return notificationDict[key];
    } else {
        return key;
    }
};

export default getNotificationMessage;