import {DatePicker, DatePickerProps} from "antd";
import 'moment/locale/pl'
import locale from 'antd/es/date-picker/locale/pl_PL';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import React from "react";
import {icons} from "../../../services/images";

dayjs.locale('pl');

interface Props {
    disabledStart?: boolean;
    fromDate: Date | null;
    fromToday?: boolean;
    setFromDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setToDate: React.Dispatch<React.SetStateAction<Date | null>>;
    title?: string | null;
    toDate: Date | null;
    withIcon?: boolean;
}

const FiltersDatePicker = ({
                               fromDate,
                               setFromDate,
                               toDate,
                               setToDate,
                               withIcon = false,
                               fromToday = false,
                               disabledStart = false,
                               title = 'Zakres dat'
                           }: Props) => {

    const onChangeFromDate: DatePickerProps['onChange'] = (date, dateString) => {
        setFromDate(date ? new Date(date.toString()) : null);
    };

    const onChangeToDate: DatePickerProps['onChange'] = (date, dateString) => {
        setToDate(date ? new Date(date.toString()) : null);
    };

    return (
        <div className="section datepicker">
            {title && <h6>{title}</h6>}
            <DatePicker
                value={fromDate ? dayjs(fromDate) : null}
                onChange={onChangeFromDate}
                className="date-picker-range-input history"
                suffixIcon={withIcon ? <img src={icons.calendarIcon} alt=""/> : false}
                locale={locale}
                placeholder="Od: "
                minDate={fromToday ? dayjs() : undefined}
                maxDate={toDate ? dayjs(toDate) : undefined}
                format={"DD.MM.YYYY"}
                disabled={disabledStart}

            />
            <DatePicker
                value={toDate ? dayjs(toDate) : null}
                onChange={onChangeToDate}
                className="date-picker-range-input history"
                suffixIcon={withIcon ? <img src={icons.calendarIcon} alt=""/> : false}
                locale={locale}
                placeholder="Do: "
                minDate={fromDate ? dayjs(fromDate) : undefined}
                maxDate={fromToday ? undefined : dayjs()}
                format={"DD.MM.YYYY"}
            />
        </div>
    )
};

export default FiltersDatePicker;