import React from 'react';
import Button from "../../common/Button";
import {showCustomersNumber} from "../../../services/utils";
import {formatNumber} from "../../../services/numbers";
import AddImage from "../../merchant_point/AddImage";
import AddVideo from "./AddVideo";
import {VideoLayout} from "../../../services/interfaces";

interface StepProps {
    formData: {
        image_path: string;
        film_path: string;
        film_title: string;
        film_layout: VideoLayout;
        film_file: File|null;
    };
    handleChange: (field: string, value: any) => void;
    prevStep: () => void;
    nextStep: () => void;
    numberOfCustomers: number;
    estimateCost: number;
}

const Step3: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        prevStep,
                                        nextStep,
                                        numberOfCustomers,
                                        estimateCost
                                    }) => {
    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <div className="section">
                    <p className="label">Zdjęcie promocji:</p>
                    <div className="add-image">
                        <AddImage
                            label=""
                            width={1200}
                            height={800}
                            type="offer-image"
                            image={formData.image_path}
                            setImage={(image) => handleChange('image_path', image)}
                        />
                    </div>
                </div>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Film promocyjny:</p>
                        <div className="input mb16">
                            <input type="text"
                                   className="input-text"
                                   value={formData.film_title}
                                   onChange={(e) => handleChange("film_title", e.target.value)}
                                   placeholder="Podaj nazwę filmu promocyjnego"
                            />
                        </div>
                        <div className="video-wrapper">
                            <AddVideo
                                videoLayout={formData.film_layout}
                                video={formData.film_path}
                                setVideoUrl={(video) => handleChange("film_path", video)}
                                setVideoFile={(file) => handleChange("film_file", file)}
                            />
                        </div>
                    </div>
                </div>
                <div className="section">
                    <p className="label label-margin">Układ filmu:</p>
                    <div className="radio-wrapper">
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="layout"
                                checked={formData.film_layout === "horizontal"}
                                value="horizontal"
                                onChange={(e)=>handleChange("film_layout",e.target.value)}
                            />
                            <p className="label">poziomy</p>
                        </label>
                        <label className="stepper-radio-inputs">
                            <input
                                type="radio"
                                name="layout"
                                checked={formData.film_layout === "vertical"}
                                value="vertical"
                                onChange={(e)=>handleChange("film_layout",e.target.value)}
                            />
                            <p className="label">pionowy</p>
                        </label>
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper with-border">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span
                            className="label w700">{showCustomersNumber(numberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper">
                        <span className="label">Łączny koszt promocji: </span>
                        <span className="label w700">{formatNumber(estimateCost)}</span>
                    </div>
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                </div>
                <div className="modal-footer">
                    <Button label="Wstecz"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Dalej"
                            onClick={nextStep}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step3;