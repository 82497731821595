import {DatePicker} from "antd";
import {Moment} from "moment/moment";
import {useCallback, useMemo, useState} from "react";
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import moment from "moment";
import {icons} from "../../services/images";
import {DatePreset, DateRange} from "../../services/interfaces";
import 'moment/locale/pl'
import locale from 'antd/es/date-picker/locale/pl_PL';
import dayjs from 'dayjs';
import {useSelector} from "react-redux";
import {getFirstTransactionDate} from "../../redux/selectors/merchantSelector";
import {getAdminFirstTransactionDate} from "../../admin/redux/selectors/adminViewSettingsSelectors";
import {DEFAULT_PRESETS, getPresetRange} from "../../services/dates";

dayjs.locale('pl');

interface Props {
    dateRange: DateRange
    setDateRange: (dateRange: DateRange) => void;
    datePreset: DatePreset | null;
    setDatePreset: (datePreset: DatePreset | null) => void;
    admin?: boolean
}

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);


const DateRangePicker = ({dateRange, setDateRange, datePreset, setDatePreset, admin = false}: Props) => {
    const {RangePicker} = MyDatePicker;
    const [open, setOpen] = useState(false);
    const firstTransactionDate: string | null = useSelector(admin ? getAdminFirstTransactionDate : getFirstTransactionDate);
    const presets: DatePreset[] = useMemo(() => {
        if (!firstTransactionDate) return DEFAULT_PRESETS;
        return [...DEFAULT_PRESETS, {
            datePickerLabel: "Cały zakres",
            label: "Cały Zakres",
            type: 'fullRange'
        }];
    }, [firstTransactionDate]);


    const handlePresetClick = useCallback((preset: DatePreset) => {
        const [start, end] = getPresetRange(preset.type, firstTransactionDate);
        setDateRange({
            startDate: start.format("YYYY-MM-DD"),
            endDate: end.format("YYYY-MM-DD")
        });
        setDatePreset(preset);
        setOpen(false);
    }, [setDateRange, firstTransactionDate]);

    const handleDateChange = useCallback((dates: Moment[] | null | any) => {
        if (dates?.[0] && dates?.[1]) {
            setDateRange({
                startDate: dates[0].format("YYYY-MM-DD"),
                endDate: dates[1].format("YYYY-MM-DD")
            });
            setDatePreset(null);
        }
    }, [setDateRange]);


    const renderExtraFooter = useCallback(() => (
        <div className="ant-picker-presets">
            <ul>
                {presets.map((preset, index) => (
                    <li
                        key={`preset-${index}`}
                        onClick={() => handlePresetClick(preset)}
                    >
                        {preset.datePickerLabel}
                    </li>
                ))}
            </ul>
        </div>
    ), [presets, handlePresetClick]);


    return (
        <div>
            <div className="date-picker">
                <img src={icons.blueCalendarIcon} alt=""/>
                <span className="period">{datePreset ? datePreset.label : "Zakres"}</span>
                <span className="bracket left">(</span>
                <span className="bracket right">)</span>
                <span className={`chevron ${open ? "up" : "down"}`}/>
            </div>
            <RangePicker
                popupClassName="date-picker-range-popup"
                className="date-picker-range-input"
                allowClear={false}
                open={open}
                onOpenChange={setOpen}
                variant="borderless"
                separator="-"
                locale={locale}
                suffixIcon={<></>}
                format="DD.MM.YYYY"
                value={[moment(dateRange.startDate), moment(dateRange.endDate)]}
                onChange={handleDateChange}
                renderExtraFooter={renderExtraFooter}
                defaultValue={[moment(dateRange.startDate), moment(dateRange.endDate)]}
                maxDate={moment()}
            />
        </div>
    )
};

export default DateRangePicker;