import NotificationPopup from "../../common/NotificationPopup";
import {icons} from "../../../services/images";
import {setBookingEditModeAction} from "../../../redux/booking";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TabManager from "./TabManager";
import ControlsRow from "./ControlsRow";
import CategoryComponent from "./CategoryComponent";
import ActionModal, {Action} from "../../modals/ActionModal";
import CategoryModal from "./CategoryModal";
import {Category, CategoryFilters, CategoryType, Product, Service} from "../../../services/interfaces";
import {deleteCategoryHandler, getCategoriesHandler, moveCategoryHandler} from "../../../handlers/bookingHandlers";
import {getCategoriesByType,} from "../../../redux/selectors/bookingSelector";
import {isEmpty} from "lodash";
import {getCurrentMerchantPoint} from "../../../redux/selectors/merchantSelector";
import CategoryItemModal from "./CategoryItemModal";
import ConfirmChangeModal from "../../modals/ConfirmChangeModal";
import {useFilteredCategories, useMappedCategories} from "../../../services/helpers";
import ImportNotificationPopup from "./ImportNotificationPopup";

export const CATEGORY_FILTERS_DEFAULT_STATE = {
    query: '',
    hiddenCategories: [],
    priceSlider: null,
};

const BookingEdit = () => {

    const dispatch = useDispatch();
    const [categoryType, setCategoryType] = useState<CategoryType>('product');
    const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);
    const [actionModalPosition, setActionModalPosition] = useState({x: 0, y: 0});
    const [filters, setFilters] = useState<CategoryFilters>(CATEGORY_FILTERS_DEFAULT_STATE);
    const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
    const [isItemModalOpen, setItemModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [selectedItem, setSelectedItem] = useState<Product | Service | null>(null);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const categoryActionRefs = useRef<(HTMLDivElement | null)[]>([]);
    const previousPointIdRef = useRef<number>();

    const categories = useSelector(getCategoriesByType(categoryType));
    const merchantPoint = useSelector(getCurrentMerchantPoint);


    useEffect(() => {
        const newId: number | undefined = merchantPoint.id;
        const oldId: number | undefined = previousPointIdRef.current;
        if (newId !== oldId || isEmpty(categories))
            getCategoriesHandler(categoryType, () => {
                previousPointIdRef.current = merchantPoint.id
            });
    }, [categories, categoryType, merchantPoint]);

    const toggleActionModal = (category: Category, index: number) => {
        setSelectedCategory(category);
        const categoryAction = categoryActionRefs.current[index];
        if (categoryAction) {
            const {right, bottom} = categoryAction.getBoundingClientRect();
            setActionModalPosition({
                x: right,
                y: bottom + 5,
            });
        }
        setIsActionModalOpen(!isActionModalOpen);
    };

    const deleteCategoryAction = () => {
        setShowDeleteConfirmation(true);
        setIsActionModalOpen(false);
    };

    const onCategoryModalClose = () => {
        setCategoryModalOpen(false);
        setSelectedCategory(null);
    };

    const onItemModalClose = () => {
        setItemModalOpen(false);
        setSelectedCategory(null);
        setSelectedItem(null);
    };

    const editCategoryAction = () => {
        setCategoryModalOpen(true);
        setIsActionModalOpen(false);
    };

    const moveCategoryAction = (category: Category, direction: 'up' | 'down', categoryType: CategoryType) => {
        moveCategoryHandler(categoryType, category.id, direction, () => getCategoriesHandler(categoryType));
        setIsActionModalOpen(false);
    };

    const addCategory = () => {
        setSelectedCategory(null);
        setCategoryModalOpen(true);
    };

    const addItem = () => {
        setSelectedCategory(null);
        setSelectedItem(null);
        setItemModalOpen(true);
    };

    const mappedCategories: Category[] = useMappedCategories(categories);


    const filteredCategories = useFilteredCategories(mappedCategories, filters);

    const actions = useMemo(() => {
        let tempActions: Action[] = [];
        if (!selectedCategory) return tempActions;
        if (selectedCategory.sort_order > 0) {
            tempActions.push({
                title: 'Przenieś w górę',
                onClick: () => moveCategoryAction(selectedCategory, 'up', categoryType),
                className: ''
            })
        }
        const lastCategory = categories[categories.length - 1];
        if (lastCategory && selectedCategory.sort_order < lastCategory.sort_order)
            tempActions.push({
                title: 'Przenieś w dół',
                onClick: () => moveCategoryAction(selectedCategory, 'down', categoryType),
                className: ''
            });

        if (selectedCategory.name !== 'Uncategorized') {
            tempActions.push({
                title: 'Zmień nazwę',
                onClick: editCategoryAction,
                className: ''
            });
            tempActions.push({
                title: 'Usuń',
                onClick: deleteCategoryAction,
                className: 'delete'
            });
        }

        return tempActions;

    }, [selectedCategory, categories, categoryType]);

    const showItemDetails = (item: Service | Product) => {
        setSelectedItem(item);
        setItemModalOpen(true);
    };

    const addNewItemToCategory = (category: Category) => {
        setSelectedCategory(category);
        setItemModalOpen(true);
    };


    return (
        <div className="dashboard-content booking-edit">
            <NotificationPopup/>
            <ImportNotificationPopup/>
            <div className="header-wrapper">
                <div className="header-section">
                    <div className="go-back"
                         onClick={() => dispatch(setBookingEditModeAction(false))}>
                        <img src={icons.backIcon} alt=""/>
                    </div>
                    <h2>Zarządzaj ofertą</h2>
                </div>
            </div>
            <TabManager tab={categoryType} setTab={setCategoryType} setFilters={setFilters}/>
            <ControlsRow filters={filters} setFilters={setFilters} categoryType={categoryType} addCategory={addCategory}
                         addItem={addItem} categories={categories}/>
            <div className="categories">
                {filteredCategories.map((category, index) => (
                    <CategoryComponent
                        toggleActionModal={() => toggleActionModal(category, category.id)}
                        categoryActionRefs={categoryActionRefs}
                        category={category}
                        onItemClick={showItemDetails}
                        onAddClick={() => addNewItemToCategory(category)}
                        key={`Category-${index}`}
                    />
                ))}
            </div>
            <ActionModal isOpen={isActionModalOpen}
                         onClose={() => setIsActionModalOpen(false)}
                         actions={actions}
                         x={actionModalPosition.x} y={actionModalPosition.y}/>
            <CategoryModal isOpen={isCategoryModalOpen}
                           onClose={onCategoryModalClose}
                           category={selectedCategory}
                           categoryType={categoryType}
            />
            <CategoryItemModal
                isOpen={isItemModalOpen}
                onClose={onItemModalClose}
                item={selectedItem}
                categories={categories}
                categoryType={categoryType}
                selectedCategoryId={selectedCategory?.id}
            />
            <ConfirmChangeModal isOpen={showDeleteConfirmation}
                                setIsOpen={setShowDeleteConfirmation}
                                title="Jesteś pewien?"
                                onConfirm={() => selectedCategory && deleteCategoryHandler(categoryType, selectedCategory.id, () => getCategoriesHandler(categoryType))}
                                body={`Usuniętych kategorii nie da się w żaden sposób odzyskać. Wszystkie ${categoryType === 'product' ? 'produkty' : 'usługi'} w obrębie usuniętej kategorii trafią do “nieprzypisane”.`}
                                confirmLabel="Tak, usuń"
            />
        </div>
    );
};

export default BookingEdit;