import Modal from "react-modal";

import Button from "../common/Button";
import React from "react";
import {StepperOfferData} from "../../services/interfaces";

interface OfferSuccessModalProps {
    onClose: ()=>void;
    formData: StepperOfferData;
}

const OfferSuccessModal = ({formData, onClose}: OfferSuccessModalProps) => {
    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="offer-success-screen"
            ariaHideApp={false}
        >
            <div className="success-info">
                <div className="badge badge-success"/>
                <h3>Pomyślnie utworzono promocję</h3>
                <p>Twoja płatność przebiegła pomyślnie.
                    Ciesz się większym zainteresowaniem klientów dzięki nowo utworzonej promocji!
                </p>
            </div>
            <div className="button-wrapper">
                <Button
                    label="Kontynuuj"
                    onClick={onClose}
                />
                <Button
                    label="Udostępnij post na Facebook"
                    onClick={onClose}
                    type="Default"
                />
                <Button
                    label="Udostępnij post na Instagram"
                    onClick={onClose}
                    type="Default"
                />
                <Button
                    label="Udostępnij post na TikTok"
                    onClick={onClose}
                    type="Default"
                />
            </div>
        </Modal>
    );
};

export default OfferSuccessModal;