import React from "react";

interface OfferRadioProps {
    selectedOption: string,
    handleOptionChange: (event: React.ChangeEvent<HTMLInputElement>)=>void,

}

const OfferRadioSelect = ({selectedOption, handleOptionChange}: OfferRadioProps) => {
    return (
        <div className="section">
            <p className="label label-margin">Utwórz promocję dla:</p>
            <div className="stepper-radio-wrapper">
                <label className="stepper-radio-inputs">
                    <input
                        type="radio"
                        name="klienci"
                        checked={selectedOption === "current"}
                        value="current"
                        onChange={handleOptionChange}
                    />
                    <p className="label">obecnych klientów</p>
                </label>
                <label className="stepper-radio-inputs">
                    <input
                        type="radio"
                        name="klienci"
                        checked={selectedOption === "new"}
                        value="new"
                        onChange={handleOptionChange}
                    />
                    <p className="label">nowych klientów</p>
                </label>
            </div>
            <span className="stepper-span blue">
                Utworzenie promocji dla nowych klientów wiąże się z opłatą zależną od jej zasięgu.
            </span>
        </div>
    );
};

export default OfferRadioSelect;