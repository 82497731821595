import {
    AccountingDocumentAdminResponse,
    AccountingFilterAdminOptions,
    OrderType
} from "../../services/interfaces";
import {
    downloadAccountingDocumentAdminPDF,
    getAccountingAdminFilters,
    getAccountingDocumentsAdmin, getAccountingDocumentsXLSXAdmin
} from "../http/adminAccounting";
import React from "react";
import {prepareB64FileToPDF, prepareB64FileToXLSX} from "../../services/utils";
import {saveAs} from "file-saver";


export const getAccountingDocumentsAdminHandler = (
    setAccountingDocuments: React.Dispatch<React.SetStateAction<AccountingDocumentAdminResponse>>,
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: OrderType,
    filterString: string,
    query: string,
) => {
    getAccountingDocumentsAdmin(page, pageSize, sortBy, sortOrder, filterString, query).then(response => {
        setAccountingDocuments(response);
    }).catch(error => {
        console.error(error);
    })
}

export const getAccountingFiltersAdminHandler = (
    setFilters: React.Dispatch<React.SetStateAction<AccountingFilterAdminOptions>>,
) => {
    getAccountingAdminFilters().then((response) => {
        setFilters({
            ...response
        })
    }).catch(error => {
        console.log(error)
    })
}

export const downloadAccountingDocumentAdminPDFHandler = (documentID: number) => {
    downloadAccountingDocumentAdminPDF(documentID).then((response) => {
        const blob = prepareB64FileToPDF(response.content);
        const filename = response.filename;
        saveAs(blob, filename)
    }).catch(error => {
        console.log(error)
    })
}

export const getAccountingDocumentsXLSXAdminHandler = (
    sortBy: string,
    sortOrder: OrderType,
    query: string,
    filterString: string,
) => {
    getAccountingDocumentsXLSXAdmin(
        sortBy,
        sortOrder,
        query,
        filterString
    ).then((response) => {
        const blob = prepareB64FileToXLSX(response.content);
        const filename = response.filename;
        saveAs(blob, filename)
    }).catch(error => {
        console.log(error)
    })
}

