import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import {icons} from "../../../services/images";
import FiltersDropdown from "./FiltersDropdown";
import FiltersDatePicker from "./FiltersDatePicker";
import {
    FilterButtonsInterface,
    FilterModalProps, FiltersTransactionsStatus, FiltersTransactionStatusState,
    HistoryFilterOptions,
    MultiSelectOption,
} from "../../../services/interfaces";
import FiltersSlider from "./FiltersSlider";
import Button from "../../common/Button";
import {getHistoryFilterOptionsHandler} from "../../../handlers/transactionHandlers";
import {useSelector} from "react-redux";
import {
    getCurrentMerchantPoint,
    getHistoryTransactionsFilterOptions,
    getIsMerchantSelected,
    getMerchant
} from "../../../redux/selectors/merchantSelector";
import {getFiltersClientOptions, getFiltersPointsOptions} from "../../../services/helpers";
import {toLocalISOString} from "../../../services/dates";
import FiltersButtons from "./FiltersButtons";

export const DEFAULT_TRANSACTION_STATUS_STATE: FiltersTransactionStatusState = {
    receivables: false,
    due: false,
    wallet: false,
    queue: false,
    withdrawn: false,
    rejected: false,
};

export const cashbackStatuses: FilterButtonsInterface<FiltersTransactionsStatus>[] = [
    {value: 'receivables', icon: icons.coinBluePrimaryIcon, color: '#213DFC', label: 'W toku'},
    {value: 'due', icon: icons.coinBluePrimaryIcon, color: '#213DFC', label: 'Do opłacenia'},
    {value: 'wallet', icon: icons.coinGreenIcon, color: '#00F6A1', label: 'Opłacone'},
    {value: 'queue', icon: icons.coinGrayIcon, color: '#989898', label: 'W kolejce'},
    {value: 'withdrawn', icon: icons.coinGrayIcon, color: '#989898', label: 'Wypłacone'},
    {value: 'rejected', icon: icons.coinRedIcon, color: '#D13735', label: 'Odrzucone'},
];

const TransactionHistoryFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const filterOptions: HistoryFilterOptions | null = useSelector(getHistoryTransactionsFilterOptions);

    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);


    useEffect(() => {
        getHistoryFilterOptionsHandler()
    }, [isMerchantSelected, merchant, merchantPoint]);


    const [clients, setClients] = useState<string[]>([]);
    const [columns, setColumns] = useState<string[]>([]);
    const [points, setPoints] = useState<string[]>([]);

    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);

    const [cashbackStatus, setCashbackStatus] = useState<FiltersTransactionStatusState>(DEFAULT_TRANSACTION_STATUS_STATE);

    const [amountSlider, setAmountSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_amount, filterOptions.max_amount] : null);
    const [cashbackSlider, setCashbackSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_cashback, filterOptions.max_cashback] : null);
    const [feeSlider, setFeeSlider] = useState<[number, number] | null>(
        filterOptions ? [filterOptions.min_total_fees, filterOptions?.max_total_fees] : null);

    if (filterOptions && (amountSlider === null || cashbackSlider === null || feeSlider === null)) {
        setAmountSlider([filterOptions.min_amount, filterOptions.max_amount]);
        setCashbackSlider([filterOptions.min_cashback, filterOptions.max_cashback]);
        setFeeSlider([filterOptions.min_total_fees, filterOptions.max_total_fees]);
    }

    const columnsOptions: MultiSelectOption[] = [
        {value: 'ID klienta', children: 'client_id'},
        {value: 'Data', children: 'trade_date'},
        {value: 'Adres punktu', children: 'name_address_value_1'},
        {value: 'Status Cashbacku', children: 'status'},
        {value: 'Kwota transakcji', children: 'amount'},
        {value: 'Cashback', children: 'cashback_accrued'},
        {value: 'Opłaty', children: 'total_fees'},
        {value: 'Suma opłat', children: 'total'},
        {value: 'Płeć', children: 'gender'},
        {value: 'Wiek', children: 'age'},
    ];

    const clientOptions = getFiltersClientOptions(filterOptions);

    const pointsOptions: MultiSelectOption[] = getFiltersPointsOptions(filterOptions);

    const clearFilters = () => {
        setClients([]);
        setColumns([]);
        setPoints([]);
        setFromDate(null);
        setToDate(null);
        setCashbackStatus(DEFAULT_TRANSACTION_STATUS_STATE);
        setAmountSlider([filterOptions?.min_amount || 0, filterOptions?.max_amount || 100]);
        setCashbackSlider([filterOptions?.min_cashback || 0, filterOptions?.max_cashback || 100]);
        setFeeSlider([filterOptions?.min_total_fees || 0, filterOptions?.max_total_fees || 100]);
    };

    const applyFilters = () => {

        if (!amountSlider || !cashbackSlider || !feeSlider) {
            return;
        }

        const statusString = Object.keys(cashbackStatus)
            .filter(key => cashbackStatus[key as keyof FiltersTransactionStatusState])
            .join(',');
        const urlFilters = '' +
            'clients=' + clients.join(',') +
            '&columns=' + columns.join(',') +
            '&points=' + points.join(',') +
            '&fromDate=' + (fromDate ? toLocalISOString(fromDate) : '') +
            '&toDate=' + (toDate ? toLocalISOString(toDate) : '') +
            '&status=' + statusString +
            '&amount=' + amountSlider.join(',') +
            '&cashback=' + cashbackSlider.join(',') +
            '&total_fees=' + feeSlider.join(',');
        setFilters(urlFilters);
        setOpen(false);
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersDropdown
                    title="Wybierz klienta"
                    placeholder="Wybierz klienta po user ID..."
                    options={clientOptions}
                    values={clients}
                    setValues={setClients}
                />
                <FiltersDropdown
                    title="Ukryj kolumny"
                    placeholder="Zaznacz kolumny które chcesz ukryć..."
                    options={columnsOptions}
                    values={columns}
                    setValues={setColumns}
                    setChildren={true}
                />
                {filterOptions && Object.keys(filterOptions.merchant_points).length > 0 &&
                    <FiltersDropdown
                        title="Wybierz punkty"
                        placeholder="Zaznacz punkty które chcesz wyświetlać..."
                        options={pointsOptions}
                        values={points}
                        setValues={setPoints}
                        setChildren={true}
                    />
                }
                <FiltersDatePicker
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                />
                <FiltersButtons<FiltersTransactionsStatus>
                    selected={cashbackStatus}
                    setSelected={setCashbackStatus}
                    options={cashbackStatuses}
                    title="Status cashbacku"
                />
                <FiltersSlider
                    label="Kwota transakcji"
                    min={filterOptions?.min_amount || 0}
                    max={filterOptions?.max_amount || 100}
                    value={amountSlider}
                    onChange={setAmountSlider}
                />
                <FiltersSlider
                    label="Kwota cashbacku"
                    min={filterOptions?.min_cashback || 0}
                    max={filterOptions?.max_cashback || 100}
                    value={cashbackSlider}
                    onChange={setCashbackSlider}
                />
                <FiltersSlider
                    label="Opłaty"
                    min={filterOptions?.min_total_fees || 0}
                    max={filterOptions?.max_total_fees || 100}
                    value={feeSlider}
                    onChange={setFeeSlider}
                />
            </div>
            <div className="modal-footer">
                {filterOptions && <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default TransactionHistoryFiltersModal;