import {useSelector} from "react-redux";
import {getBookingEditMode} from "../redux/selectors/bookingSelector";
import BookingEdit from "../components/booking/edit/BookingEdit";
import BookingDashboard from "../components/booking/dashboard/BookingDashboard";
import {getCurrentMerchantPoint, getIsMerchantSelected} from "../redux/selectors/merchantSelector";
import {getUserDetails} from "../redux/selectors/authSelectors";
import {CUPS_PAGE, HOME_PAGE} from "../services/dictionaries/paths";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {MerchantUserDetails} from "../services/interfaces";


const BookingContainer = () => {
    const editMode = useSelector(getBookingEditMode);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const userDetails: MerchantUserDetails | null = useSelector(getUserDetails);
    const navigate = useNavigate();

    useEffect(() => {
    if (!merchantPoint.id || isMerchantSelected)
        if (userDetails)
            navigate(userDetails.is_superuser ? HOME_PAGE: CUPS_PAGE);
    }, [isMerchantSelected, merchantPoint, navigate]);
    return (
        editMode ?
            <BookingEdit/> :
            <BookingDashboard/>
    )
};

export default BookingContainer;