import {Customer} from "../../services/interfaces";
import {icons} from "../../services/images";

interface CheckboxProps {
    user: Customer,
    onChange: (user: Customer) => void;
}

const Checkbox = ({user, onChange}: CheckboxProps) => {
    const handleChange = () => {
        onChange({...user, status: !user.status});
    };

    return (
        <div className="checkbox">
            <input
                type="checkbox"
                id={`checkbox-${user.id}`}
                checked={user.status}
                onChange={handleChange}
            />
            <label htmlFor={`checkbox-${user.id}`}>
                <img className="user-icon" src={icons.userProfileIcon} alt={"user icon"}/>
                <p className="w700">#{user.id}</p>
                <p>{user.email}</p>
                {user.name && <p>({user.name})</p>}
            </label>
        </div>
    )
}

export default Checkbox;