import React from "react";

export type ButtonType = 'Primary' | 'Default' | 'Red' | 'Cancel'

interface Props {
    label: string
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    type?: ButtonType
    disabled?: boolean
    loading?: boolean
    fullWidth?: boolean
    icon?: string
    dataTooltipId?: string
    extraStyle?: React.CSSProperties
    badge?: number
    badgeColor?: 'red' | 'green' | null
}

const Button = ({
                    label,
                    onClick,
                    type = "Primary",
                    disabled = false,
                    loading = false,
                    fullWidth = false,
                    icon,
                    dataTooltipId,
                    extraStyle,
                    badge,
                    badgeColor = null
                }: Props) => {

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClick(event);
    };

    let colorClass;
    switch (type) {
        case "Primary":
            colorClass = "button-primary";
            break;
        case "Red":
            colorClass = "button-red";
            break;
        case "Default":
            colorClass = "button-default";
            break;
        case "Cancel":
            colorClass = "button-cancel";
            break;

    }

    return (
        <button
            className={`button ${colorClass} ${fullWidth ? 'full-width' : ''}`}
            onClick={handleClick}
            disabled={disabled}
            data-tooltip-id={dataTooltipId}
            data-tooltip-place="top"
            style={extraStyle}
        >
            <img src={icon} alt=""/>
            <span>{label}</span>
            {loading && <span className='button-loading'/>}
            {badge && badge > 0 ?
                <span className={`badge-attention small ${badgeColor ?? ''}`}>{badge}</span> : ''
            }
        </button>
    )
};

export default Button;


