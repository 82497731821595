import CreateOfferButton from "../common/CreateOfferButton";
import DateRangePicker from "../common/DateRangePicker";
import React, {useEffect, useRef, useState} from "react";
import {ChartInterval, DateRange, Gender, RangeBase, DatePreset} from "../../services/interfaces";
import {
    DEFAULT_OFFER_DATA,
    setBarChartSelectedAction,
    setCurrentOfferAction,
    setDatePresetAction,
    setDateRangeAction
} from "../../redux/merchant";
import {useDispatch, useSelector} from "react-redux";
import {
    getBarChartSelected,
    getCurrentMerchantPoint,
    getCurrentOffer,
    getDatePreset,
    getDateRange,
    getIsMerchantSelected,
    getMerchant, getOfferDashboard,
    getOffers, getPreviousPeriod
} from "../../redux/selectors/merchantSelector";
import StatisticsTopTile from "../statistics/StatisticsTopTile";
import NoChart from "../common/chart/NoChart";
import {getTargetGroupsHandler} from "../../handlers/targetGroupHandlers";
import ImgGeneratorModal from "../modals/ImgGeneratorModal";
import {DOUBLE_DECIMAL_NUMBER_FORMAT, formatNumber, WHOLE_NUMBER_FORMAT} from "../../services/numbers";
import {isEmpty, isEqual} from "lodash";
import dayjs from "dayjs";
import {mapOfferDaysToString, showCustomersNumber} from "../../services/utils";
import {OfferTypeMapping, OfferUsageMapping} from "../../services/dictionaries/enums";
import {cloudFrontUrl, icons, images} from "../../services/images";
import {Select} from "antd";
import {store} from "../../redux/store";
import Button from "../common/Button";
import {getOfferDashboardDataHandler, getOffersHandler} from "../../handlers/offerHandlers";
import DateIntervalBarChart from "../common/chart/DateIntervalBarChart";
import StatisticsAggregateChartTile from "../statistics/StatisticsAggregateChartTile";
import EndOfferModal from "../modals/EndOfferModal";
import ExtendOfferModal from "../modals/ExtendOfferModal";

const rangesHandler = (ranges: RangeBase[]) => {
  return ranges.map(element => {
    if (element.selected) {
      return (
        <div key={element.id}>
            <p className="label w700">{element.min}-{element.max === null ? 'więcej' : element.max}</p>
        </div>
      );
    }
    return null;
  });
};


const getHowDaysLeft = (start: Date, end: Date) => {
    const endDate = new Date(end);
    const startDate = new Date(start);
    const actualDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    actualDate.setHours(0, 0, 0, 0);

    const milisecondsEndActualDifference = endDate.getTime() - actualDate.getTime();
    const dayDifference = Math.round(milisecondsEndActualDifference / (1000 * 60 * 60 * 24))
    if(actualDate.getTime() < startDate.getTime()){return 'Nadchodzi';}


    if (isNaN(dayDifference) || dayDifference < 0){
        return 'Zakończono';
    }
    if (dayDifference === 0){
        return 'do Dziś';
    }
    if (dayDifference === 1){
        return 'do Jutra';
    }
    return `${dayDifference} dni`;
}

const genderHandler = (gender: Gender[]) => {
   return gender.map((element, index) => {
    if (element === "male") {
      return (

            <p key={index} className="label w700">Mężczyzna</p>
      );
    }
    if (element === "female") {
      return (
            <p key={index} className="label w700">Kobieta</p>
      );
    }
    if (element === "unknown") {
      return (
            <p key={index} className="label w700">Nie chcę podać</p>
      );
    }
    return null;
  });
}

const Offers = () => {
    const [chartInterval, setChartInterval] = useState<ChartInterval>('DAY');
    const dateRange = useSelector(getDateRange);
    const datePreset: DatePreset | null = useSelector(getDatePreset);
    const previousPeriod = useSelector(getPreviousPeriod);
    const merchant = useSelector(getMerchant);
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const currentOffer = useSelector(getCurrentOffer);
    const currentOfferRef = useRef(currentOffer);
    const offerDashboard = useSelector(getOfferDashboard);
    const offers = useSelector(getOffers);
    const selectRef = useRef<any>(null);
    const barChartSelected = useSelector(getBarChartSelected);
    const dispatch = useDispatch();
    const [imgGeneratorIsOpen, setImgGeneratorIsOpen] = useState<boolean>(false);
    const [endModalIsOpen, setEndModalIsOpen] = useState<boolean>(false);
    const [extendModalIsOpen, setExtendModalIsOpen] = useState<boolean>(false);


    useEffect(() => {
        getTargetGroupsHandler();
        getOffersHandler();
    }, [isMerchantSelected,merchantPoint, merchantPoint.id]);

    useEffect(() => {
        getOfferDashboardDataHandler(dateRange, previousPeriod, chartInterval);
    }, [dateRange, previousPeriod, chartInterval, currentOffer]);

    if (!merchant)
        return <div/>;

    return (
        <div className="dashboard-offers-content">
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Promocje
                        - {isMerchantSelected ? "Cała sieć " : ""}{isMerchantSelected ? merchant.name : merchantPoint.name}</h2>
                </div>
                <div className="header-section offer-button-wrapper">
                    <CreateOfferButton/>
                    <DateRangePicker dateRange={dateRange}
                                     setDateRange={(dateRange: DateRange) => dispatch(setDateRangeAction(dateRange))}
                                     datePreset={datePreset}
                                     setDatePreset={(datePreset) => dispatch(setDatePresetAction(datePreset))}
                    />
                </div>
            </div>
            <div className="block-wrapper block-wrapper-statistics">
                <StatisticsTopTile title="Zakończone promocje" value={offerDashboard.ended_offers}
                                   format={WHOLE_NUMBER_FORMAT}/>
                <StatisticsTopTile title="Nadchodzące promocje" value={offerDashboard.future_offers}
                                   format={WHOLE_NUMBER_FORMAT}/>
                <StatisticsTopTile title="Depozyt promocyjny (zł)" value={offerDashboard.offers_deposit}
                />
                <StatisticsTopTile title="Przychody z wybranej promocji (zł)"
                                   value={offerDashboard.current_offer_income}
                />
            </div>
            <div className="block-wrapper discount-section-wrapper">
                {isEqual(currentOffer, DEFAULT_OFFER_DATA) ?
                    <div className="discount-block block-1-4">
                        <div className="title-section">
                            <div className="value-section">
                                <h4>Wybierz promocję</h4>
                            </div>
                        </div>
                        <div className="discount-center">
                            <div className="offer-button-wrapper">
                                <span className="small-block-title grey">Brak promocji</span>
                                <div className="mt16">
                                    <CreateOfferButton/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="discount-block block-1-4">
                        <div className="current-offer-wrapper">
                            <div>
                                <div className="title-section mb16">
                                    <div className="value-section">
                                        <h4>Wybrana promocja</h4>
                                    </div>
                                </div>
                                <div className="select-wrapper mb24">
                                    <div className="prefix-icon-wrapper">
                                        {<img className="prefix-icon"
                                              src={icons.discountIcon}
                                              alt="prefix icon"/>
                                        }
                                    </div>
                                    <Select
                                        className="input-select"
                                        variant="borderless"
                                        value={currentOffer?.id}
                                        onChange={(selectedId) => {
                                            const selectedOffer = offers.find(offer => offer.id === selectedId);
                                            if (selectedOffer) {
                                                store.dispatch(setCurrentOfferAction(selectedOffer))
                                            }
                                        }}
                                        allowClear={true}
                                        showSearch={false}
                                        options={offers.map((offer) => ({
                                            value: offer.id, label: offer.name
                                        }))}
                                        ref={selectRef}
                                    />
                                </div>
                                {currentOffer && currentOffer.end_date && currentOffer.start_date &&
                                    <div className="time-left-wrapper mb16">
                                        <div className="prefix-icon-wrapper">
                                            <img className="prefix-icon"
                                                 src={icons.offerCalendarIcon}
                                                 alt="calendar icon"
                                            />
                                        </div>
                                        <p className="label start">Pozostały czas trwania: </p>
                                        <p className="label blue w700 end">{getHowDaysLeft(currentOffer.start_date, currentOffer.end_date)}</p>
                                    </div>
                                }
                                <div className="button-wrapper">
                                    <Button label="Zakończ"
                                            onClick={()=>setEndModalIsOpen(true)}
                                            type="Default"
                                            extraStyle={{
                                                border: "1px solid red",
                                                color: "red",
                                            }}
                                    />
                                    <Button label="Przedłuż"
                                            onClick={()=>setExtendModalIsOpen(true)}
                                            type="Primary"/>
                                </div>
                            </div>
                            <div className="bottom-icons">
                                <div className="clickable-div">
                                    <img className="icon mr4" src={icons.cogIcon} alt="promote"/>
                                    <p className="label blue w700">Zarządzaj promocjami</p>
                                </div>
                                <div className="clickable-div"
                                     onClick={() => setImgGeneratorIsOpen(true)}
                                >
                                    <img className="icon mr4" src={icons.megaphoneIcon} alt="promote"/>
                                    <p className="label blue w700 mr4">Promuj</p>
                                    <img className="chevron-icon promote-icon" src={icons.rightArrowBlueIcon}
                                         alt="promote"/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {isEqual(currentOffer, DEFAULT_OFFER_DATA) ?
                    <div className="discount-block block-1-2 h320">
                        <div className="title-section">
                        </div>
                        <div className="discount-center">
                            <NoChart/>
                        </div>
                    </div>
                    :
                    <div className="discount-block block-1-2">
                        <div className="title-section mb16">
                            <h4>Szczegóły wybranej promocji</h4>
                        </div>
                        <div className="details-offer-wrapper">
                            <div className="details-media">
                                <img
                                    className="media"
                                    src={currentOffer.image_path ? `${cloudFrontUrl}${currentOffer.image_path}` : images.dashboardOfferImagePlaceHolder}
                                    alt={"Offer"}/>
                                {currentOffer?.film_path ?
                                    <>
                                        <video key={currentOffer.id} className={`media-${currentOffer.film_layout}`} controls>
                                            <source src={`${cloudFrontUrl}${currentOffer.film_path}`}
                                                    type="video/mp4"/>
                                            <source src={`${cloudFrontUrl}${currentOffer.film_path}`} type="video/ogg"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </>
                                    :
                                    <img
                                        className="media-horizontal"
                                        src={images.dashboardOfferVideoPlaceHolder}
                                        alt={"Offer Video"}/>
                                }
                            </div>
                            <div className="details-offer-content">
                                <div className="mb8">
                                    <h4 className="mb4">{currentOffer.name}</h4>
                                    {currentOffer.description !== "" &&
                                        <p className="group-details mb4">{currentOffer.description}</p>
                                    }
                                    {currentOffer.end_date && currentOffer.start_date &&
                                        <div className="one-line mb4">
                                            <p className="small-label blue">Promocja dostępna w okresie:
                                                &nbsp;
                                            </p>
                                            <span
                                                className="small-label blue w700">{
                                                `${dayjs(currentOffer.start_date).format('DD.MM.YYYY')} - 
                                                        ${dayjs(currentOffer.end_date).format('DD.MM.YYYY')}`}</span>
                                        </div>
                                    }
                                    <div className="one-line mb8">
                                        <p className="small-label blue">w&nbsp;</p>
                                        <p className="small-label blue w700">
                                            {mapOfferDaysToString(currentOffer.offer_days)}.
                                        </p>
                                    </div>
                                    <div className="discount-tags-wrapper">
                                        {currentOffer.usage &&
                                            <div className="discount-tag">
                                                {OfferUsageMapping[currentOffer.usage]}
                                            </div>
                                        }
                                        {currentOffer.type &&
                                            <div className="discount-tag">
                                                {OfferTypeMapping[currentOffer.type]}
                                            </div>
                                        }
                                        {currentOffer.image_path !== "" &&
                                            <div className="discount-tag">Obrazek promocyjny</div>
                                        }
                                        {currentOffer.film_path !== "" &&
                                            <div className="discount-tag">Filmik promocyjny</div>
                                        }
                                        {currentOffer.offer_merchant_points &&
                                            <div className="discount-tag">
                                                Punkty objęte promocją:&nbsp;
                                                {currentOffer.offer_merchant_points.length}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="label-wrapper">
                                        <div className="icon-content">
                                            <img className="icon"
                                                 src={icons.navigateToMeIcon}
                                                 alt="calendar icon"
                                            />
                                            <p className="label start">Przewidywany zasięg promocji: </p>
                                        </div>
                                        <p className="label end w700">{formatNumber(offerDashboard.offer_current_range,WHOLE_NUMBER_FORMAT)}/{showCustomersNumber(currentOffer.number_of_customers)}</p>
                                    </div>
                                    <div className="label-wrapper">
                                        <div className="icon-content">
                                            <img className="icon"
                                                 src={icons.coinGrayIcon}
                                                 alt="calendar icon"
                                            />
                                            <p className="label">Łączny koszt promocji: </p>
                                        </div>
                                        <p className="label end w700">{formatNumber(offerDashboard.offer_current_cost,DOUBLE_DECIMAL_NUMBER_FORMAT)}/{formatNumber(currentOffer.estimated_cost)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {isEqual(currentOffer, DEFAULT_OFFER_DATA) ?
                    <div className="discount-block block-1-4">
                        <div className="title-section">
                            <h4>Grupa docelowa</h4>
                        </div>
                        <div className="discount-center">
                            <NoChart/>
                        </div>
                    </div>
                    :
                    <div className="discount-block block-1-4">
                        <div className="title-section mb16">
                            <h4>Grupa docelowa -&nbsp;</h4>
                            <h4 className="blue"> {currentOffer.target_group.name}</h4>
                        </div>
                        <div>
                        {!isEqual(rangesHandler(currentOffer.target_group.age_ranges), [null, null, null, null, null]) ?
                            <div className="label-wrapper">
                                <p className="label start">Wiek: </p>
                                <div className="content-wrapper">
                                    {rangesHandler(currentOffer.target_group.age_ranges)}
                                </div>
                            </div>
                            :
                            <div className="label-wrapper">
                                <p className="label start">Wiek: </p>
                                <div className="content-wrapper">
                                    <p className="label w700">Nie podano</p>
                                </div>
                            </div>
                        }
                        {!isEmpty(genderHandler(currentOffer.target_group.gender)) ?
                            <div className="label-wrapper">
                                <p className="label start">Płeć:</p>
                                <div className="content-wrapper">
                                    {genderHandler(currentOffer.target_group.gender)}
                                </div>
                            </div>
                            :
                            <div className="label-wrapper">
                                <p className="label start">Płeć:</p>
                                <div className="content-wrapper">
                                    <p className="label w700">Nie podano</p>
                                </div>
                            </div>
                        }
                        {!isEqual(rangesHandler(currentOffer.target_group.transactions_ranges), [null, null, null, null, null]) ?
                            <div className="label-wrapper">
                                <p className="label start">Liczba transakcji:</p>
                                <div className="content-wrapper">
                                    {rangesHandler(currentOffer.target_group.transactions_ranges)}
                                </div>
                            </div>
                            :
                            <div className="label-wrapper">
                                <p className="label start">Liczba transakcji:</p>
                                <div className="content-wrapper">
                                    <p className="label w700">Nie podano</p>
                                </div>
                            </div>
                        }
                        {!isEqual(rangesHandler(currentOffer.target_group.amount_sum_ranges), [null, null, null, null, null]) ?
                            <div className="label-wrapper"><p className="label start">Kwota zakupów (zł): </p>
                                <div className="content-wrapper">
                                    {rangesHandler(currentOffer.target_group.amount_sum_ranges)}
                                </div>
                            </div>
                            :
                            <div className="label-wrapper"><p className="label start">Kwota zakupów (zł): </p>
                                <div className="content-wrapper">
                                    <p className="label w700">Nie podano</p>
                                </div>
                            </div>
                                }
                        {currentOffer.offer_localizations.offer_range ?
                            <div className="label-wrapper">
                                <p className="label start">Lokalizacja: </p>
                                <p className="label end w700">{`${currentOffer.offer_localizations.place_name} +${currentOffer.offer_localizations.offer_range} km`}</p>
                            </div>
                        :
                            <div className="label-wrapper">
                                <p className="label start">Lokalizacja: </p>
                               <p className="label end w700">Nie podano</p>
                            </div>
                        }
                        {currentOffer.extra_customers ?
                            <div className="label-wrapper"><p className="label start">Ręcznie dodane osoby: </p>
                                <p className="label end w700">
                                    {currentOffer.extra_customers}
                                </p>
                            </div>
                            :
                            <div className="label-wrapper"><p className="label start">Ręcznie dodane osoby: </p>
                                <p className="label end w700">
                                    Nie podano
                                </p>
                            </div>
                        }
                        </div>
                    </div>
                }
            </div>
            <div className="block-wrapper statistics-chart-block-wrapper">
                <div className="discount-block block-1-3 h230">
                    <div className="title-section">
                        <h4>Nowi klienci</h4>
                    </div>
                    <div className="discount-center">
                        {isEmpty(offerDashboard.new_clients) ?
                            <NoChart/>
                            :
                            <DateIntervalBarChart
                                chartData={offerDashboard.new_clients}
                                interval={chartInterval}
                                chartType={'absolute'}
                                height={160}
                            />
                        }
                    </div>
                </div>
                <div className="discount-block block-1-3">
                    <div className="title-section">
                        <h4>Powracający klienci</h4>
                    </div>
                    <div className="discount-center">
                        {isEmpty(offerDashboard.returning_clients) ?
                            <NoChart/>
                            :
                            <DateIntervalBarChart
                                chartData={offerDashboard.returning_clients}
                                interval={chartInterval}
                                chartType={'absolute'}
                                height={160}
                            />
                        }
                    </div>
                </div>
                <div className="discount-block block-1-3">
                    <div className="title-section">
                        <h4>Liczba użyć promocji</h4>
                    </div>
                    <div className="discount-center">
                        {isEmpty(offerDashboard.offer_use_count) ?
                            <NoChart/>
                            :
                            <DateIntervalBarChart
                                chartData={offerDashboard.offer_use_count}
                                interval={chartInterval}
                                chartType={'absolute'}
                                height={160}
                            />
                        }
                    </div>
                </div>
            </div>
            <StatisticsAggregateChartTile aggregate={"amount"}
                                          chartData={offerDashboard.income_data}
                                          prevChartData={offerDashboard.prev_income_data}
                                          value={offerDashboard.income_sum}
                                          prevValue={offerDashboard.prev_income_sum}
                                          chartInterval={chartInterval}
                                          barChartSelected={barChartSelected}
                                          setBarChartSelected={(selected) => dispatch(setBarChartSelectedAction(selected))}
                                          offerChart={true}
            />
            <div className="block-wrapper statistics-chart-block-wrapper">
                <div className="discount-block block-1-2 h230">
                    <div className="title-section">
                        <h4>Liczba zarezerwowanych promocji</h4>
                    </div>
                    <div className="discount-center">
                        {isEmpty(offerDashboard.offer_reservation_count) ?
                            <NoChart/>
                            :
                            <DateIntervalBarChart
                                chartData={offerDashboard.offer_reservation_count}
                                interval={chartInterval}
                                chartType={'absolute'}
                                height={160}
                            />
                        }
                    </div>
                </div>
                <div className="discount-block block-1-2">
                    <div className="title-section">
                        <h4>Liczba zrealizowanych promocji</h4>
                    </div>
                    <div className="discount-center">
                        {isEmpty(offerDashboard.offer_realization_count) ?
                            <NoChart/>
                            :
                            <DateIntervalBarChart
                                chartData={offerDashboard.offer_realization_count}
                                interval={chartInterval}
                                chartType={'absolute'}
                                height={160}
                            />
                        }
                    </div>
                </div>
            </div>
            <ImgGeneratorModal isOpen={imgGeneratorIsOpen}
                               onClose={() => setImgGeneratorIsOpen(false)}
            />
            <EndOfferModal isOpen={endModalIsOpen} onClose={()=>setEndModalIsOpen(false)}/>
            <ExtendOfferModal
                isOpen={extendModalIsOpen}
                onClose={()=>setExtendModalIsOpen(false)}
            />
        </div>
    )
};

export default Offers;