import React, {useState} from "react";
import Modal from "react-modal";
import {FilterModalProps, MultiSelectOption} from "../../../services/interfaces";
import {icons} from "../../../services/images";
import FiltersDropdown from "../../modals/filters/FiltersDropdown";
import Button from "../../common/Button";



const StatisticsTableFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const [columns, setColumns] = useState<string[]>([]);

    const columnsOptions: MultiSelectOption[] = [
        {value: 'Imię i Nazwisko', children: 'name'},
        {value: 'Adres e-mail', children: 'email'},
        {value: 'Wiek', children: 'age'},
        {value: 'Płeć', children: 'gender'},
        {value: 'Łącznie wydana kwota', children: 'amount'},
    ];

    const clearFilters = () => {
        setColumns([]);
    };

    const applyFilters = () => {
        const urlFilters = '' +
            '&columns=' + columns.join(',');
        setFilters(urlFilters);
        setOpen(false);
    };


    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersDropdown
                    title="Ukryj kolumny"
                    placeholder="Zaznacz kolumny które chcesz ukryć..."
                    options={columnsOptions}
                    values={columns}
                    setValues={setColumns}
                    setChildren={true}
                />
            </div>
            <div className="modal-footer">
                <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>
            </div>
        </Modal>
    )
};

export default StatisticsTableFiltersModal