import Modal from "react-modal";
import {icons} from "../../../services/images";
import React, {useEffect, useState} from "react";
import {getMerchantHistoryDepositHandler} from "../../../handlers/merchantHandler";
import {HistoryDeposit} from "../../../services/interfaces";
import {formatNumber} from "../../../services/numbers";
import {categorizeByDate, displayConvertedDate} from "../../../services/dates";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    deposit: number | null;
    blockedFunds: number | null;
}

const HistoryDepositModal = ({isOpen, setIsOpen, setIsLoaded, deposit, blockedFunds}: Props) => {

    const [history, setHistory] = useState<HistoryDeposit[]>([]);

    useEffect(() => {
        isOpen && getMerchantHistoryDepositHandler(setHistory, () => setIsLoaded(false));
    }, [isOpen, setIsLoaded]);

    const renderSingleHistory = (historyItem: HistoryDeposit) => {

        let icon = icons.whiteCoinIcon;
        let itemName = 'Nieznane działanie';
        if (historyItem.history_type === 'CHARGE') {
            icon = icons.addFundsIcon;
            itemName = 'Zasilenie salda';
        } else if (historyItem.history_type === 'BLOCK') {
            icon = icons.blockFundsIcon;
            itemName = `Blokada ${historyItem.transaction_id ? `(#${historyItem.transaction_id})` : ''}`;
        } else if (historyItem.history_type === 'UNBLOCK') {
            icon = icons.unblockFundsIcon;
            itemName = `Odblokowanie środków ${historyItem.transaction_id ? `(#${historyItem.transaction_id})` : ''}`;
        } else if (historyItem.history_type === 'CASHBACK') {
            icon = icons.cashbackPaidIcon;
            itemName = `Rozliczenie transakcji ${historyItem.transaction_id ? `(#${historyItem.transaction_id})` : ''}`
        } else if (historyItem.history_type === 'PROMOTION') {
            icon = icons.promotionPaidIcon;
            itemName = 'Opłacenie promocji'
        }


        return (
            <div className="history-item">
                <img className="history-icon" src={icon} alt=""/>
                <div className="history-info">
                    <span>{itemName}</span>
                    <span>{historyItem.amount > 0 && historyItem.history_type !== 'CASHBACK' ? '+' : ''}{formatNumber(historyItem.amount)}</span>
                    <span>{displayConvertedDate(historyItem.date)}</span>
                </div>
                <div className="history-total">{formatNumber(historyItem.total_after)}</div>
            </div>
        )
    }

    const categorizedHistory = categorizeByDate(history, 'date');

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            overlayClassName="modal-wrapper"
            className="modal-content popup deposit-history"
            ariaHideApp={false}
        >
            <div className="modal-header">
                <div className="title-row">
                    <h5 className="modal-title">Historia salda</h5>
                    <div className="modal-close" onClick={() => setIsOpen(false)}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="cash-row">
                    <div className="cash-block">
                        <span className="title">Depozyt:</span>
                        <span className="amount">{formatNumber(deposit)}</span>
                    </div>
                    <div className="cash-block">
                        <span className="title">Blokada:</span>
                        <span className="amount">{formatNumber(blockedFunds)}</span>
                    </div>
                </div>
            </div>
            <div className="modal-body">
                <div className="history-list">
                    {['today', 'yesterday', 'last7Days', 'last30Days', 'olderThanMonth'].map(group => {
                            if (categorizedHistory[group].length > 0) {
                                return (
                                    <div className="history-group" key={group}>
                                        <span className="title">{group === 'today' ? 'Dzisiaj' :
                                            group === 'yesterday' ? 'Wczoraj' :
                                                group === 'last7Days' ? 'Ostatnie 7 dni' :
                                                    group === 'last30Days' ? 'Ostatnie 30 dni' : 'Starsze niż 30 dni'}</span>
                                        {categorizedHistory[group].map(item => renderSingleHistory(item))}
                                    </div>
                                )
                            }
                        }
                    )}
                </div>
            </div>
        </Modal>
    )
};

export default HistoryDepositModal;