import request, {GET, POST, PUT} from "./request";
import {
    Coordinates,
    DashboardData,
    GoogleMapsSearchResult,
    MerchantPoint,
    MerchantPointUpdateData,
    MerchantUpdateData,
    NIPSearchResponse, OrderType
} from "../services/interfaces";
import {handleError} from "./handleError";
import {omit} from "lodash";


export const searchCompanyByNIP = async (nip: string): Promise<NIPSearchResponse> => {
    try {
        const {data} = await request(GET, `merchant/nip_search?nip=${nip}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};


export const googleMapsSearch = async (query: string, location: Coordinates | null): Promise<GoogleMapsSearchResult[]> => {
    try {
        let path = `merchant/google_maps_search?q=${query}`;
        if (location)
            path += `&lat=${location.lat}&lng=${location.lng}`;
        const {data} = await request(GET, path);
        return data;
    } catch (error) {
        throw handleError(error);
    }
};


export const updateMerchant = async (merchantUpdateData: MerchantUpdateData) => {
    try {
        const {status}: { status: number } = await request(PUT, `merchant/`, {...merchantUpdateData});
        return {status};
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchant = async () => {
    try {
        const {data} = await request(GET, `merchant/`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPoint = async (merchantPointId: number) => {
    try {
        const {data} = await request(GET, `merchant/point/${merchantPointId}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const createMerchantPoint = async (merchantPoint: MerchantPoint) => {
    try {
        const {data} = await request(POST, `merchant/point`, {
            ...merchantPoint,
            opening_hours: merchantPoint.opening_hours.map(o => omit(o, 'id')),
            service_types: merchantPoint.service_types.map(s => omit(s, 'id')),
        });
        return data;
    } catch (error) {
        throw handleError(error);
    }
};


export const updateMerchantPoint = async (merchantPointId: number, merchantPointUpdateData: MerchantPointUpdateData) => {
    try {
        const {status}: {
            status: number
        } = await request(PUT, `merchant/point/${merchantPointId}`, {...merchantPointUpdateData});
        return {status};
    } catch (error) {
        throw handleError(error);
    }
};


export const getMerchantDashboard = async (urlParam: string): Promise<{
    previous: DashboardData,
    current: DashboardData
}> => {
    try {
        const {data} = await request(GET, `merchant/dashboard?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const getMerchantPointDashboard = async (merchantPointId: number, urlParam: string): Promise<{
    previous: DashboardData,
    current: DashboardData
}> => {
    try {
        const {data} = await request(GET, `merchant/point/${merchantPointId}/dashboard?${urlParam}`);
        return data
    } catch (error) {
        throw handleError(error);
    }
};

export const sendRemoveTerminalRequest = async (terminalId: number) => {
    try {
        const {status}: { status: number } = await request(GET, `merchant/terminal/remove/?terminal_id=${terminalId}`);
        return {status};
    } catch (error) {
        throw handleError(error);
    }
};

export const addMerchantDeposit = async (amount: number) => {
    try {
        const {data} = await request(POST, `merchant/deposit/add/`, {amount});
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getMerchantHistoryDeposit = async () => {
    try {
        const {data} = await request(GET, `merchant/deposit/history/`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getMerchantUsers = async (urlParam: string) => {
    try {
        const {data} = await request(GET, `merchant/current_users/?${urlParam}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getAccountingDocuments = async (
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: OrderType,
    filterString: string,
    query: string,
) => {
    try {
        const {data} = await request(GET, `merchant/accounting/?page=${page}&page_size=${pageSize}&sort_by=${sortBy}&sort_order=${sortOrder}&query=${query}&filters=${filterString}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getAccountingFilters = async () => {
    try {
        const {data} = await request(GET, `merchant/accounting/filters/`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const getAccountingDocumentsXLSX = async (
    sortBy: string,
    sortOrder: OrderType,
    query: string,
    filterString: string,
) => {
    try {
        const {data} = await request(GET, `merchant/accounting/list/?sort_by=${sortBy}&sort_order=${sortOrder}&query=${query}&filters=${filterString}`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}

export const downloadAccountingDocumentPDF = async (documentId: number) => {
    try {
        const {data} = await request(GET, `merchant/accounting/${documentId}/pdf/`);
        return data;
    } catch (error) {
        throw handleError(error);
    }
}