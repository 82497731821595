import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import { Input } from 'antd';
import {Customer} from "../../services/interfaces";
import {icons} from "../../services/images";
import {getMerchantUsers} from "../../http/merchant";
import Checkbox from "../common/Checkbox";


interface UsersListModalProps {
    isOpen: boolean,
    onClose: () => void;
    estimateUsers: Customer[],
    setEstimateUsers: React.Dispatch<React.SetStateAction<Customer[]>>;
    numberOfCustomers: number;
    setNumberOfCustomers: React.Dispatch<React.SetStateAction<number>>;
}

const UsersListModal = ({
                            isOpen,
                            onClose,
                            estimateUsers,
                            setEstimateUsers,
                            numberOfCustomers,
                            setNumberOfCustomers
                        }: UsersListModalProps) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState<Customer[]>([]);
    const [allUsers, setAllUsers] = useState<Customer[]>([]);

    useEffect(() => {
        const fetchAllUsers = async () => {
            const userData = await getMerchantUsers('');
            const updatedUsers = userData.map((user: Customer) => ({
                ...user,
                status: estimateUsers.some((u) => u.id === user.id),
            }));
            setAllUsers(updatedUsers);
            setUsers(updatedUsers);
        };
        fetchAllUsers();
    }, [estimateUsers]);

    const handleSearch = async () => {
        const urlParam = new URLSearchParams();
        urlParam.append('q', searchTerm);
        const userData = await getMerchantUsers(urlParam.toString());
        const updatedUsers = userData.map((user: Customer) => ({
            ...user,
            status: estimateUsers.some((u) => u.id === user.id),
        }));
        setUsers(updatedUsers);
    };

    useEffect(() => {
        if (searchTerm === '') {
            setUsers(allUsers);
        }
    }, [searchTerm, allUsers]);

    const handleCheckboxChange = (updatedUser: Customer) => {
        const updatedUsers = users.map((user: Customer) => {
            if (user.id === updatedUser.id) {
                return {...updatedUser};
            }
            return user;
        });
        setUsers(updatedUsers);

        const updatedAllUsers = allUsers.map((user: Customer) => {
            if (user.id === updatedUser.id) {
                return {...updatedUser};
            }
            return user;
        });
        setAllUsers(updatedAllUsers);

        if (updatedUser.status) {
            if (!estimateUsers.some(user => user.id === updatedUser.id)) {
                setEstimateUsers(prev => [...prev, updatedUser]);
                setNumberOfCustomers(prev => prev + 1);
            }
        } else {
            setEstimateUsers(prev => prev.filter(user => user.id !== updatedUser.id));
            setNumberOfCustomers(prev => prev - 1);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            overlayClassName="modal-wrapper"
            className="users-modal"
            ariaHideApp={false}
        >
            <div>
                <div className="modal-header">
                    <div className="title-wrapper">
                        <h5 className="modal-title">Dodaj lub usuń użytkowników</h5>
                    </div>
                    <div className="modal-close" onClick={onClose}>
                        <img src={icons.closeIcon} alt=""/>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="input">
                        <img className="icon"
                             src={icons.search} alt={'Pin'}/>
                        <Input type="text"
                               className="input-text"
                               allowClear
                               variant={"borderless"}
                               value={searchTerm}
                               onChange={e => setSearchTerm(e.target.value)}
                               onKeyDown={(e) => {
                                   if (e.key === 'Enter') {
                                       handleSearch();
                                   }
                               }}
                               placeholder="Szukaj"
                               maxLength={50}
                        />
                    </div>
                    {users.map((user, index) => (
                        <div key={index} className="checkbox-wrapper">
                            <Checkbox user={user} onChange={handleCheckboxChange}/>
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default UsersListModal;