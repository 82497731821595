import React, {useEffect, useRef, useState} from 'react';
import Button from "../../common/Button";
import {
    AdditionalCustomer,
    Coordinates, Customer, Gender, OfferLocation, TargetGroup
} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getOfferTargetGroup, getTargetGroups} from "../../../redux/selectors/merchantSelector";
import MapModal, {DEFAULT_OFFER_LOCATION} from "../MapModal";
import {icons} from "../../../services/images";
import { Divider, Input as InputAntd, Select, Space } from 'antd';
import type { InputRef } from 'antd';
import {TARGET_GROUP_DEFAULT_STATE} from "../../../redux/merchant";
import TargetGroupDatePicker from "../target_group/TargetGroupDatePicker";
import OfferRadioSelect from "./OfferRadioSelect";
import {
    getMerchantTargetGroupUsers,
    getTargetGroupUsersExceptMerchant
} from "../../../http/targetGroup";
import {formatNumber} from "../../../services/numbers";
import {showCustomersNumber} from "../../../services/utils";
import TargetGroupRangesSelect from "../target_group/TargetGroupRangesSelect";
import TargetGroupAmountRanges from "../target_group/TargetGroupAmountRanges";
import {
    createTargetGroupHandler,
    updateTargetGroupHandler
} from "../../../handlers/targetGroupHandlers";
import {isEqual} from "lodash";
import Switch from "../../common/Switch";
import UsersListModal from "../UsersListModal";
import {Tooltip} from "react-tooltip";


interface StepProps {
    formData: {
        clients: string;
        target_group: TargetGroup;
        offer_localizations: OfferLocation;
        estimated_cost: number;
        number_of_customers: number;
        extra_customers: number,
        offer_user_changes: AdditionalCustomer[],
        offer_rate: number,
    };
    handleChangeSubField: (field: string, subfield: string, value: any) => void;
    handleChange: (field: string, value: any) => void;
    nextStep: () => void;
    prevStep: () => void;
}

const Step1: React.FC<StepProps> = ({
                                        formData,
                                        handleChange,
                                        handleChangeSubField,
                                        nextStep,
                                        prevStep,
                                    }) => {
    const [mapIsOpen, setMapIsOpen] = useState(false);
    const [userListIsOpen, setUserListIsOpen] = useState(false);
    const [myLocation, setMyLocation] = useState<Coordinates | null>(null);

    const targetGroups = useSelector(getTargetGroups);
    const [options, setOptions] = useState<TargetGroup[]>(targetGroups);
    const [error, setError] = useState(false);
    const offerTargetGroup = useSelector(getOfferTargetGroup);
    const [newTargetGroup, setNewTargetGroup] = useState(TARGET_GROUP_DEFAULT_STATE);
    const [temporaryTargetGroup, setTemporaryTargetGroup] = useState<TargetGroup>(offerTargetGroup);
    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);
    const [currentTargetGroup, setCurrentTargetGroup] = useState<string>('needToKeep');
    const [overrideTargetGroup, setOverrideTargetGroup] = useState<boolean>(false);

    const selectRef = useRef<any>(null);
    const inputRef = useRef<InputRef>(null);

    const [exceptedNumberOfCustomers, setExceptedNumberOfCustomers] = useState<number>(0);
    const [customerList, setCustomerList] = useState<Customer[] | []>([]);
    const [estimatedCustomerList, setEstimatedCustomerList] = useState<Customer[] | []>([]);
    const [changes, setChanges] = useState<AdditionalCustomer[]>([]);

    const currentUsersRate = 0.05; //should be downloaded from AdminAPI
    const newUsersRate = 0.1;

    const compareCustomerLists = (
        currentList: Customer[],
        estimatedList: Customer[]
    ): AdditionalCustomer[] => {
        const newChanges: AdditionalCustomer[] = [];

        currentList.forEach((customer) => {
            const existsInEstimated = estimatedList.some(
                (estCustomer) => estCustomer.id === customer.id
            );

            if (!existsInEstimated && customer.status) {
                newChanges.push({
                    id: customer.id,
                    status: true,
                });
            }
        });

        estimatedList.forEach((estCustomer) => {
            const existsInCurrent = currentList.some(
                (customer) => customer.id === estCustomer.id
            );

            if (!existsInCurrent) {
                newChanges.push({
                    id: estCustomer.id,
                    status: false,
                });
            }
        });

        return newChanges;
    };

    const extraCustomersHandler = (changes:AdditionalCustomer[]) => {
        let additionalCustomersNumber = 0;
         changes.map(change => {
            if(change.status){
                additionalCustomersNumber++;
            }
            return change
        })
        handleChange("extra_customers",additionalCustomersNumber);
    }

    const estimateCost = (clients: string, numberOfCustomers: number): number => {
        // TODO: Estimate Rate must be downloaded from AdminAPI
        if (clients === "current") {
            return numberOfCustomers * currentUsersRate
        } else if (clients === "new") {
            return numberOfCustomers * newUsersRate
        } else {
            return 0
        }
    }

    const validationAndNextStep = () => {
        if (formData.target_group.name === "") {
            setError(true);
            return;
        }
        nextStep()
    }

    const addItem = () => {
        if (newTargetGroup.name) {
            setOptions([...options, newTargetGroup]);
            handleChange("target_group", newTargetGroup);
            if (selectRef.current) {
                selectRef.current.blur();
            }
            handleSelectNameChange(newTargetGroup.name)
            setCurrentTargetGroup("needToSave");
            setDropdownIsOpen(false);
            setNewTargetGroup(TARGET_GROUP_DEFAULT_STATE);
        }
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(false);
        setNewTargetGroup({
            ...newTargetGroup,
            name: event.target.value
        });
    };

    const handleSelectNameChange = (value: string) => {
        setError(false);
        const selected = options.find(option => option.name === value);
        if (selected) {
            if (selected.id) {
                setCurrentTargetGroup("needToKeep");
            } else {
                setCurrentTargetGroup("needToSave");
            }
            setTemporaryTargetGroup(selected);
            handleChange("target_group", selected);
        }
    }

    const handleMapSave = (location: OfferLocation) => {
        handleChange("offer_localizations", location);
        setMapIsOpen(false);
    };

    const handleMapClose = () => {
        setMapIsOpen(false);
    }

    const resetLocalization = () => {
        handleChange("offer_localizations", DEFAULT_OFFER_LOCATION)
    }

    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setMyLocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        };
        getLocation();
    }, []);

    useEffect(() => {
        if (formData.target_group !== TARGET_GROUP_DEFAULT_STATE) {
            const urlParam = new URLSearchParams();
            const targetGroupJson = JSON.stringify(formData.target_group);
            urlParam.append('target_group_data', targetGroupJson);
            if (formData.offer_localizations && formData.offer_localizations.offer_range) {
                urlParam.append('longitude', String(formData.offer_localizations.coordinates.lng));
                urlParam.append('latitude', String(formData.offer_localizations.coordinates.lat));
                urlParam.append('range_km', String(formData.offer_localizations.offer_range));
            }
            if (formData.clients === "current") {
                getMerchantTargetGroupUsers(urlParam.toString()).then((clients: [Customer]) => {
                    setCustomerList(clients);
                    setEstimatedCustomerList(clients);
                    setExceptedNumberOfCustomers(clients.length);
                })
            }
            if (formData.clients === "new") {
                getTargetGroupUsersExceptMerchant(urlParam.toString()).then((numberOfClients: number) => {
                    setExceptedNumberOfCustomers(numberOfClients);
                })
            }
        }
    }, [formData.target_group, formData.clients, formData.offer_localizations]);

    useEffect(() => {
        if (temporaryTargetGroup.id && formData.target_group.name === temporaryTargetGroup.name && !isEqual(formData.target_group, temporaryTargetGroup)) {
            setCurrentTargetGroup("needToUpdate");
        }
    }, [formData.target_group, temporaryTargetGroup]);

    useEffect(() => {
        if(!userListIsOpen){
            const newChanges = compareCustomerLists(customerList, estimatedCustomerList);
            setChanges(newChanges);
            extraCustomersHandler(newChanges);
            handleChange("offer_user_changes", newChanges);
        }
    }, [userListIsOpen]);

    const offerRange = formData.offer_localizations && formData.offer_localizations.offer_range ? formData.offer_localizations.offer_range : 0;

    return (
        <div className="step-form-wrapper">
            <div className="step-form">
                <OfferRadioSelect selectedOption={formData.clients}
                                  handleOptionChange={e => handleChange("clients", e.target.value)}/>
                <div className="section">
                    <div className="select-wrapper">
                        <p className="label label-margin">Utwórz lub wybierz grupę docelową<span
                            className="required-mark"> *</span></p>
                        <Select
                            className="input"
                            variant="borderless"
                            placeholder="Grupa docelowa..."
                            open={dropdownIsOpen}
                            onDropdownVisibleChange={setDropdownIsOpen}
                            onChange={handleSelectNameChange}
                            value={formData.target_group.name === "" ? undefined : formData.target_group.name}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <Divider className="divider"/>
                                    <Space className="space">
                                        <InputAntd
                                            className="space-input"
                                            placeholder="Wprowadź nazwę nowej grupy docelowej"
                                            ref={inputRef}
                                            value={newTargetGroup.name}
                                            onChange={onNameChange}
                                            onKeyDown={(e) => e.stopPropagation()}
                                        />
                                        <Button onClick={addItem} icon={icons.plusIcon} label={"Dodaj grupę"}/>
                                    </Space>
                                </>
                            )}
                            options={options.map((item: TargetGroup) => ({value: item.name}))}
                            ref={selectRef}
                        />
                    </div>
                    {error &&
                        <div className="section validation">
                            <div className="error-message">Grupa docelowa jest wymagana</div>
                        </div>
                    }
                </div>
                <div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupRangesSelect rangeKey='age_ranges' title='Wiek' placeholder="Wybierz wiek..."
                                                     targetGroup={formData.target_group}
                                                     setTargetGroup={value => handleChange("target_group", value)}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="select-wrapper">
                            <p className="label label-margin">Płeć</p>
                            <Select
                                mode="multiple"
                                value={formData.target_group.gender ? formData.target_group.gender : undefined}
                                onChange={(value) => handleChangeSubField("target_group", "gender", value)}
                                allowClear={true}
                                placeholder="Wybierz płeć..."
                                showSearch={false}
                                options={[
                                    {value: "male" as Gender, label: 'Mężczyzna'},
                                    {value: "female" as Gender, label: 'Kobieta'},
                                    {value: "unknown" as Gender, label: 'Inna'},
                                ]}
                                className="dropdown-select"
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupRangesSelect rangeKey='transactions_ranges'
                                                     title={formData.clients === "current" ? 'Liczba transakcji w sklepie' : 'Liczba transakcji'}
                                                     placeholder="Wybierz liczbę transakcji..."
                                                     targetGroup={formData.target_group}
                                                     setTargetGroup={value => handleChange("target_group", value)}/>
                            <TargetGroupDatePicker editedTargetGroup={formData.target_group}
                                                   setEditedTargetGroup={value => handleChange("target_group", value)}/>
                        </div>
                    </div>
                    <div className="section">
                        <div className="range-container">
                            <TargetGroupAmountRanges
                                editedTargetGroup={formData.target_group}
                                setEditedTargetGroup={value => handleChange("target_group", value)}/>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="offer-wrapper">
                        <div className="wrapper">
                            <p className="label mr8">Lokalizacja</p>
                            <div className="icon-wrapper">
                                <Tooltip id="localization" className="tooltip" noArrow={true}>
                                    <p className="tooltip-body">Tekst tooltip lokalizacji.</p>
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info"
                                     data-tooltip-id="localization" data-tooltip-place="top"/>
                            </div>
                        </div>
                            <p className="label required-mark pointer"
                               onClick={resetLocalization}>Wyczyść</p>
                        </div>
                        <div className="localization-input">
                            <img className="pin"
                                 src={icons.greyPinIcon} alt={'Pin'}/>
                            <input type="text"
                                   className="localization-input-place"
                                   value={formData.offer_localizations.place_name}
                                   onClick={() => setMapIsOpen(true)}
                                   onChange={() => handleMapSave}
                                   placeholder="Wybierz lokalizację..."
                            />
                            <input type="text"
                                   className="input-radius"
                                   placeholder="+ 0 km"
                                   value={`+ ${offerRange} km`}
                                   readOnly
                                   onChange={() => handleMapSave}/>
                        </div>
                        <MapModal
                            isOpen={mapIsOpen}
                            onClose={handleMapClose}
                            userLocation={myLocation}
                            savedLocation={formData.offer_localizations}
                            onSaveRange={handleMapSave}
                        />
                    </div>
                    {currentTargetGroup === "needToUpdate" &&
                    <div className="section">
                        <div className="select-wrapper one-line">
                            <p className="label">Czy chcesz nadpisać grupę?</p>
                            <Switch checked={overrideTargetGroup}
                                    setChecked={(value) => {
                                        setOverrideTargetGroup(value);
                                    }}/>
                        </div>
                    </div>
                }
                <div className="section">
                    <div className="offer-wrapper text-gray">
                        <span className="label">Przewidywany zasięg promocji: </span>
                        <span className="label w700">{showCustomersNumber(exceptedNumberOfCustomers)}</span>
                    </div>
                    <div className="offer-wrapper with-border">
                        <div  className="wrapper">
                            <span className="label">Liczba osób, do których chcesz dotrzeć:</span>
                            <div className="icon-wrapper">
                                <Tooltip id="customers count" className="tooltip" noArrow={true}>
                                    <p className="tooltip-body">Tekst tooltip listy osób do których chcesz dotrzeć.</p>
                                </Tooltip>
                                <img src={icons.infoIcon} className="info-icon" alt="info" data-tooltip-id="customers count" data-tooltip-place="top-end"/>
                            </div>
                        </div>
                        <div className="icon-wrapper">
                            {formData.clients === "current" &&
                                <img
                                    className="pen-icon"
                                    src={icons.pencilIcon}
                                    onClick={() => setUserListIsOpen(true)}
                                    alt={"Show users list"}/>
                            }
                            <div className="slider-value-container">
                                <div className="slider-input-wrapper">
                                    <div className="input-wrapper">
                                        <input
                                            value={exceptedNumberOfCustomers}
                                            onChange={e => setExceptedNumberOfCustomers((e.target.valueAsNumber))}
                                            className="form-control"
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="offer-wrapper">
                        <span className="label">Łączny koszt promocji: </span>
                        <span
                            className="label w700">{formatNumber(estimateCost(formData.clients, exceptedNumberOfCustomers))}</span>
                    </div>
                    <span
                        className="stepper-span blue">Koszt promocji jest zależny od zasięgu promocji.</span>
                    <UsersListModal
                        isOpen={userListIsOpen}
                        estimateUsers={customerList}
                        setEstimateUsers={setCustomerList}
                        numberOfCustomers={exceptedNumberOfCustomers}
                        setNumberOfCustomers={setExceptedNumberOfCustomers}
                        onClose={() => setUserListIsOpen(false)}
                    />
                </div>
                <div className="modal-footer">
                    <Button label="Anuluj"
                            onClick={prevStep}
                            type="Default"/>
                    <Button label="Dalej"
                            disabled={error}
                            onClick={() => {
                                if(formData.clients === "current"){
                                    handleChange("offer_rate",currentUsersRate)
                                }
                                if(formData.clients === "new"){
                                    handleChange("offer_rate",newUsersRate)
                                }
                                if (exceptedNumberOfCustomers) {
                                    handleChange("estimated_cost", estimateCost(formData.clients, exceptedNumberOfCustomers))
                                    handleChange("number_of_customers", exceptedNumberOfCustomers)
                                }
                                if (currentTargetGroup === "needToKeep") {
                                    const newGroup = {...formData.target_group};
                                    delete newGroup.id;
                                    // createOfferTargetGroupHandler(newGroup);
                                }
                                if (currentTargetGroup === "needToSave") {
                                    createTargetGroupHandler(formData.target_group);
                                    // createOfferTargetGroupHandler(formData.target_group);
                                }
                                if (currentTargetGroup === "needToUpdate" && overrideTargetGroup) {
                                    updateTargetGroupHandler(temporaryTargetGroup, formData.target_group);
                                    const newGroup = {...formData.target_group};
                                    delete newGroup.id;
                                    // createOfferTargetGroupHandler(newGroup);
                                }
                                if (currentTargetGroup === "needToUpdate" && !overrideTargetGroup) {
                                    const newGroup = {...formData.target_group};
                                    delete newGroup.id;
                                    // createOfferTargetGroupHandler(newGroup);
                                }
                                validationAndNextStep()
                            }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step1;