import NotificationPopup from "../../common/NotificationPopup";
import React, {useEffect} from "react";
import ReservationTable from "./ReservationTable";
import {getHeaderTitle, useMappedCategories} from "../../../services/helpers";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentMerchantPoint, getIsMerchantSelected, getMerchant} from "../../../redux/selectors/merchantSelector";
import Button from "../../common/Button";
import {icons} from "../../../services/images";
import {setBookingEditModeAction} from "../../../redux/booking";
import OrderTable from "./OrderTable";
import {getCategoriesHandler} from "../../../handlers/bookingHandlers";
import {getCategoriesByType} from "../../../redux/selectors/bookingSelector";
import {Category} from "../../../services/interfaces";
import {every, isEmpty} from "lodash";
import NoBookings from "./NoBookings";


const BookingDashboard = () => {
    const isMerchantSelected = useSelector(getIsMerchantSelected);
    const merchant = useSelector(getMerchant);
    const merchantPoint = useSelector(getCurrentMerchantPoint);
    const dispatch = useDispatch();
    const serviceCategories = useSelector(getCategoriesByType('service'));
    const productCategories = useSelector(getCategoriesByType('product'));
    const mappedServiceCategories: Category[] = useMappedCategories(serviceCategories);
    const mappedProductCategories: Category[] = useMappedCategories(productCategories);
    const mappedCategories: Category[] = mappedProductCategories.concat(mappedServiceCategories);

    useEffect(() => {
        isEmpty(productCategories) && getCategoriesHandler('product');
        isEmpty(serviceCategories) && getCategoriesHandler('service');
    }, [merchantPoint, productCategories, serviceCategories]);

    if (!merchant)
        return <div/>;
    return (
        <div className="dashboard-content">
            <NotificationPopup/>
            <div className="header-wrapper">
                <div className="header-section">
                    <h2>Rezerwacje i zamówienia - {getHeaderTitle(isMerchantSelected, merchant, merchantPoint)}</h2>
                </div>
                <div className="header-section">
                    <Button label="Zarządzaj ofertą" icon={icons.cogWhiteIcon}
                            onClick={() => dispatch(setBookingEditModeAction(true))}/>
                </div>
            </div>
            {isEmpty(mappedCategories) || every(mappedCategories, c => isEmpty(c.items)) ?
                <NoBookings/> :
                <>
                    <OrderTable/>
                    <ReservationTable/>
                </>
            }
        </div>
    )
};

export default BookingDashboard;