import {Transaction} from "../../../services/interfaces";
import {GenderEnum, TransactionStatus} from "../../../services/dictionaries/enums";
import {formatNumber} from "../../../services/numbers";
import {parseQueryString} from "../../../services/utils";
import {icons} from "../../../services/images";


const HistoryTableRow = ({transaction, filters}: { transaction: Transaction, filters: string }) => {

    const dateOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    const formattedDate = new Date(transaction.trade_date).toLocaleDateString('pl-PL', dateOptions);

    let transactionStatus = 'Nieznany';
    if (transaction.status in TransactionStatus) {
        transactionStatus = TransactionStatus[transaction.status];
    }

    let gender = 'Inna';
    if (transaction.user && transaction.user.gender in GenderEnum) {
        gender = GenderEnum[transaction.user.gender];
    }

    const parsedQuery = parseQueryString(filters);
    const excludedColumns = parsedQuery['columns'] ? parsedQuery['columns'].split(',') : [];

    const returnTransaction =
        transaction.status === 'refund' || (transaction.status === 'rejected' && !transaction.root)

    return (
        <tr className={`${returnTransaction ? 'return' : ''}`}>
            {returnTransaction ?
                <>
                    <td colSpan={!excludedColumns.includes('client_id') ? 2 : undefined} style={{textAlign: 'center'}}>
                        <img src={icons.blackArrowUpIcon} alt=""/>
                    </td>
                </> :
                <>
                    <td className="transaction-id-cell">#{transaction.id}</td>
                    {!excludedColumns.includes('client_id') &&
                        <td className="client-id-cell">{transaction.user ? transaction.user.id : '--'}</td>}
                </>
            }
            {!excludedColumns.includes('trade_date') && <td>{formattedDate}</td>}
            {!excludedColumns.includes('name_address_value_1') &&
                <td>{transaction.name_address_value_1}</td>}
            {!excludedColumns.includes('status') &&
                <td className={`cashback-status-cell 
                ${transaction.status === 'rejected' ? 'red' :
                    (transaction.status === 'wallet' ? 'green' :
                        (['receivables', 'due', 'refund'].includes(transaction.status) ? 'blue' :
                            (['queue', 'withdrawn'].includes(transaction.status) ? 'grey' : '')))}`}>
                    {transactionStatus}
                </td>
            }
            {!excludedColumns.includes('amount') &&
                <td className="cell-digit">{formatNumber(transaction.amount)}</td>}
            {!excludedColumns.includes('cashback_accrued') &&
                <td className="cell-digit">{formatNumber(transaction.cashback_accrued)}</td>}
            {!excludedColumns.includes('total_fees') &&
                <td className="cell-digit">{formatNumber(transaction.total_fees)}</td>}
            {!excludedColumns.includes('total') && <td className="cell-digit">{formatNumber(transaction.total)}</td>}
            {!excludedColumns.includes('gender') && <td className="cell-digit">{gender}</td>}
            {!excludedColumns.includes('age') &&
                <td className="cell-digit">{transaction.user && transaction.user.age !== 0 ? transaction.user.age : '--'}</td>}
        </tr>
    )
};

export default HistoryTableRow;