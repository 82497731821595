import {FilterModalProps, MultiSelectOption, ServiceTypeName} from "../../../../services/interfaces";
import {AdminMerchantTransactionsFilterOptions, PartnerTypeState} from "../../../services/interfaces";
import {useSelector} from "react-redux";
import {getAdminMerchantTransactionsFilterOptions} from "../../../redux/selectors/adminViewSettingsSelectors";
import React, {useEffect, useState} from "react";
import AdminPartnerTypeFilter, {DEFAULT_PARTNER_TYPE_STATE} from "../../common/AdminPartnerTypeFilter";
import {getAdminMerchantTransactionsFilterOptionsHandler} from "../../../handlers/filterOptionsHandlers";
import Modal from "react-modal";
import {icons} from "../../../../services/images";
import FiltersSlider from "../../../../components/modals/filters/FiltersSlider";
import Button from "../../../../components/common/Button";
import FiltersDropdown from "../../../../components/modals/filters/FiltersDropdown";
import AdminServiceTypeFilter from "../../common/AdminServiceTypeFilter";
import AdminMerchantPointFilter from "../../common/AdminMerchantPointFilter";


const AdminMerchantsFiltersModal = ({open, setOpen, setFilters}: FilterModalProps) => {
    const transactionsFilterOptions: AdminMerchantTransactionsFilterOptions | null = useSelector(getAdminMerchantTransactionsFilterOptions);

    const [columns, setColumns] = useState<string[]>([]);
    const [serviceTypes, setServiceTypes] = useState<ServiceTypeName[]>([]);
    const [merchantPointIds, setMerchantPointIds] = useState<number[]>([]);
    const [cashbackSlider, setCashbackSlider] = useState<[number, number] | null>(
        transactionsFilterOptions ? [transactionsFilterOptions.min_cashback, transactionsFilterOptions.max_cashback] : null);
    const [feeSlider, setFeeSlider] = useState<[number, number] | null>(
        transactionsFilterOptions ? [transactionsFilterOptions.min_payprofit_fee, transactionsFilterOptions.max_payprofit_fee] : null);
    const [partnerSlider, setPartnerSlider] = useState<[number, number] | null>(
        transactionsFilterOptions ? [transactionsFilterOptions.min_partner_cashback, transactionsFilterOptions.max_partner_cashback] : null);
    const [forestSlider, setForestSlider] = useState<[number, number] | null>(
        transactionsFilterOptions ? [transactionsFilterOptions.min_forest_cashback, transactionsFilterOptions.max_forest_cashback] : null);
    const [countSlider, setCountSlider] = useState<[number, number] | null>(
        transactionsFilterOptions ? [transactionsFilterOptions.min_count, transactionsFilterOptions.max_count] : null);
    const [partnerType, setPartnerType] = useState<PartnerTypeState>(DEFAULT_PARTNER_TYPE_STATE);

    const columnsOptions: MultiSelectOption[] = [
        {value: 'Nazwa', children: 'name'},
        {value: 'Branża', children: 'service_type'},
        {value: 'Oddziały', children: 'points_count'},
        {value: 'Cashback od zakupu', children: 'cashback'},
        {value: 'Cashback od zakupu - Udział', children: 'cashback_share'},
        {value: 'Cashback dla Payprofit', children: 'payprofit_fee'},
        {value: 'Cashback dla Payprofit - Udział', children: 'payprofit_fee_share'},
        {value: 'Cashback Partnerski', children: 'partner_cashback'},
        {value: 'Cashback Partnerski - Udział', children: 'partner_cashback_share'},
        {value: 'Cashback na las', children: 'forest_cashback'},
        {value: 'Cashback na las - Udział', children: 'forest_cashback_share'},
        {value: 'Sprzedaż nieopłacone', children: 'due_payment'},
        {value: 'Liczba wizyt', children: 'transactions_count'},
    ];

    useEffect(() => {
        getAdminMerchantTransactionsFilterOptionsHandler();
    }, []);

    useEffect(() => {
        if (transactionsFilterOptions &&
            (cashbackSlider === null || feeSlider === null || forestSlider === null || partnerSlider === null)) {
            setCashbackSlider([transactionsFilterOptions.min_cashback, transactionsFilterOptions.max_cashback]);
            setFeeSlider([transactionsFilterOptions.min_payprofit_fee, transactionsFilterOptions.max_payprofit_fee]);
            setPartnerSlider([transactionsFilterOptions.min_partner_cashback, transactionsFilterOptions.max_partner_cashback]);
            setForestSlider([transactionsFilterOptions.min_forest_cashback, transactionsFilterOptions.max_forest_cashback]);
            setCountSlider([transactionsFilterOptions.min_count, transactionsFilterOptions.max_count]);
            setPartnerType(DEFAULT_PARTNER_TYPE_STATE);
        }
    }, [transactionsFilterOptions]);


    const clearFilters = () => {
        setColumns([]);
        setServiceTypes([]);
        setMerchantPointIds([]);
        setPartnerType(DEFAULT_PARTNER_TYPE_STATE);
        setCashbackSlider([transactionsFilterOptions?.min_cashback || 0, transactionsFilterOptions?.max_cashback || 100]);
        setFeeSlider([transactionsFilterOptions?.min_payprofit_fee || 0, transactionsFilterOptions?.max_payprofit_fee || 100]);
        setPartnerSlider([transactionsFilterOptions?.min_partner_cashback || 0, transactionsFilterOptions?.max_partner_cashback || 100]);
        setForestSlider([transactionsFilterOptions?.min_forest_cashback || 0, transactionsFilterOptions?.max_forest_cashback || 100]);
        setCountSlider([transactionsFilterOptions?.min_count || 0, transactionsFilterOptions?.max_count || 100]);
    };

    const applyFilters = () => {
        if (!cashbackSlider || !feeSlider || !partnerSlider || !forestSlider || !countSlider) {
            return;
        }
        const partnerTypeString = Object.keys(partnerType)
            .filter(key => partnerType[key as keyof PartnerTypeState])
            .join(',');

        const urlFilters = '' +
            '&columns=' + columns.join(',') +
            '&merchant_points_ids=' + merchantPointIds.join(',') +
            '&service_types=' + serviceTypes.join(',') +
            '&partner_types=' + partnerTypeString +
            '&cashback=' + cashbackSlider.join(',') +
            '&fee=' + feeSlider.join(',') +
            '&partner_cashback=' + partnerSlider.join(',') +
            '&forest_cashback=' + forestSlider.join(',') +
            '&transactions_count=' + countSlider.join(',');
        setFilters(urlFilters);
        setOpen(false);
    };


    return (
        <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            overlayClassName="modal-wrapper"
            ariaHideApp={false}
            className="modal-content filters-modal"
        >
            <div className="modal-header">
                <div className="modal-close" onClick={() => setOpen(false)}>
                    <img src={icons.closeIcon} alt=""/>
                </div>
                <h5 className="modal-title">Filtry</h5>
                <div className="modal-close" onClick={clearFilters}>
                    <img src={icons.resetFilters} alt=""/>
                </div>
            </div>
            <div className="modal-body">
                <FiltersDropdown
                    title="Ukryj kolumny"
                    placeholder="Zaznacz kolumny które chcesz ukryć..."
                    options={columnsOptions}
                    values={columns}
                    setValues={setColumns}
                    setChildren={true}
                />
                <AdminServiceTypeFilter serviceTypes={serviceTypes} setServiceTypes={setServiceTypes}/>
                <AdminMerchantPointFilter merchantPointIds={merchantPointIds}
                                          setMerchantPointsIds={setMerchantPointIds}/>
                <AdminPartnerTypeFilter partnerType={partnerType} setPartnerType={setPartnerType}/>
                <FiltersSlider
                    label="Cashback od zakupu"
                    min={transactionsFilterOptions?.min_cashback || 0}
                    max={transactionsFilterOptions?.max_cashback || 100}
                    value={cashbackSlider}
                    onChange={setCashbackSlider}
                />
                <FiltersSlider
                    label="Opłaty payprofit"
                    min={transactionsFilterOptions?.min_payprofit_fee || 0}
                    max={transactionsFilterOptions?.max_payprofit_fee || 100}
                    value={feeSlider}
                    onChange={setFeeSlider}
                />
                <FiltersSlider
                    label="Cashback partnerski"
                    min={transactionsFilterOptions?.min_partner_cashback || 0}
                    max={transactionsFilterOptions?.max_partner_cashback || 100}
                    value={partnerSlider}
                    onChange={setPartnerSlider}
                />
                <FiltersSlider
                    label="Cashback na las"
                    min={transactionsFilterOptions?.min_forest_cashback || 0}
                    max={transactionsFilterOptions?.max_forest_cashback || 100}
                    value={forestSlider}
                    onChange={setForestSlider}
                />
                <FiltersSlider
                    label="Liczba wizyt"
                    min={transactionsFilterOptions?.min_count || 0}
                    max={transactionsFilterOptions?.max_count || 100}
                    value={countSlider}
                    onChange={setCountSlider}
                    wholeNumber
                />
            </div>
            <div className="modal-footer">
                {transactionsFilterOptions &&
                    <Button label="Zastosuj filtry" onClick={applyFilters} fullWidth={true}/>}
            </div>
        </Modal>
    )
};

export default AdminMerchantsFiltersModal